
















































































































import Vue from 'vue';
import * as api from '@/util/api';
import { Endpoint, ServiceClient } from '@/openapi-types';
import AccessRightsDialog from '@/views/Service/AccessRightsDialog.vue';
import { encodePathParameter } from '@/util/api';
import { Permissions } from '@/global';
import { mapActions, mapState } from 'pinia';
import { useUser } from '@/store/modules/user';

import { useNotifications } from '@/store/modules/notifications';

export default Vue.extend({
  name: 'EndpointAccessRights',
  components: {
    AccessRightsDialog,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    clientId: {
      type: String,
      required: true,
    },
  },
  data: () => {
    return {
      endpoint: {} as Endpoint | Record<string, unknown>,
      serviceClients: [] as ServiceClient[],
      confirmDeleteAll: false as boolean,
      confirmDeleteOne: false as boolean,
      serviceClientsToDelete: [] as ServiceClient[],
      addSubjectsDialogVisible: false as boolean,
      serviceClientsToAdd: [] as ServiceClient[],
    };
  },
  computed: {
    ...mapState(useUser, ['hasPermission']),
    canEdit(): boolean {
      return this.hasPermission(Permissions.EDIT_ENDPOINT_ACL);
    },
  },
  created(): void {
    this.fetchData();
  },
  methods: {
    ...mapActions(useNotifications, ['showError', 'showSuccess']),
    close(): void {
      this.$router.go(-1);
    },
    removeAll(): void {
      this.confirmDeleteAll = true;
      this.serviceClientsToDelete = this.serviceClients;
    },
    remove(serviceClient: ServiceClient): void {
      this.confirmDeleteOne = true;
      this.serviceClientsToDelete = [serviceClient];
    },
    resetDeletionSettings(isDeleteAll: boolean): void {
      if (isDeleteAll) {
        this.confirmDeleteAll = false;
      } else {
        this.confirmDeleteOne = false;
      }
      this.serviceClientsToDelete = [];
    },
    toggleAddServiceClientsDialog(): void {
      this.addSubjectsDialogVisible = !this.addSubjectsDialogVisible;
    },
    fetchData(): void {
      api
        .get<Endpoint>(`/endpoints/${encodePathParameter(this.id)}`)
        .then((endpoint) => {
          this.endpoint = endpoint.data;
        })
        .catch((error) => {
          this.showError(error);
        });
      api
        .get<ServiceClient[]>(
          `/endpoints/${encodePathParameter(this.id)}/service-clients`,
        )
        .then((accessRights) => {
          this.serviceClients = accessRights.data;
        })
        .catch((error) => {
          this.showError(error);
        });
    },
    doRemoveSelectedServiceClients(isDeleteAll: boolean): void {
      api
        .post(
          `/endpoints/${encodePathParameter(this.id)}/service-clients/delete`,
          {
            items: this.serviceClientsToDelete,
          },
        )
        .then(() => {
          this.showSuccess(this.$t('accessRights.removeSuccess'));
          this.fetchData();
        })
        .catch((error) => {
          this.showError(error);
        })
        .finally(() => {
          this.resetDeletionSettings(isDeleteAll);
        });
    },
    doAddServiceClients(serviceClients: ServiceClient[]): void {
      api
        .post<ServiceClient[]>(
          `/endpoints/${encodePathParameter(this.id)}/service-clients`,
          {
            items: serviceClients,
          },
        )
        .then((accessRights) => {
          this.showSuccess(this.$t('accessRights.addSubjectsSuccess'));
          this.serviceClients = accessRights.data;
        })
        .catch((error) => {
          this.showError(error);
        })
        .finally(() => {
          this.toggleAddServiceClientsDialog();
        });
    },
  },
});
