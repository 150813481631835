
























































// A dialog for backend warnings
import Vue from 'vue';
import { Prop } from 'vue/types/options';

export default Vue.extend({
  props: {
    dialog: {
      type: Boolean as Prop<boolean>,
      required: true,
    },
    warnings: {
      type: Array as Prop<string[]>,
      required: true,
    },
    cancelButtonText: {
      type: String as Prop<string>,
      default: 'action.cancel',
    },
    acceptButtonText: {
      type: String as Prop<string>,
      default: 'action.continue',
    },
    maxWidth: {
      type: String as Prop<string>,
      default: '850',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    cancel(): void {
      this.$emit('cancel');
    },
    accept(): void {
      this.$emit('accept');
    },
  },
});
