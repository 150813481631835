


















































import Vue from 'vue';
import { mapActions, mapState } from 'pinia';
import { useUser } from '@/store/modules/user';
import { RouteName } from '@/global';

export default Vue.extend({
  computed: {
    ...mapState(useUser, ['username']),
  },
  methods: {
    ...mapActions(useUser, ['logoutUser']),
    logout(): void {
      this.logoutUser();
      this.$router.replace({ name: RouteName.Login });
    },
  },
});
