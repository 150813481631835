


























































import Vue from 'vue';
import * as api from '@/util/api';
import { encodePathParameter } from '@/util/api';
import { mapActions } from 'pinia';
import { useNotifications } from '@/store/modules/notifications';

export default Vue.extend({
  props: {
    id: {
      type: String,
      required: true,
    },
    dialog: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      code: '',
      description: '',
    };
  },
  computed: {
    formReady(): boolean {
      if (this.code && this.code.length > 0 && this.description.length > 0) {
        return true;
      }
      return false;
    },
  },

  methods: {
    ...mapActions(useNotifications, ['showError', 'showSuccess']),
    cancel(): void {
      this.clearForm();
      this.$emit('cancel');
    },
    save(): void {
      api
        .post(`/clients/${encodePathParameter(this.id)}/local-groups`, {
          code: this.code,
          description: this.description,
        })
        .then(() => {
          this.showSuccess(this.$t('localGroup.localGroupAdded'));
          this.$emit('group-added');
        })
        .catch((error) => {
          this.showError(error);
        })
        .finally(() => this.clearForm());
    },

    clearForm(): void {
      this.code = '';
      this.description = '';
    },
  },
});
