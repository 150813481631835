








































import Vue from 'vue';
import { Permissions, RouteName } from '@/global';
import { Tab } from '@/ui-types';
import SubTabs from '@/components/layout/SubTabs.vue';
import { mapState } from 'pinia';
import { useUser } from '@/store/modules/user';

export default Vue.extend({
  components: {
    SubTabs,
  },
  computed: {
    ...mapState(useUser, ['getAllowedTabs']),
    tabs(): Tab[] {
      const allTabs: Tab[] = [
        {
          key: 'system-parameters-tab-button',
          name: 'tab.settings.systemParameters',
          to: {
            name: RouteName.SystemParameters,
          },
          permissions: [Permissions.VIEW_SYS_PARAMS],
        },
        {
          key: 'backupandrestore-tab-button',
          name: 'tab.settings.backupAndRestore',
          to: {
            name: RouteName.BackupAndRestore,
          },
          permissions: [Permissions.BACKUP_CONFIGURATION],
        },
      ];
      return this.getAllowedTabs(allTabs);
    },
  },
});
