






















































import Vue from 'vue';
import { TimestampingService } from '@/openapi-types';
import { Permissions } from '@/global';
import { Prop } from 'vue/types/options';
import * as api from '@/util/api';
import { mapActions, mapState } from 'pinia';
import { useNotifications } from '@/store/modules/notifications';
import { useUser } from '@/store/modules/user';

export default Vue.extend({
  name: 'TimestampingServiceRow',
  props: {
    timestampingService: {
      type: Object as Prop<TimestampingService>,
      required: true,
    },
    messageLogEnabled: Boolean,
  },
  data() {
    return {
      confirmDeleteDialog: false,
      deleting: false,
      permissions: Permissions,
    };
  },
  computed: {
    ...mapState(useUser, ['hasPermission']),
    showDeleteTsp(): boolean {
      return this.hasPermission(Permissions.DELETE_TSP) && this.messageLogEnabled;
    },
  },
  methods: {
    ...mapActions(useNotifications, ['showError', 'showSuccess']),

    deleteTimestampingService(): void {
      this.deleting = true;
      api
        .post('/system/timestamping-services/delete', this.timestampingService)
        .then(() => {
          this.deleting = false;
          this.confirmDeleteDialog = false;
          this.$emit('deleted');
          this.showSuccess(
            this.$t(
              'systemParameters.timestampingServices.table.action.delete.success',
            ),
          );
        })
        .catch((error) => this.showError(error));
    },
  },
});
