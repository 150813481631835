

































































































































import Vue from 'vue';
import { Permissions } from '@/global';
import * as api from '@/util/api';
import { Anchor } from '@/openapi-types';
import { FileUploadResult } from '@niis/shared-ui';
import { PostPutPatch } from '@/util/api';
import { mapActions, mapState } from 'pinia';
import { useNotifications } from '@/store/modules/notifications';
import { useUser } from '@/store/modules/user';

const EmptyAnchorPreview: Anchor = {
  hash: '',
  created_at: '',
};

export default Vue.extend({
  name: 'UploadConfigurationAnchorDialog',
  props: {
    initMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      previewing: false as boolean,
      uploading: false as boolean,
      anchorPreview: EmptyAnchorPreview,
      uploadedFile: null as string | ArrayBuffer | null,
      showPreview: false as boolean,
      permissions: Permissions,
      anchorFile: undefined as string | undefined,
    };
  },
  computed: {
    ...mapState(useUser, ['hasPermission']),
    showUploadAnchor(): boolean {
      return this.hasPermission(Permissions.UPLOAD_ANCHOR);
    },
  },
  methods: {
    ...mapActions(useNotifications, ['showError', 'showSuccess']),
    onUploadFileChanged(event: FileUploadResult): void {
      if (this.initMode) {
        this.previewAnchor(
          event,
          '/system/anchor/previews?validate_instance=false',
        );
      } else {
        this.previewAnchor(event, '/system/anchor/previews');
      }
    },

    previewAnchor(event: FileUploadResult, query: string): void {
      this.previewing = true;
      api
        .post<Anchor>(query, event.buffer, {
          headers: {
            'Content-Type': 'application/octet-stream',
          },
        })
        .then((resp) => {
          this.uploadedFile = event.buffer;
          this.anchorPreview = resp.data;
          this.showPreview = true;
        })
        .catch((error) => {
          this.showError(error);
          // Clear the anchor file
          this.anchorFile = undefined;
        })
        .finally(() => (this.previewing = false));
    },

    confirmUpload(): void {
      if (this.initMode) {
        this.uploadAnchor(api.post);
      } else {
        this.uploadAnchor(api.put);
      }
    },

    uploadAnchor(apiCall: PostPutPatch): void {
      this.uploading = true;
      apiCall('/system/anchor', this.uploadedFile, {
        headers: {
          'Content-Type': 'application/octet-stream',
        },
      })
        .then(() => {
          this.showSuccess(
            this.$t(
              'systemParameters.configurationAnchor.action.upload.dialog.success',
            ),
          );
          this.$emit('uploaded');
        })
        .catch((error) => this.showError(error))
        .finally(() => {
          this.uploading = false;
          this.close();
        });
    },
    close(): void {
      this.previewing = false;
      this.showPreview = false;
      this.anchorPreview = EmptyAnchorPreview;
    },
  },
});
