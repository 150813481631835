var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"xrd-tab-max-width main-wrap",attrs:{"data-test":"service-description-details-dialog"}},[_c('div',{staticClass:"pa-4"},[(_vm.serviceDesc.type === _vm.serviceType.WSDL)?_c('xrd-sub-view-title',{attrs:{"title":_vm.$t('services.wsdlDetails'),"data-test":"wsdl-service-description-details-dialog"},on:{"close":_vm.close}}):(_vm.serviceDesc.type === _vm.serviceType.REST)?_c('xrd-sub-view-title',{attrs:{"title":_vm.$t('services.restDetails'),"data-test":"rest-service-description-details-dialog"},on:{"close":_vm.close}}):(_vm.serviceDesc.type === _vm.serviceType.OPENAPI3)?_c('xrd-sub-view-title',{attrs:{"title":_vm.$t('services.openapiDetails'),"data-test":"openapi-service-description-details-dialog"},on:{"close":_vm.close}}):_vm._e(),_c('div',{staticClass:"delete-wrap"},[(_vm.showDelete)?_c('xrd-button',{attrs:{"data-test":"service-description-details-delete-button","outlined":""},on:{"click":function($event){return _vm.showDeletePopup(_vm.serviceDesc.type)}}},[_vm._v(_vm._s(_vm.$t('action.delete')))]):_vm._e()],1)],1),_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"px-4"},[_c('div',{staticClass:"edit-row pb-4"},[_c('div',[_vm._v(_vm._s(_vm.$t('services.serviceType')))]),(_vm.serviceDesc.type === _vm.serviceType.REST)?_c('div',{staticClass:"code-input",attrs:{"data-test":"service-description-details-url-type-value"}},[_vm._v(" "+_vm._s(_vm.$t('services.restApiBasePath'))+" ")]):(_vm.serviceDesc.type === _vm.serviceType.OPENAPI3)?_c('div',{staticClass:"code-input",attrs:{"data-test":"service-description-details-url-type-value"}},[_vm._v(" "+_vm._s(_vm.$t('services.OpenApi3Description'))+" ")]):_c('div',{staticClass:"code-input",attrs:{"data-test":"service-description-details-url-type-value"}},[_vm._v(" "+_vm._s(_vm.$t('services.wsdlDescription'))+" ")])]),_c('div',{staticClass:"edit-row"},[_c('div',[_vm._v(_vm._s(_vm.$t('services.editUrl')))]),_c('ValidationProvider',{staticClass:"validation-provider",attrs:{"rules":"required|wsdlUrl","name":"url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"url-input",attrs:{"outlined":"","name":"url","error-messages":errors,"type":"text"},on:{"input":function($event){_vm.touched = true}},model:{value:(_vm.serviceDesc.url),callback:function ($$v) {_vm.$set(_vm.serviceDesc, "url", $$v)},expression:"serviceDesc.url"}})]}}],null,true)})],1),_c('div',{staticClass:"edit-row"},[(
            _vm.serviceDesc.type === _vm.serviceType.REST ||
            _vm.serviceDesc.type === _vm.serviceType.OPENAPI3
          )?[_c('div',[_vm._v(_vm._s(_vm.$t('services.serviceCode')))]),_c('ValidationProvider',{staticClass:"validation-provider",attrs:{"rules":"required|xrdIdentifier","name":"code_field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-text-field',{staticClass:"code-input",attrs:{"name":"code_field","outlined":"","type":"text","maxlength":255,"error-messages":errors},on:{"input":function($event){_vm.touched = true}},model:{value:(_vm.currentServiceCode),callback:function ($$v) {_vm.currentServiceCode=$$v},expression:"currentServiceCode"}})]}}],null,true)})]:_vm._e()],2)]),_c('v-card',{attrs:{"flat":""}},[_c('div',{staticClass:"dtlv-actions-footer mt-12"},[_c('xrd-button',{attrs:{"data-test":"service-description-details-cancel-button","outlined":""},on:{"click":function($event){return _vm.close()}}},[_vm._v(_vm._s(_vm.$t('action.cancel')))]),_c('xrd-button',{attrs:{"loading":_vm.saveBusy,"data-test":"service-description-details-save-button","disabled":!_vm.touched || invalid},on:{"click":function($event){return _vm.save()}}},[_vm._v(_vm._s(_vm.$t('action.save')))])],1)])]}}])}),_c('xrd-confirm-dialog',{attrs:{"dialog":_vm.confirmWSDLDelete,"title":"services.deleteTitle","text":"services.deleteWsdlText"},on:{"cancel":function($event){_vm.confirmWSDLDelete = false},"accept":function($event){return _vm.doDeleteServiceDesc()}}}),_c('xrd-confirm-dialog',{attrs:{"dialog":_vm.confirmRESTDelete,"title":"services.deleteTitle","text":"services.deleteRestText"},on:{"cancel":function($event){_vm.confirmRESTDelete = false},"accept":function($event){return _vm.doDeleteServiceDesc()}}}),_c('ServiceWarningDialog',{attrs:{"dialog":_vm.confirmEditWarning,"warnings":_vm.warningInfo,"loading":_vm.editLoading},on:{"cancel":function($event){return _vm.cancelEditWarning()},"accept":function($event){return _vm.acceptEditWarning()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }