







































import Vue from 'vue';
import * as api from '@/util/api';
import { CertificateDetails } from '@/openapi-types';
import CertificateInfo from '@/components/certificate/CertificateInfo.vue';
import CertificateHash from '@/components/certificate/CertificateHash.vue';
import { mapActions } from 'pinia';
import { useNotifications } from '@/store/modules/notifications';

export default Vue.extend({
  components: {
    CertificateInfo,
    CertificateHash,
  },
  props: {},
  data() {
    return {
      certificate: undefined as CertificateDetails | undefined,
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    ...mapActions(useNotifications, ['showError']),
    close(): void {
      this.$router.go(-1);
    },
    fetchData(): void {
      api
        .get<CertificateDetails>('/system/certificate')
        .then((res) => {
          this.certificate = res.data;
        })
        .catch((error) => {
          this.showError(error);
        });
    },
  },
});
