























































































import Vue from 'vue';
import WarningDialog from '@/components/ui/WarningDialog.vue';
import { AddMemberWizardModes } from '@/global';
import { createClientId } from '@/util/helpers';
import * as api from '@/util/api';
import { encodePathParameter } from '@/util/api';
import { memberHasValidSignCert } from '@/util/ClientUtil';
import { mapActions, mapState } from 'pinia';

import { useNotifications } from '@/store/modules/notifications';
import { useAddClient } from '@/store/modules/addClient';
import { useUser } from '@/store/modules/user';
import { useCsrStore } from '@/store/modules/certificateSignRequest';

export default Vue.extend({
  components: {
    WarningDialog,
  },
  data() {
    return {
      disableCancel: false,
      registerChecked: true,
      submitLoading: false,
      warningInfo: [] as string[],
      warningDialog: false,
    };
  },
  computed: {
    ...mapState(useAddClient, [
      'addMemberWizardMode',
      'memberClass',
      'memberCode',
      'subsystemCode',
      'tokens',
    ]),
    ...mapState(useUser, ['currentSecurityServer']),
    ...mapState(useCsrStore, ['csrTokenId']),

    showRegisterOption(): boolean {
      return (
        this.addMemberWizardMode === AddMemberWizardModes.CERTIFICATE_EXISTS
      );
    },
    canRegisterClient(): boolean {
      const memberName = `${this.currentSecurityServer.instance_id}:${this.memberClass}:${this.memberCode}`;
      return memberHasValidSignCert(memberName, this.tokens);
    },
  },

  methods: {
    ...mapActions(useNotifications, ['showError', 'showSuccess']),
    ...mapActions(useAddClient, ['createClient']),
    ...mapActions(useCsrStore, ['generateKeyAndCsr']),
    cancel(): void {
      this.$emit('cancel');
    },
    previous(): void {
      this.$emit('previous');
    },
    done(): void {
      this.requestCreateClient(false);
    },
    requestCreateClient(ignoreWarnings: boolean): void {
      this.disableCancel = true;
      this.submitLoading = true;

      this.createClient(ignoreWarnings).then(
        () => {
          if (
            this.addMemberWizardMode ===
              AddMemberWizardModes.CERTIFICATE_EXISTS &&
            this.registerChecked &&
            this.canRegisterClient
          ) {
            this.registerClient();
          } else if (
            this.addMemberWizardMode === AddMemberWizardModes.CERTIFICATE_EXISTS
          ) {
            this.disableCancel = false;
            this.submitLoading = false;
            this.$emit('done');
          } else if (
            this.addMemberWizardMode === AddMemberWizardModes.CSR_EXISTS
          ) {
            this.generateCsr();
          } else {
            this.requestGenerateKeyAndCsr();
          }
        },
        (error) => {
          if (error?.response?.data?.warnings) {
            this.warningInfo = error.response.data.warnings;
            this.warningDialog = true;
          } else {
            this.showError(error);
            this.disableCancel = false;
            this.submitLoading = false;
          }
        },
      );
    },
    cancelSubmit(): void {
      this.disableCancel = false;
      this.submitLoading = false;
      this.warningDialog = false;
    },
    acceptWarnings(): void {
      this.requestCreateClient(true);
    },
    requestGenerateKeyAndCsr(): void {
      if (!this.csrTokenId) {
        // Should not happen
        throw new Error('Token id is missing');
      }

      const tokenId = this.csrTokenId;

      this.generateKeyAndCsr(tokenId)
        .then(
          () => {
            this.$emit('done');
          },
          (error) => {
            this.showError(error);
          },
        )
        .finally(() => {
          this.disableCancel = false;
          this.submitLoading = false;
        });
    },

    generateCsr(): void {
      this.requestGenerateCsr()
        .then(
          () => {
            this.$emit('done');
          },
          (error) => {
            this.showError(error);
          },
        )
        .finally(() => {
          this.disableCancel = false;
          this.submitLoading = false;
        });
    },

    registerClient(): void {
      if (!this.currentSecurityServer.instance_id) {
        // Should not happen
        throw new Error('Current security server is missing instance id');
      }

      const clientId = createClientId(
        this.currentSecurityServer.instance_id,
        this.memberClass,
        this.memberCode,
        this.subsystemCode,
      );

      api
        .put(`/clients/${encodePathParameter(clientId)}/register`, {})
        .then(
          () => {
            this.$emit('done');
          },
          (error) => {
            this.showError(error);
            this.$emit('done');
          },
        )
        .finally(() => {
          this.disableCancel = false;
          this.submitLoading = false;
        });
    },
  },
});
