var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('table',{staticClass:"xrd-table keys-table"},[_c('KeysTableThead',{attrs:{"sort-direction":_vm.sortDirection,"selected-sort":_vm.selectedSort},on:{"set-sort":_vm.setSort}}),_vm._l((_vm.sortedKeys),function(key){return _c('tbody',{key:key.id},[(_vm.tokenType === _vm.tokenTypes.SOFTWARE)?[_c('KeyRow',{attrs:{"token-logged-in":_vm.tokenLoggedIn,"token-key":key},on:{"generate-csr":function($event){return _vm.generateCsr(key)},"key-click":function($event){return _vm.keyClick(key)}}}),_vm._l((key.certificates),function(cert){return _c('CertificateRow',{key:cert.id,attrs:{"cert":cert},on:{"certificate-click":function($event){return _vm.certificateClick(cert, key)}}},[_c('div',{attrs:{"slot":"certificateAction"},slot:"certificateAction"},[(
                _vm.showRegisterCertButton &&
                cert.possible_actions.includes('REGISTER')
              )?_c('xrd-button',{staticClass:"table-button-fix test-register",attrs:{"outlined":false,"text":""},on:{"click":function($event){return _vm.showRegisterCertDialog(cert)}}},[_vm._v(_vm._s(_vm.$t('action.register')))]):_vm._e()],1)])})]:_vm._e(),(_vm.tokenType === 'HARDWARE')?[_c('KeyRow',{attrs:{"token-logged-in":_vm.tokenLoggedIn,"token-key":key},on:{"generate-csr":function($event){return _vm.generateCsr(key)},"key-click":function($event){return _vm.keyClick(key)}}}),_vm._l((key.certificates),function(cert){return _c('CertificateRow',{key:cert.id,attrs:{"cert":cert},on:{"certificate-click":function($event){return _vm.certificateClick(cert, key)}}},[_c('div',{attrs:{"slot":"certificateAction"},slot:"certificateAction"},[(_vm.canImportFromToken)?[(cert.possible_actions.includes('IMPORT_FROM_TOKEN'))?_c('xrd-button',{staticClass:"table-button-fix",attrs:{"outlined":false,"text":""},on:{"click":function($event){return _vm.importCert(cert.certificate_details.hash)}}},[_vm._v(_vm._s(_vm.$t('keys.importCert')))]):(key.usage === 'AUTHENTICATION')?_c('div',[_vm._v(" "+_vm._s(_vm.$t('keys.authNotSupported'))+" ")]):_vm._e()]:_vm._e()],2)])})]:_vm._e(),(
          key.certificate_signing_requests &&
          key.certificate_signing_requests.length > 0
        )?_vm._l((key.certificate_signing_requests),function(req){return _c('tr',{key:req.id},[_c('td',{staticClass:"td-name"},[_c('div',{staticClass:"name-wrap"},[_c('i',{staticClass:"icon-Certificate cert-icon"}),_c('div',[_vm._v(_vm._s(_vm.$t('keys.request')))])])]),_c('td',{attrs:{"colspan":"4"}},[_vm._v(_vm._s(req.id))]),_c('td',{staticClass:"td-align-right"},[(
                req.possible_actions.includes('DELETE') && _vm.canDeleteCsr(key)
              )?_c('xrd-button',{staticClass:"table-button-fix",attrs:{"outlined":false,"text":""},on:{"click":function($event){return _vm.showDeleteCsrDialog(req, key)}}},[_vm._v(_vm._s(_vm.$t('keys.deleteCsr')))]):_vm._e()],1)])}):_vm._e()],2)})],2),_c('RegisterCertificateDialog',{attrs:{"dialog":_vm.registerDialog},on:{"save":_vm.registerCert,"cancel":function($event){_vm.registerDialog = false}}}),_c('xrd-confirm-dialog',{attrs:{"dialog":_vm.confirmDeleteCsr,"title":"keys.deleteCsrTitle","text":"keys.deleteCsrText"},on:{"cancel":function($event){_vm.confirmDeleteCsr = false},"accept":function($event){return _vm.deleteCsr()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }