






























































import Vue from 'vue';
import { Colors } from '@/global';
import { Notification } from '@/ui-types';

import { mapActions, mapState } from 'pinia';
import { useNotifications } from '@/store/modules/notifications';

declare global {
  interface Window {
    e2eTestingMode: boolean;
  }
}

export default Vue.extend({
  // Component for snackbar notifications
  data() {
    return {
      colors: Colors,
    };
  },
  computed: {
    ...mapState(useNotifications, ['successNotifications']),
  },
  methods: {
    ...mapActions(useNotifications, ['deleteSuccessNotification']),
    snackbarTimeout(notification: Notification) {
      // Check global window value to see if e2e testing mode should be enabled
      if (window.e2eTestingMode === true) {
        return -1;
      }
      return notification.timeout;
    },
    transitionName() {
      // Check global window value to see if e2e testing mode should be enabled
      if (window.e2eTestingMode === true) {
        return 'no-transition'; // Transition class name that doesn't exist
      }
      return 'fade'; // Proper transition class name
    },
  },
});
