












































































































































































































// View for services tab
import Vue from 'vue';
import { Permissions, RouteName } from '@/global';
import * as api from '@/util/api';
import { encodePathParameter } from '@/util/api';
import AddWsdlDialog from './AddWsdlDialog.vue';
import AddRestDialog from './AddRestDialog.vue';
import DisableServiceDescDialog from './DisableServiceDescDialog.vue';
import ServiceWarningDialog from '@/components/service/ServiceWarningDialog.vue';
import ServiceIcon from '@/components/ui/ServiceIcon.vue';

import { Service, ServiceDescription, ServiceType } from '@/openapi-types';
import { ServiceTypeEnum } from '@/domain';
import { Prop } from 'vue/types/options';
import { sortServiceDescriptionServices } from '@/util/sorting';
import { deepClone } from '@/util/helpers';
import { mapActions, mapState } from 'pinia';
import { useServicesStore } from '@/store/modules/services';
import { useUser } from '@/store/modules/user';
import { useNotifications } from '@/store/modules/notifications';

export default Vue.extend({
  components: {
    AddWsdlDialog,
    AddRestDialog,
    DisableServiceDescDialog,
    ServiceWarningDialog,
    ServiceIcon,
  },
  props: {
    id: {
      type: String as Prop<string>,
      required: true,
    },
  },
  data() {
    return {
      search: '' as string,
      loading: false,
      addWsdlDialog: false as boolean,
      addRestDialog: false as boolean,
      disableDescDialog: false as boolean,
      selectedServiceDesc: undefined as undefined | ServiceDescription,
      selectedIndex: -1 as number,
      componentKey: 0 as number,
      expanded: [] as string[],
      serviceDescriptions: [] as ServiceDescription[],
      warningInfo: [] as string[],
      saveWsdlWarningDialog: false as boolean,
      saveRestWarningDialog: false as boolean,
      refreshWarningDialog: false as boolean,
      url: '' as string,
      serviceType: '' as string,
      serviceCode: '' as string,
      refreshId: '' as string,
      addWsdlBusy: false as boolean,
      addRestBusy: false as boolean,
      refreshBusy: {} as { [key: string]: boolean },
      refreshButtonComponentKey: 0 as number,
      serviceTypeEnum: ServiceTypeEnum,
      saveWsdlLoading: false as boolean,
      saveRestLoading: false as boolean,
      refreshLoading: false as boolean,
    };
  },
  computed: {
    ...mapState(useUser, ['hasPermission']),
    ...mapState(useServicesStore, ['descExpanded']),

    showAddWSDLButton(): boolean {
      return this.hasPermission(Permissions.ADD_WSDL);
    },
    showAddRestButton(): boolean {
      return this.hasPermission(Permissions.ADD_OPENAPI3);
    },
    canDisable(): boolean {
      return this.hasPermission(Permissions.ENABLE_DISABLE_WSDL);
    },
    filtered(): ServiceDescription[] {
      if (!this.serviceDescriptions || this.serviceDescriptions.length === 0) {
        return [];
      }

      // Sort array by id:s so it doesn't jump around. Order of items in the backend reply changes between requests.
      const arr = deepClone(this.serviceDescriptions).sort((a, b) => {
        if (a.id < b.id) {
          return -1;
        }
        if (a.id > b.id) {
          return 1;
        }

        // equal id:s. (should not happen)
        return 0;
      });

      if (!this.search) {
        return arr;
      }

      // Clean the search string
      const mysearch = this.search.toString().toLowerCase();
      if (mysearch.trim() === '') {
        return arr;
      }

      // Filter out service descriptions that don't include search term
      const filtered = arr.filter((element: ServiceDescription) => {
        return element.services.find((service: Service) => {
          return (
            service.service_code.toString().toLowerCase().includes(mysearch) ||
            service.url.toString().toLowerCase().includes(mysearch) ||
            service.timeout.toString().toLowerCase().includes(mysearch)
          );
        });
      });

      // Filter out services that don't include search term
      filtered.forEach((element: ServiceDescription) => {
        element.services = element.services.filter((service: Service) => {
          return (
            service.service_code.toString().toLowerCase().includes(mysearch) ||
            service.url.toString().toLowerCase().includes(mysearch) ||
            service.timeout.toString().toLowerCase().includes(mysearch)
          );
        });
      });

      return filtered;
    },
  },

  created() {
    this.fetchData();
  },
  methods: {
    ...mapActions(useNotifications, ['showError', 'showSuccess']),
    ...mapActions(useServicesStore, ['hideDesc', 'expandDesc']),

    showRefreshButton(serviceDescriptionType: string): boolean {
      if (serviceDescriptionType === this.serviceTypeEnum.WSDL) {
        return this.hasPermission(Permissions.REFRESH_WSDL);
      } else if (serviceDescriptionType === this.serviceTypeEnum.OPENAPI3) {
        return this.hasPermission(Permissions.REFRESH_OPENAPI3);
      }
      return false;
    },
    descriptionClick(desc: ServiceDescription): void {
      this.$router.push({
        name: RouteName.ServiceDescriptionDetails,
        params: { id: desc.id },
      });
    },
    serviceClick(
      serviceDescription: ServiceDescription,
      service: Service,
    ): void {
      this.$router.push({
        name: RouteName.Service,
        params: { serviceId: service.id, clientId: this.id },
        query: { descriptionType: serviceDescription.type },
      });
    },
    canEditServiceDesc(servicedescription: ServiceDescription): boolean {
      let permission: Permissions;
      if (servicedescription.type === ServiceType.REST) {
        permission = Permissions.EDIT_REST;
      } else if (servicedescription.type === ServiceType.WSDL) {
        permission = Permissions.EDIT_WSDL;
      } else if (servicedescription.type === ServiceType.OPENAPI3) {
        permission = Permissions.EDIT_OPENAPI3;
      } else {
        return false;
      }

      return this.hasPermission(permission);
    },
    switchChanged(
      event: unknown,
      serviceDesc: ServiceDescription,
      index: number,
    ): void {
      if (!serviceDesc.disabled) {
        // If user wants to disable service description:
        // - cancel the switch change
        // - show confirmation dialog instead
        this.selectedServiceDesc = serviceDesc;
        this.selectedIndex = index;
        this.disableDescDialog = true;
        this.forceUpdateSwitch(index, false);
        return;
      }

      api
        .put(
          `/service-descriptions/${encodePathParameter(serviceDesc.id)}/enable`,
          {},
        )
        .then(() => {
          this.showSuccess(this.$t('services.enableSuccess'));
        })
        .catch((error) => {
          this.showError(error);
        })
        .finally(() => {
          // Whatever happens, refresh the data
          this.fetchData();
        });
    },

    disableDescCancel(
      subject: ServiceDescription | undefined,
      index: number,
    ): void {
      // User cancels the change from dialog. Switch must be returned to original position.
      this.disableDescDialog = false;
      this.forceUpdateSwitch(index, false);
    },

    disableDescSave(
      subject: ServiceDescription | undefined,
      index: number,
      notice: string,
    ): void {
      this.disableDescDialog = false;
      this.forceUpdateSwitch(index, true);
      if (subject) {
        api
          .put(
            `/service-descriptions/${encodePathParameter(subject.id)}/disable`,
            {
              disabled_notice: notice,
            },
          )
          .then(() => {
            this.showSuccess(this.$t('services.disableSuccess'));
          })
          .catch((error) => {
            this.showError(error);
          })
          .finally(() => {
            this.fetchData();
          });
      }
    },

    forceUpdateSwitch(index: number, value: boolean): void {
      // "force updating" the switch is needed for smooth
      this.filtered[index].disabled = value;
      this.componentKey += 1;
    },

    showAddRestDialog(): void {
      this.addRestDialog = true;
    },

    showAddWsdlDialog(): void {
      this.addWsdlDialog = true;
    },

    wsdlSave(url: string): void {
      this.url = url;
      this.addWsdlBusy = true;
      api
        .post(`/clients/${encodePathParameter(this.id)}/service-descriptions`, {
          url,
          type: this.serviceTypeEnum.WSDL,
        })
        .then(() => {
          this.showSuccess(this.$t('services.wsdlAdded'));
          this.addWsdlBusy = false;
          this.fetchData();
        })
        .catch((error) => {
          if (error?.response?.data?.warnings) {
            this.warningInfo = error.response.data.warnings;
            this.saveWsdlWarningDialog = true;
          } else {
            this.showError(error);
            this.addWsdlBusy = false;
          }
        });

      this.addWsdlDialog = false;
    },

    acceptSaveWsdlWarning(): void {
      this.saveWsdlLoading = true;
      api
        .post(`/clients/${encodePathParameter(this.id)}/service-descriptions`, {
          url: this.url,
          type: this.serviceTypeEnum.WSDL,
          ignore_warnings: true,
        })
        .then(() => {
          this.showSuccess(this.$t('services.wsdlAdded'));
        })
        .catch((error) => {
          this.showError(error);
        })
        .finally(() => {
          this.fetchData();
          this.addWsdlBusy = false;
          this.saveWsdlLoading = false;
          this.saveWsdlWarningDialog = false;
        });
    },

    cancelSaveWsdlWarning(): void {
      this.addWsdlBusy = false;
      this.saveWsdlLoading = false;
      this.saveWsdlWarningDialog = false;
    },

    cancelAddWsdl(): void {
      this.addWsdlDialog = false;
    },

    restSave(serviceType: string, url: string, serviceCode: string): void {
      this.serviceType = serviceType;
      this.url = url;
      this.serviceCode = serviceCode;
      this.addRestBusy = true;
      api
        .post(`/clients/${encodePathParameter(this.id)}/service-descriptions`, {
          url: this.url,
          rest_service_code: this.serviceCode,
          type: this.serviceType,
        })
        .then(() => {
          this.showSuccess(
            this.serviceType === 'OPENAPI3'
              ? this.$t('services.openApi3Added')
              : this.$t('services.restAdded'),
          );
          this.addRestBusy = false;
          this.fetchData();
        })
        .catch((error) => {
          if (error?.response?.data?.warnings) {
            this.warningInfo = error.response.data.warnings;
            this.saveRestWarningDialog = true;
          } else {
            this.showError(error);
            this.addRestBusy = false;
          }
        });

      this.addRestDialog = false;
    },

    acceptSaveRestWarning(): void {
      this.saveRestLoading = true;
      api
        .post(`/clients/${encodePathParameter(this.id)}/service-descriptions`, {
          url: this.url,
          rest_service_code: this.serviceCode,
          type: this.serviceType,
          ignore_warnings: true,
        })
        .then(() => {
          this.showSuccess(
            this.serviceType === 'OPENAPI3'
              ? this.$t('services.openApi3Added')
              : this.$t('services.restAdded'),
          );
        })
        .catch((error) => {
          this.showError(error);
        })
        .finally(() => {
          this.fetchData();
          this.addRestBusy = false;
          this.saveRestLoading = false;
          this.saveRestWarningDialog = false;
        });
    },

    cancelSaveRestWarning(): void {
      this.addRestBusy = false;
      this.saveRestLoading = false;
      this.saveRestWarningDialog = false;
    },

    cancelAddRest(): void {
      this.addRestDialog = false;
    },

    refresh(serviceDescription: ServiceDescription): void {
      this.refreshBusy[serviceDescription.id] = true;
      this.refreshButtonComponentKey += 1; // update component key to make spinner work

      api
        .put(
          `/service-descriptions/${encodePathParameter(
            serviceDescription.id,
          )}/refresh`,
          {
            ignore_warnings: false,
          },
        )
        .then(() => {
          this.showSuccess(this.$t('services.refreshed'));
          this.fetchData();
        })
        .catch((error) => {
          if (error.response.data.warnings) {
            this.warningInfo = error.response.data.warnings;
            this.refreshWarningDialog = true;
            this.refreshId = serviceDescription.id;
          } else {
            this.showError(error);
            this.fetchData();
          }
        })
        .finally(() => {
          this.refreshBusy[serviceDescription.id] = false;
        });
    },

    acceptRefreshWarning(): void {
      this.refreshLoading = true;
      api
        .put(
          `/service-descriptions/${encodePathParameter(
            this.refreshId,
          )}/refresh`,
          {
            ignore_warnings: true,
          },
        )
        .then(() => {
          this.showSuccess(this.$t('services.refreshed'));
        })
        .catch((error) => {
          this.showError(error);
        })
        .finally(() => {
          this.fetchData();
          this.refreshLoading = false;
          this.refreshWarningDialog = false;
        });
    },

    cancelRefresh(): void {
      this.refreshLoading = false;
      this.refreshWarningDialog = false;
      this.refreshLoading = false;
    },

    descClose(tokenId: string) {
      this.hideDesc(tokenId);
    },
    descOpen(tokenId: string) {
      this.expandDesc(tokenId);
    },
    isExpanded(tokenId: string) {
      return this.descExpanded(tokenId);
    },

    fetchData(): void {
      this.loading = true;
      api
        .get<ServiceDescription[]>(
          `/clients/${encodePathParameter(this.id)}/service-descriptions`,
        )
        .then((res) => {
          const serviceDescriptions: ServiceDescription[] = res.data;
          this.serviceDescriptions = serviceDescriptions.map(
            sortServiceDescriptionServices,
          );
        })
        .catch((error) => {
          this.showError(error);
        })
        .finally(() => (this.loading = false));
    },
  },
});
