


























































import Vue from 'vue';
import { RouteName } from '@/global';
import * as api from '@/util/api';
import { encodePathParameter } from '@/util/api';
import { mapActions } from 'pinia';
import { useNotifications } from '@/store/modules/notifications';

export default Vue.extend({
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      orphansLoading: false as boolean,
      confirmDelete: false as boolean,
      deleteLoading: false as boolean,
      confirmOrphans: false as boolean,
    };
  },

  methods: {
    ...mapActions(useNotifications, ['showError', 'showSuccess']),
    deleteClient(): void {
      this.deleteLoading = true;
      api.remove(`/clients/${encodePathParameter(this.id)}`).then(
        () => {
          this.showSuccess(this.$t('client.action.delete.success'));
          this.checkOrphans();
        },
        (error) => {
          this.showError(error);
          this.confirmDelete = false;
          this.deleteLoading = false;
        },
      );
    },

    checkOrphans(): void {
      api.get(`/clients/${encodePathParameter(this.id)}/orphans`).then(
        () => {
          this.confirmDelete = false;
          this.deleteLoading = false;
          this.confirmOrphans = true;
        },
        (error) => {
          this.confirmDelete = false;
          this.deleteLoading = false;
          if (error.response.status === 404) {
            // No orphans found so exit the view
            this.$router.replace({ name: RouteName.Clients });
          } else {
            // There was some other error, but the client is already deleted so exit the view
            this.showError(error);
            this.$router.replace({ name: RouteName.Clients });
          }
        },
      );
    },

    deleteOrphans(): void {
      this.orphansLoading = true;
      api
        .remove(`/clients/${encodePathParameter(this.id)}/orphans`)
        .then(
          () => {
            this.showSuccess(this.$t('client.action.removeOrphans.success'));
          },
          (error) => {
            // There was some other error, but the client is already deleted so exit the view
            this.showError(error);
          },
        )
        .finally(() => {
          this.confirmOrphans = false;
          this.orphansLoading = false;
          this.$router.replace({ name: RouteName.Clients });
        });
    },

    notDeleteOrphans(): void {
      this.confirmOrphans = false;
      this.$router.replace({ name: RouteName.Clients });
    },
  },
});
