

































































import Vue from 'vue';
import { RouteName } from '@/global';
import * as api from '@/util/api';
import { mapActions, mapState } from 'pinia';
import { useAlerts } from '@/store/modules/alerts';
import { useUser } from '@/store/modules/user';

export default Vue.extend({
  data() {
    return {
      sessionPollInterval: 0,
      alertsPollInterval: 0,
    };
  },
  computed: {
    ...mapState(useUser, ['sessionAlive']),
    showDialog(): boolean {
      return this.sessionAlive === false;
    },
  },
  created() {
    // Set interval to poll backend for session
    this.sessionPollInterval = setInterval(
      () => this.pollSessionStatus(),
      30000,
    );
    this.checkAlertStatus(); // Poll immediately to get initial alerts state
  },
  methods: {
    ...mapActions(useAlerts, ['checkAlertStatus']),
    ...mapActions(useUser, ['logoutUser', 'setSessionAlive']),
    async pollSessionStatus() {
      return api
        .get('/notifications/session-status')
        .then(() => {
          // Check alert status after a successfull session-status call
          this.checkAlertStatus();
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            this.setSessionAlive(false);
            clearInterval(this.sessionPollInterval);
            clearInterval(this.alertsPollInterval);
          }
        });
    },
    logout(): void {
      this.logoutUser();
      this.$router.replace({ name: RouteName.Login });
    },
  },
});
