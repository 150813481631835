








































import Vue from 'vue';
import { Permissions, RouteName } from '@/global';
import { Tab } from '@/ui-types';
import SubTabs from '@/components/layout/SubTabs.vue';
import { mapState } from 'pinia';
import { useUser } from '@/store/modules/user';

export default Vue.extend({
  components: {
    SubTabs,
  },
  data: () => ({
    currentTab: undefined as undefined | Tab,
    showHelp: false,
  }),

  computed: {
    ...mapState(useUser, ['getAllowedTabs']),
    tabs(): Tab[] {
      const allTabs: Tab[] = [
        {
          key: 'sign-and-auth-keys-tab-button',
          name: 'tab.keys.signAndAuthKeys',
          to: {
            name: RouteName.SignAndAuthKeys,
          },
          permissions: [Permissions.VIEW_KEYS],
        },
        {
          key: 'api-key-tab-button',
          name: 'tab.keys.apiKey',
          to: {
            name: RouteName.ApiKey,
          },
          permissions: [
            Permissions.CREATE_API_KEY,
            Permissions.VIEW_API_KEYS,
            Permissions.UPDATE_API_KEY,
            Permissions.REVOKE_API_KEY,
          ],
        },
        {
          key: 'ss-tls-certificate-tab-button',
          name: 'tab.keys.ssTlsCertificate',
          to: {
            name: RouteName.SSTlsCertificate,
          },
          permissions: [Permissions.VIEW_INTERNAL_TLS_CERT],
        },
      ];

      return this.getAllowedTabs(allTabs);
    },
  },
});
