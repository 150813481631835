




























































































































































import Vue from 'vue';
import SelectClientDialog from '@/components/client/SelectClientDialog.vue';
import { RouteName } from '@/global';
import { containsClient, createClientId } from '@/util/helpers';
import { Client } from '@/openapi-types';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import * as api from '@/util/api';
import { encodePathParameter } from '@/util/api';
import { mapActions } from 'pinia';
import { useNotifications } from '@/store/modules/notifications';

export default Vue.extend({
  components: {
    ValidationObserver,
    ValidationProvider,
    SelectClientDialog,
  },
  props: {
    instanceId: {
      type: String,
      required: true,
    },
    memberClass: {
      type: String,
      required: true,
    },
    memberCode: {
      type: String,
      required: true,
    },
    memberName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showSelectClient: false as boolean,
      registerChecked: true as boolean,
      existingSubsystems: [] as Client[],
      selectableSubsystems: [] as Client[],
      subsystemCode: undefined as undefined | string,
      submitLoading: false as boolean,
      confirmRegisterClient: false as boolean,
      registerClientLoading: false as boolean,
    };
  },
  computed: {
    duplicateClient(): boolean {
      if (!this.subsystemCode) {
        return false;
      }

      return containsClient(
        this.existingSubsystems,
        this.memberClass,
        this.memberCode,
        this.subsystemCode,
      );
    },
  },

  created() {
    this.fetchData();
  },
  methods: {
    ...mapActions(useNotifications, ['showError', 'showSuccess']),
    done(): void {
      this.submitLoading = true;
      const body = {
        client: {
          member_name: this.memberName,
          member_class: this.memberClass,
          member_code: this.memberCode,
          subsystem_code: this.subsystemCode,
        },
        ignore_warnings: false,
      };

      api.post('/clients', body).then(
        () => {
          this.submitLoading = false;
          this.showSuccess(this.$t('wizard.subsystem.subsystemAdded'));
          if (this.registerChecked) {
            this.confirmRegisterClient = true;
          } else {
            this.exitView();
          }
        },
        (error) => {
          this.submitLoading = false;
          this.showError(error);
        },
      );
    },

    registerSubsystem(): void {
      this.registerClientLoading = true;

      const clientId = createClientId(
        this.instanceId,
        this.memberClass,
        this.memberCode,
        this.subsystemCode,
      );
      api.put(`/clients/${encodePathParameter(clientId)}/register`, {}).then(
        () => {
          this.exitView();
          this.showSuccess(this.$t('wizard.subsystem.subsystemAdded'));
        },
        (error) => {

          this.exitView();
          this.showError(error);
        },
      );
    },

    exitView(): void {
      this.registerClientLoading = false;
      this.confirmRegisterClient = false;
      this.submitLoading = false;
      this.$router.replace({ name: RouteName.Clients });
    },
    saveSelectedClient(selectedMember: Client): void {
      this.subsystemCode = selectedMember.subsystem_code;
      this.showSelectClient = false;
    },
    fetchData(): void {
      // Fetch selectable subsystems
      api
        .get<Client[]>(
          `/clients?instance=${this.instanceId}&member_class=${this.memberClass}&member_code=${this.memberCode}&show_members=false&exclude_local=true&internal_search=false`,
        )
        .then((res) => {
          this.selectableSubsystems = res.data;
        })
        .catch((error) => {
          this.showError(error);
        });

      // Fetch existing subsystems
      api
        .get<Client[]>(
          `/clients?instance=${this.instanceId}&member_class=${this.memberClass}&member_code=${this.memberCode}&internal_search=true`,
        )
        .then((res) => {
          this.existingSubsystems = res.data;
        })
        .catch((error) => {
          this.showError(error);
        });
    },
  },
});
