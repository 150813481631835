/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * client status
 */
export enum ClientStatus {
    REGISTERED = 'REGISTERED',
    SAVED = 'SAVED',
    GLOBAL_ERROR = 'GLOBAL_ERROR',
    REGISTRATION_IN_PROGRESS = 'REGISTRATION_IN_PROGRESS',
    DELETION_IN_PROGRESS = 'DELETION_IN_PROGRESS',
}