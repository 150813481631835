









































































































import Vue from 'vue';
import { mapState } from 'pinia';
import { useSystemStore } from '@/store/modules/system';

export default Vue.extend({
  name: 'AppFooter',
  computed: {
    ...mapState(useSystemStore, ['securityServerVersion']),
  },
});
