






























































































import Vue from 'vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { extend } from 'vee-validate';
import i18n from '@/i18n';

const PASSWORD_MATCH_ERROR: string = i18n.t(
  'initialConfiguration.pin.pinMatchError',
) as string;

extend('password', {
  params: ['target'],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validate(value, { target }: Record<string, any>) {
    return value === target;
  },
  message: PASSWORD_MATCH_ERROR,
});

export default Vue.extend({
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    saveBusy: {
      type: Boolean,
    },
  },
  data() {
    return {
      pin: '' as string,
      pinConfirm: '' as string,
    };
  },

  methods: {
    done(): void {
      this.$emit('done', this.pin);
    },
    previous(): void {
      this.$emit('previous');
    },
  },
});
