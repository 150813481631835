












































































































import Vue from 'vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { CsrFormat } from '@/openapi-types';
import { mapState, mapWritableState } from 'pinia';
import { useCsrStore } from '@/store/modules/certificateSignRequest';
import { useAddClient } from '@/store/modules/addClient';

export default Vue.extend({
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    saveButtonText: {
      type: String,
      default: 'action.continue',
    },
    showPreviousButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      csrFormatList: Object.values(CsrFormat) as string[],
    };
  },
  computed: {
    ...mapState(useCsrStore, ['filteredServiceList', 'usage']),
    ...mapWritableState(useCsrStore, ['csrFormat', 'certificationService']),
    ...mapState(useAddClient, ['selectedMemberId']),
  },

  watch: {
    filteredServiceList(val) {
      // Set first certification service selected as default when the list is updated
      if (val?.length === 1) {
        this.certificationService = val[0].name;
      }
    },
  },
  methods: {
    done(): void {
      this.$emit('done');
    },
    previous(): void {
      this.$emit('previous');
    },
    cancel(): void {
      this.$emit('cancel');
    },
  },
});
