/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * connection type
 */
export enum ConnectionType {
    HTTP = 'HTTP',
    HTTPS = 'HTTPS',
    HTTPS_NO_AUTH = 'HTTPS_NO_AUTH',
}