



































































































































































































import Vue from 'vue';
import { Roles } from '@/global';
import { ApiKey } from '@/global-types';
import * as api from '@/util/api';
import { toClipboard } from '@/util/helpers';
import { mapActions } from 'pinia';
import { useNotifications } from '@/store/modules/notifications';

export default Vue.extend({
  name: 'CreateApiKeyStepper',
  data() {
    return {
      step: 1,
      roles: Roles,
      generatingKey: false,
      selectedRoles: [] as string[],
      apiKey: {} as ApiKey,
    };
  },
  computed: {
    nextButtonDisabled(): boolean {
      return this.selectedRoles.length === 0;
    },
    translatedRoles(): string[] {
      return !this.apiKey.roles
        ? []
        : this.apiKey.roles.map(
            (role) => this.$t(`apiKey.role.${role}`) as string,
          );
    },
    keyGenerated(): boolean {
      return this.apiKey.key !== undefined;
    },
  },
  methods: {
    ...mapActions(useNotifications, ['showSuccess']),
    close(): void {
      this.$router.back();
    },
    async generateKey() {
      this.generatingKey = true;
      api
        .post<ApiKey>('/api-keys', this.selectedRoles)
        .then((resp) => {
          this.apiKey = resp.data;
          this.showSuccess(this.$t('apiKey.createApiKey.success'));
        })
        .catch((error) => this.showError(error))
        .finally(() => (this.generatingKey = false));
    },
    copyKey(): void {
      const key = this.apiKey.key;
      if (key) {
        toClipboard(key);
      }
    },
  },
});
