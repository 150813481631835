
















































































































































import Vue from 'vue';
import * as api from '@/util/api';
import {
  AccessRight,
  AccessRights,
  ServiceClient,
  ServiceDescription,
} from '@/openapi-types';
import AddServiceClientServiceDialog from '@/views/Clients/ServiceClients/AddServiceClientServiceDialog.vue';
import { serviceCandidatesForServiceClient } from '@/util/serviceClientUtils';

import { ServiceCandidate } from '@/ui-types';
import { sortAccessRightsByServiceCode } from '@/util/sorting';
import { encodePathParameter } from '@/util/api';
import { Permissions } from '@/global';
import { mapActions, mapState } from 'pinia';
import { useNotifications } from '@/store/modules/notifications';
import { useUser } from '@/store/modules/user';

interface UiAccessRight extends AccessRight {
  uiKey: number;
}

export default Vue.extend({
  components: {
    AddServiceClientServiceDialog,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    serviceClientId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      serviceClientAccessRights: [] as AccessRight[],
      serviceClient: {} as ServiceClient,
      accessRightToDelete: null as AccessRight | null,
      isAddServiceDialogVisible: false as boolean,
      clientServiceDescriptions: [] as ServiceDescription[],
      showConfirmDeleteAll: false as boolean,
      showConfirmDeleteOne: false as boolean,
    };
  },
  computed: {
    ...mapState(useUser, ['hasPermission']),
    canEdit(): boolean {
      return this.hasPermission(Permissions.EDIT_ACL_SUBJECT_OPEN_SERVICES);
    },
  },
  created(): void {
    this.fetchData();
  },
  methods: {
    ...mapActions(useNotifications, ['showError', 'showSuccess']),
    fetchData(): void {
      this.fetchAccessRights();
      this.fetchServiceDescriptions();
      api
        .get<ServiceClient>(
          `/clients/${this.id}/service-clients/${this.serviceClientId}`,
        )
        .then((response) => (this.serviceClient = response.data))
        .catch((error) => this.showError(error));
    },
    fetchServiceDescriptions(): void {
      api
        .get<ServiceDescription[]>(
          `/clients/${encodePathParameter(this.id)}/service-descriptions`,
        )
        .then((response) => {
          this.clientServiceDescriptions = response.data;
        })
        .catch((error) => this.showError(error));
    },
    fetchAccessRights(): void {
      api
        .get<AccessRight[]>(
          `/clients/${this.id}/service-clients/${this.serviceClientId}/access-rights`,
        )
        .then((response) => {
          this.serviceClientAccessRights = sortAccessRightsByServiceCode(
            response.data,
          );
        })
        .catch((error) => this.showError(error));
    },
    close(): void {
      this.$router.go(-1);
    },
    resetDeletionSettings(): void {
      this.showConfirmDeleteOne = false;
      this.accessRightToDelete = null;
    },
    remove(accessRight: AccessRight): void {
      this.showConfirmDeleteOne = true;
      this.accessRightToDelete = accessRight;
    },
    doRemoveAccessRight(): void {
      api
        .post(
          `/clients/${this.id}/service-clients/${this.serviceClientId}/access-rights/delete`,
          { items: [{ service_code: this.accessRightToDelete?.service_code }] },
        )
        .then(() => {
          this.showSuccess(this.$t('accessRights.removeSuccess'));
          if (this.serviceClientAccessRights.length === 1) {
            this.serviceClientAccessRights = [];
          } else {
            this.fetchAccessRights();
          }
        })
        .catch((error) => this.showError(error))
        .finally(() => {
          this.showConfirmDeleteOne = false;
          this.accessRightToDelete = null;
        });
    },
    addService(accessRights: AccessRight[]): void {
      this.hideAddService();
      const accessRightsObject: AccessRights = { items: accessRights };
      api
        .post(
          `/clients/${this.id}/service-clients/${this.serviceClientId}/access-rights`,
          accessRightsObject,
        )
        .then(() => {
          this.showSuccess(
            this.$t('serviceClients.addServiceClientAccessRightSuccess'),
          );
          this.fetchAccessRights();
        })
        .catch((error) => this.showError(error));
    },
    hideAddService(): void {
      this.isAddServiceDialogVisible = false;
    },
    showAddServiceDialog(): void {
      this.isAddServiceDialogVisible = true;
    },
    removeAll(): void {
      this.showConfirmDeleteAll = false;

      api
        .post(
          `/clients/${this.id}/service-clients/${this.serviceClientId}/access-rights/delete`,
          {
            items: this.serviceClientAccessRights.map((item: AccessRight) => ({
              service_code: item.service_code,
            })),
          },
        )
        .then(() => {
          this.showSuccess(this.$t('accessRights.removeSuccess'));
          this.serviceClientAccessRights = [];
        })
        .catch((error) => this.showError(error));
    },
    serviceCandidates(): ServiceCandidate[] {
      return serviceCandidatesForServiceClient(
        this.clientServiceDescriptions,
        this.serviceClientAccessRights,
      );
    },

    keyedServiceClientAccessRights(): UiAccessRight[] {
      return this.serviceClientAccessRights.map(
        (sca: AccessRight, index: number) => {
          return { ...sca, uiKey: index };
        },
      ) as UiAccessRight[];
    },
  },
});
