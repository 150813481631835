
















































































































import Vue, { VueConstructor } from 'vue';
import { Permissions, RouteName } from '@/global';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import AlertsContainer from '@/components/ui/AlertsContainer.vue';
import axios, { AxiosError } from 'axios';
import { mapActions, mapState } from 'pinia';
import { useUser } from '@/store/modules/user';
import { useSystemStore } from '@/store/modules/system';
import { useNotifications } from '@/store/modules/notifications';

export default (
  Vue as VueConstructor<
    Vue & {
      $refs: {
        form: InstanceType<typeof ValidationObserver>;
      };
    }
  >
).extend({
  name: 'Login',
  components: {
    ValidationProvider,
    ValidationObserver,
    AlertsContainer,
  },
  data() {
    return {
      loading: false as boolean,
      username: '' as string,
      password: '' as string,
    };
  },
  computed: {
    ...mapState(useUser, [
      'hasPermission',
      'firstAllowedTab',
      'hasInitState',
      'needsInitialization',
    ]),
    isDisabled() {
      if (
        this.username.length < 1 ||
        this.password.length < 1 ||
        this.loading
      ) {
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapActions(useUser, [
      'loginUser',
      'logoutUser',
      'fetchInitializationStatus',
      'fetchUserData',
      'fetchCurrentSecurityServer',
      'clearAuth',
    ]),
    ...mapActions(useSystemStore, [
      'fetchSecurityServerVersion',
      'fetchSecurityServerNodeType',
      'clearSystemStore',
    ]),
    ...mapActions(useNotifications, [
      'showError',
      'showErrorMessage',
      'clearErrorNotifications',
    ]),
    async submit() {
      // Clear error notifications when route is changed
      this.clearErrorNotifications();

      /* Clear user data so there is nothing left from previous sessions.
       For example user has closed browser tab without loggin out > user data is left in browser local storage */
      this.clearAuth();
      this.clearSystemStore();

      // Validate inputs
      const isValid = await this.$refs.form.validate();

      if (!isValid) return;

      const loginData = {
        username: this.username,
        password: this.password,
      };

      this.$refs.form.reset();
      this.loading = true;

      try {
        await this.loginUser(loginData);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          // Display invalid username/password error in inputs
          if (error?.response?.status === 401) {
            // Clear inputs
            this.username = '';
            this.password = '';
            this.$refs.form.reset();

            // The whole view needs to be rendered so the "required" rule doesn't block
            // "wrong unsername or password" error in inputs
            this.$nextTick(() => {
              // Set inputs to error state
              this.$refs.form.setErrors({
                username: [''],
                password: [this.$t('login.errorMsg401') as string],
              });
            });
          }
          this.showErrorMessage(this.$t('login.generalError'));
        } else {
          if (error instanceof Error) {
            this.showErrorMessage(error.message);
          } else {
            throw error;
          }
        }
      }

      // Auth ok. Start phase 2 (fetch user data and current security server info).

      try {
        await this.fetchUserData();
        await this.fetchInitializationData(); // Used to be inside fetchUserData()
        await this.fetchSecurityServerVersion();
        await this.fetchSecurityServerNodeType();
      } catch (error) {
        this.showError(error as AxiosError);
      }

      // Clear loading state
      this.loading = false;
    },

    async fetchInitializationData() {
      const redirectToLogin = async () => {
        // Logout without page refresh
        await this.logoutUser(false);
        // Clear inputs
        this.username = '';
        this.password = '';
        this.$refs.form.reset();
      };

      await this.fetchInitializationStatus();
      await this.fetchSecurityServerNodeType();
      if (!this.hasInitState) {
        this.showErrorMessage(
          this.$t('initialConfiguration.noInitializationStatus'),
        );
        await redirectToLogin();
      } else if (this.needsInitialization) {
        // Check if the user has permission to initialize the server
        if (!this.hasPermission(Permissions.INIT_CONFIG)) {
          await redirectToLogin();
          throw new Error(
            this.$t('initialConfiguration.noPermission') as string,
          );
        }
        await this.$router.replace({ name: RouteName.InitialConfiguration });
      } else {
        // No need to initialise, proceed to "main view"
        await this.fetchCurrentSecurityServer();
        await this.$router.replace({
          name: this.firstAllowedTab.to.name,
        });
      }
    },
  },
});
