












































































































import Vue from 'vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';

export default Vue.extend({
  components: { ValidationProvider, ValidationObserver },
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      serviceType: '',
      url: '',
      serviceCode: '',
    };
  },
  methods: {
    cancel(): void {
      this.$emit('cancel');
      this.clear();
    },
    save(): void {
      this.$emit('save', this.serviceType, this.url, this.serviceCode);
      this.clear();
    },
    clear(): void {
      this.url = '';
      this.serviceCode = '';
      this.serviceType = '';
      requestAnimationFrame(() => {
        (this.$refs.form as InstanceType<typeof ValidationObserver>).reset();
      });
    },
  },
});
