


















































































































































































/***
 * Component for showing the details of a token.
 */
import Vue from 'vue';
import * as api from '@/util/api';
import { encodePathParameter } from '@/util/api';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { Permissions } from '@/global';
import {
  PossibleAction,
  Token,
  TokenPinUpdate,
  TokenType,
} from '@/openapi-types';
import { mapActions, mapState } from 'pinia';
import { useUser } from '@/store/modules/user';
import { useNotifications } from '@/store/modules/notifications';
import { AxiosError } from 'axios';

export default Vue.extend({
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      saveBusy: false,
      loading: false,
      token: {} as Token,
      tokenPinUpdate: {} as TokenPinUpdate,
      isChangePinOpen: false,
      isFriendlyNameFieldDirty: false,
      newPinConfirm: '',
    };
  },
  computed: {
    ...mapState(useUser, ['hasPermission']),

    hasEditPermission(): boolean {
      return this.hasPermission(Permissions.EDIT_TOKEN_FRIENDLY_NAME);
    },
    canUpdatePin(): boolean {
      return this.hasPermission(Permissions.UPDATE_TOKEN_PIN);
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    ...mapActions(useNotifications, ['showError', 'showSuccess']),
    close(): void {
      this.$router.go(-1);
    },

    async save(): Promise<void> {
      this.saveBusy = true;

      try {
        let successMsg = this.$t('keys.tokenSaved') as string;
        if (this.isChangePinOpen) {
          await api.put(
            `/tokens/${encodePathParameter(this.id)}/pin`,
            this.tokenPinUpdate,
          );
          successMsg = this.$t('token.pinChanged') as string;
        }
        if (this.isFriendlyNameFieldDirty) {
          await api.patch(
            `/tokens/${encodePathParameter(this.id)}`,
            this.token,
          );
        }
        this.showSuccess(successMsg);
        this.$router.go(-1);
      } catch (error) {
        // Error comes from axios, so it most probably is AxiosError
        this.showError(error as AxiosError);
      } finally {
        this.saveBusy = false;
      }
    },

    fetchData(): void {
      this.loading = true;
      api
        .get<Token>(`/tokens/${encodePathParameter(this.id)}`)
        .then((res) => {
          this.token = res.data;
        })
        .catch((error) => {
          this.showError(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    canEditName(): boolean {
      return (
        this.token?.possible_actions?.includes(
          PossibleAction.EDIT_FRIENDLY_NAME,
        ) ?? false
      );
    },

    isTokenLoggedIn(): boolean {
      return (
        this.token.possible_actions?.includes(
          PossibleAction.TOKEN_CHANGE_PIN,
        ) ?? false
      );
    },

    isSoftwareToken(): boolean {
      return this.token.type === TokenType.SOFTWARE;
    },

    toggleChangePinOpen(): void {
      if (!this.isTokenLoggedIn()) {
        return;
      }
      this.isChangePinOpen = !this.isChangePinOpen;
      this.tokenPinUpdate.old_pin = '';
      this.tokenPinUpdate.new_pin = '';
      this.newPinConfirm = '';
    },
  },
});
