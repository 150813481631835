










































































/**
 * Table component for an array of keys
 */
import Vue from 'vue';
import KeyRow from './KeyRow.vue';
import CertificateRow from './CertificateRow.vue';
import KeysTableThead from './KeysTableThead.vue';
import {
  Key,
  PossibleAction,
  TokenCertificate,
  TokenCertificateSigningRequest,
} from '@/openapi-types';
import { Permissions } from '@/global';
import { KeysSortColumn } from './keyColumnSorting';
import * as Sorting from './keyColumnSorting';
import { Prop } from 'vue/types/options';
import { mapState } from 'pinia';
import { useUser } from '@/store/modules/user';

export default Vue.extend({
  components: {
    KeyRow,
    CertificateRow,
    KeysTableThead,
  },
  props: {
    keys: {
      type: Array as Prop<Key[]>,
      required: true,
    },
    tokenLoggedIn: {
      type: Boolean,
    },
    tokenType: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      registerDialog: false,
      confirmDeleteCsr: false,
      selectedCert: undefined as TokenCertificate | undefined,
      selectedCsr: undefined as TokenCertificateSigningRequest | undefined,
      selectedKey: undefined as Key | undefined,
      sortDirection: false,
      selectedSort: KeysSortColumn.NAME,
    };
  },

  computed: {
    ...mapState(useUser, ['hasPermission']),
    sortedKeys(): Key[] {
      return Sorting.keyArraySort(
        this.keys,
        this.selectedSort,
        this.sortDirection,
      );
    },
    canCreateCsr(): boolean {
      return (
        this.hasPermission(Permissions.GENERATE_AUTH_CERT_REQ) ||
        this.hasPermission(Permissions.GENERATE_SIGN_CERT_REQ)
      );
    },
    canImportFromToken(): boolean {
      // Can the user import certificate from hardware token
      return this.hasPermission(Permissions.IMPORT_UNKNOWN_CERT);
    },
  },

  methods: {
    setSort(sort: KeysSortColumn): void {
      if (sort === this.selectedSort) {
        this.sortDirection = !this.sortDirection;
      }

      this.selectedSort = sort;
    },
    disableGenerateCsr(key: Key): boolean {
      if (!this.tokenLoggedIn) {
        return true;
      }

      if (
        key.possible_actions?.includes(PossibleAction.GENERATE_AUTH_CSR) ||
        key.possible_actions?.includes(PossibleAction.GENERATE_SIGN_CSR)
      ) {
        return false;
      }

      return true;
    },

    keyClick(key: Key): void {
      this.$emit('key-click', key);
    },
    certificateClick(cert: TokenCertificate, key: Key): void {
      this.$emit('certificate-click', { cert, key });
    },
    generateCsr(key: Key): void {
      this.$emit('generate-csr', key);
    },
    importCert(hash: string): void {
      this.$emit('import-cert-by-hash', hash);
    },
  },
});
