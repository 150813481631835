











































































































































































































































































import Vue from 'vue';
import {
  AddOnStatus,
  Anchor,
  CertificateAuthority,
  TimestampingService,
} from '@/openapi-types';
import * as api from '@/util/api';
import { Permissions } from '@/global';
import TimestampingServiceRow from '@/views/Settings/SystemParameters/TimestampingServiceRow.vue';
import UploadConfigurationAnchorDialog from '@/views/Settings/SystemParameters/UploadConfigurationAnchorDialog.vue';
import { saveResponseAsFile } from '@/util/helpers';
import AddTimestampingServiceDialog from '@/views/Settings/SystemParameters/AddTimestampingServiceDialog.vue';
import { mapActions, mapState } from 'pinia';
import { useNotifications } from '@/store/modules/notifications';
import { useUser } from '@/store/modules/user';

export default Vue.extend({
  components: {
    TimestampingServiceRow,
    UploadConfigurationAnchorDialog,
    AddTimestampingServiceDialog,
  },
  data() {
    return {
      configuratonAnchor: undefined as Anchor | unknown,
      downloadingAnchor: false,
      configuredTimestampingServices: [] as TimestampingService[],
      certificateAuthorities: [] as CertificateAuthority[],
      permissions: Permissions,
      loadingTimestampingservices: false,
      loadingAnchor: false,
      loadingCAs: false,
      loadingMessageLogEnabled: false,
      messageLogEnabled: false,
    };
  },
  computed: {
    ...mapState(useUser, ['hasPermission']),
    orderedCertificateAuthorities(): CertificateAuthority[] {
      const temp = this.certificateAuthorities;

      return temp.sort((authorityA, authorityB) =>
        authorityA.path.localeCompare(authorityB.path),
      );
    },
  },
  created(): void {
    if (this.hasPermission(Permissions.VIEW_ANCHOR)) {
      this.fetchConfigurationAnchor();
    }

    if (this.hasPermission(Permissions.VIEW_TSPS)) {
      this.fetchMessageLogEnabled();
      this.fetchConfiguredTimestampingServiced();
    }

    if (this.hasPermission(Permissions.VIEW_APPROVED_CERTIFICATE_AUTHORITIES)) {
      this.fetchApprovedCertificateAuthorities();
    }
  },
  methods: {
    ...mapActions(useNotifications, ['showError']),

    async fetchConfigurationAnchor() {
      this.loadingAnchor = true;
      return api
        .get<Anchor>('/system/anchor')
        .then((resp) => (this.configuratonAnchor = resp.data))
        .catch((error) => this.showError(error))
        .finally(() => (this.loadingAnchor = false));
    },
    async fetchMessageLogEnabled() {
      this.loadingMessageLogEnabled = true;
      return api
        .get<AddOnStatus>('/diagnostics/addon-status')
        .then((resp) => (this.messageLogEnabled = resp.data.messagelog_enabled))
        .catch((error) => this.showError(error))
        .finally(() => (this.loadingMessageLogEnabled = false));
    },
    async fetchConfiguredTimestampingServiced() {
      this.loadingTimestampingservices = true;
      return api
        .get<TimestampingService[]>('/system/timestamping-services')
        .then((resp) => (this.configuredTimestampingServices = resp.data))
        .catch((error) => this.showError(error))
        .finally(() => (this.loadingTimestampingservices = false));
    },
    async fetchApprovedCertificateAuthorities() {
      this.loadingCAs = true;
      return api
        .get<CertificateAuthority[]>(
          '/certificate-authorities?include_intermediate_cas=true',
        )
        .then((resp) => (this.certificateAuthorities = resp.data))
        .catch((error) => this.showError(error))
        .finally(() => (this.loadingCAs = false));
    },
    downloadAnchor(): void {
      this.downloadingAnchor = true;
      api
        .get('/system/anchor/download', { responseType: 'blob' })
        .then((res) => saveResponseAsFile(res, 'configuration-anchor.xml'))
        .catch((error) => {
          this.showError(error);
        })
        .finally(() => (this.downloadingAnchor = false));
    },
  },
});
