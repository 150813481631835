













































































// View for keys tab
import Vue from 'vue';
import { RouteName } from '@/global';
import TokenExpandable from './TokenExpandable.vue';
import TokenLoginDialog from '@/components/token/TokenLoginDialog.vue';
import HelpButton from '../HelpButton.vue';
import { mapActions, mapState } from 'pinia';
import { useNotifications } from '@/store/modules/notifications';
import { useTokensStore } from '@/store/modules/tokens';

import {
  Key,
  Token,
  TokenCertificate,
  TokenCertificateSigningRequest,
} from '@/openapi-types';
import { deepClone } from '@/util/helpers';

export default Vue.extend({
  components: {
    HelpButton,
    TokenExpandable,
    TokenLoginDialog,
  },
  data() {
    return {
      search: '',
      loginDialog: false,
      logoutDialog: false,
      loading: false,
    };
  },
  computed: {
    ...mapState(useTokensStore, ['tokens', 'selectedToken']),
    filtered(): Token[] {
      if (!this.tokens || this.tokens.length === 0) {
        return [];
      }

      // Sort array by id:s so it doesn't jump around. Order of items in the backend reply changes between requests.
      let arr = deepClone<Token[]>(this.tokens).sort((a, b) => {
        if (a.id < b.id) {
          return -1;
        }
        if (a.id > b.id) {
          return 1;
        }

        // equal id:s. (should not happen)
        return 0;
      });

      // Check that there is a search input
      if (!this.search || this.search.length < 1) {
        return arr;
      }

      const mysearch = this.search.toLowerCase();

      arr.forEach((token: Token) => {
        token.keys.forEach((key: Key) => {
          // Filter the certificates
          const certs = key.certificates.filter((cert: TokenCertificate) => {
            if (cert.owner_id) {
              return cert.owner_id.toLowerCase().includes(mysearch);
            }
            return false;
          });
          key.certificates = certs;

          // Filter the CSR:s
          const csrs = key.certificate_signing_requests.filter(
            (csr: TokenCertificateSigningRequest) => {
              if (csr.id) {
                return csr.id.toLowerCase().includes(mysearch);
              }
              return false;
            },
          );
          key.certificate_signing_requests = csrs;
        });
      });

      arr.forEach((token: Token) => {
        const keys = token.keys.filter((key: Key) => {
          if (key.certificates && key.certificates.length > 0) {
            return true;
          }

          if (
            key.certificate_signing_requests &&
            key.certificate_signing_requests.length > 0
          ) {
            return true;
          }

          if (key.name) {
            return key.name.toLowerCase().includes(mysearch);
          }
          if (key.id) {
            return key.id.toLowerCase().includes(mysearch);
          }
          return false;
        });
        token.keys = keys;
      });

      arr = arr.filter((token: Token) => {
        if (token.keys && token.keys.length > 0) {
          return true;
        }

        return token.name.toLowerCase().includes(mysearch);
      });

      return arr;
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    ...mapActions(useNotifications, ['showError', 'showSuccess']),
    ...mapActions(useTokensStore, ['fetchTokens', 'tokenLogout']),
    fetchData(): void {
      // Fetch tokens from backend
      this.loading = true;
      this.fetchTokens()
        .catch((error) => {
          this.showError(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    acceptTokenLogout(): void {
      if (!this.selectedToken) {
        // eslint-disable-next-line no-console
        console.error('Token is undefined');
        return;
      }

      this.tokenLogout(this.selectedToken.id).then(
        () => {
          this.showSuccess(this.$t('keys.loggedOut'));
        },
        (error) => {
          this.showError(error);
        },
      );

      this.logoutDialog = false;
    },
    tokenLogin(): void {
      this.fetchData();
      this.loginDialog = false;
    },
    addKey() {
      if (!this.selectedToken) {
        // Should not happen
        throw new Error('Token is undefined');
      }

      this.$router.push({
        name: RouteName.AddKey,
        params: {
          tokenId: this.selectedToken.id,
          tokenType: this.selectedToken.type,
        },
      });
    },
  },
});
