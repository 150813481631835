


























































import Vue from 'vue';
import { ValidationProvider } from 'vee-validate';
import { isValidWsdlURL } from '@/util/helpers';

export default Vue.extend({
  components: { ValidationProvider },
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      url: '',
    };
  },

  computed: {
    isValid(): boolean {
      return isValidWsdlURL(this.url);
    },
  },

  methods: {
    cancel(): void {
      this.$emit('cancel');
      this.clear();
    },
    save(): void {
      this.$emit('save', this.url);
      this.clear();
    },
    clear(): void {
      this.url = '';
      (
        this.$refs.serviceUrl as InstanceType<typeof ValidationProvider>
      ).reset();
    },
  },
});
