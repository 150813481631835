var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"step-content-wrapper"},[_c('div',{staticClass:"wizard-info-block"},[_c('div',[_vm._v(" "+_vm._s(_vm.$t('wizard.clientInfo1'))+" "),_c('br'),_c('br'),_vm._v(" "+_vm._s(_vm.$t('wizard.clientInfo2'))+" ")]),_c('div',{staticClass:"action-block"},[_c('xrd-button',{attrs:{"outlined":"","data-test":"select-client-button"},on:{"click":function($event){_vm.showSelectClient = true}}},[_vm._v(_vm._s(_vm.$t('wizard.selectClient')))])],1)]),_c('ValidationObserver',{ref:"form2",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"wizard-step-form-content"},[_c('div',{staticClass:"wizard-row-wrap"},[_c('xrd-form-label',{attrs:{"label-text":_vm.$t('wizard.memberName'),"help-text":_vm.$t('wizard.client.memberNameTooltip')}}),_c('div',{attrs:{"data-test":"selected-member-name"}},[_vm._v(_vm._s(_vm.selectedMemberName))])],1),_c('div',{staticClass:"wizard-row-wrap"},[_c('xrd-form-label',{attrs:{"label-text":_vm.$t('wizard.memberClass'),"help-text":_vm.$t('wizard.client.memberClassTooltip')}}),_c('ValidationProvider',{attrs:{"name":"addClient.memberClass","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"wizard-form-input",attrs:{"items":_vm.memberClassesCurrentInstance,"error-messages":errors,"data-test":"member-class-input","placeholder":_vm.$t('wizard.selectMemberClass'),"outlined":""},model:{value:(_vm.memberClass),callback:function ($$v) {_vm.memberClass=$$v},expression:"memberClass"}})]}}],null,true)})],1),_c('div',{staticClass:"wizard-row-wrap"},[_c('xrd-form-label',{attrs:{"label-text":_vm.$t('wizard.memberCode'),"help-text":_vm.$t('wizard.client.memberCodeTooltip')}}),_c('ValidationProvider',{attrs:{"name":"addClient.memberCode","rules":"required|xrdIdentifier"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"wizard-form-input",attrs:{"type":"text","error-messages":errors,"autofocus":"","placeholder":_vm.$t('wizard.memberCode'),"outlined":"","data-test":"member-code-input"},model:{value:(_vm.memberCode),callback:function ($$v) {_vm.memberCode=$$v},expression:"memberCode"}})]}}],null,true)})],1),_c('div',{staticClass:"wizard-row-wrap"},[_c('xrd-form-label',{attrs:{"label-text":_vm.$t('wizard.subsystemCode'),"help-text":_vm.$t('wizard.client.subsystemCodeTooltip')}}),_c('ValidationProvider',{attrs:{"name":"addClient.subsystemCode","rules":"required|xrdIdentifier"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"wizard-form-input",attrs:{"type":"text","outlined":"","placeholder":_vm.$t('wizard.subsystemCode'),"error-messages":errors,"data-test":"subsystem-code-input"},model:{value:(_vm.subsystemCode),callback:function ($$v) {_vm.subsystemCode=$$v},expression:"subsystemCode"}})]}}],null,true)})],1),(_vm.duplicateClient)?_c('div',{staticClass:"wizard-duplicate-warning"},[_vm._v(" "+_vm._s(_vm.$t('wizard.client.clientExists'))+" ")]):_vm._e()]),_c('div',{staticClass:"button-footer"},[_c('xrd-button',{attrs:{"outlined":"","data-test":"cancel-button"},on:{"click":_vm.cancel}},[_vm._v(_vm._s(_vm.$t('action.cancel')))]),_c('xrd-button',{attrs:{"disabled":invalid || _vm.duplicateClient,"data-test":"next-button"},on:{"click":_vm.done}},[_vm._v(_vm._s(_vm.$t('action.next')))])],1)]}}])}),_c('SelectClientDialog',{attrs:{"title":_vm.$t('wizard.addClientTitle'),"search-label":_vm.$t('wizard.client.searchLabel'),"dialog":_vm.showSelectClient,"selectable-clients":_vm.selectableClients},on:{"cancel":function($event){_vm.showSelectClient = false},"save":_vm.saveSelectedClient}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }