
















































































































import Vue from 'vue';
import * as api from '@/util/api';
import { Permissions } from '@/global';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { Endpoint } from '@/openapi-types';
import { encodePathParameter } from '@/util/api';
import { mapActions, mapState } from 'pinia';
import { useUser } from '@/store/modules/user';
import { useNotifications } from '@/store/modules/notifications';

export default Vue.extend({
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      endpoint: {} as Endpoint,
      confirmDelete: false,
      saveBusy: false,
      touched: false,
      methods: [
        { text: this.$t('endpoints.all'), value: '*' },
        { text: 'GET', value: 'GET' },
        { text: 'POST', value: 'POST' },
        { text: 'PUT', value: 'PUT' },
        { text: 'PATCH', value: 'PATCH' },
        { text: 'DELETE', value: 'DELETE' },
        { text: 'HEAD', value: 'HEAD' },
        { text: 'OPTIONS', value: 'OPTIONS' },
        { text: 'TRACE', value: 'TRACE' },
      ],
    };
  },
  computed: {
    ...mapState(useUser, ['hasPermission']),
    showDelete(): boolean {
      return this.hasPermission(Permissions.DELETE_ENDPOINT);
    },
  },
  created(): void {
    this.fetchData(this.id);
  },
  methods: {
    ...mapActions(useNotifications, ['showError', 'showSuccess']),
    close(): void {
      this.$router.go(-1);
    },
    showDeletePopup(): void {
      this.confirmDelete = true;
    },
    deleteEndpoint(id: string): void {
      api
        .remove(`/endpoints/${encodePathParameter(id)}`)
        .then(() => {
          this.showSuccess(this.$t('endpoints.deleteSuccess'));
          this.$router.go(-1);
        })
        .catch((error) => {
          this.showError(error);
          this.confirmDelete = false;
        });
    },
    saveEndpoint(): void {
      if (!this.endpoint.id) {
        throw new Error('Unable to save endpoint: Endpoint id not defined!');
      }
      api
        .patch(
          `/endpoints/${encodePathParameter(this.endpoint.id)}`,
          this.endpoint,
        )
        .then(() => {
          this.showSuccess(this.$t('endpoints.editSuccess'));
          this.$router.go(-1);
        })
        .catch((error) => {
          this.showError(error);
        });
    },
    fetchData(id: string): void {
      api
        .get<Endpoint>(`/endpoints/${encodePathParameter(id)}`)
        .then((endpoint) => {
          this.endpoint = endpoint.data;
        })
        .catch((error) => {
          this.showError(error);
        });
    },
  },
});
