



























































































































































/**
 * View for 'API keys' tab
 */
import Vue from 'vue';

import { DataTableHeader } from 'vuetify';
import { ApiKey } from '@/global-types';
import HelpButton from '../HelpButton.vue';
import { RouteName, Roles, Permissions } from '@/global';
import * as api from '@/util/api';
import { mapActions, mapState } from 'pinia';
import { useNotifications } from '@/store/modules/notifications';
import { useUser } from '@/store/modules/user';

export default Vue.extend({
  components: {
    HelpButton,
  },
  data() {
    return {
      apiKeys: new Array<ApiKey>(),
      search: '' as string,
      loading: false,
      showOnlyPending: false,
      selectedKey: undefined as undefined | ApiKey,
      selectedRoles: [] as string[],
      showEditDialog: false,
      confirmRevoke: false,
      savingChanges: false,
      removingApiKey: false,
      roles: Roles,
    };
  },
  computed: {
    ...mapState(useUser, ['hasPermission']),
    canCreateApiKey(): boolean {
      return this.hasPermission(Permissions.CREATE_API_KEY);
    },
    canEdit(): boolean {
      return this.hasPermission(Permissions.UPDATE_API_KEY);
    },
    canRevoke(): boolean {
      return this.hasPermission(Permissions.REVOKE_API_KEY);
    },
    headers(): DataTableHeader[] {
      return [
        {
          text: this.$t('apiKey.table.header.id') as string,
          align: 'start',
          value: 'id',
          class: 'xrd-table-header ts-table-header-server-code',
        },
        {
          text: this.$t('apiKey.table.header.roles') as string,
          align: 'start',
          value: 'roles',
          class: 'xrd-table-header ts-table-header-valid-from',
        },

        {
          text: '',
          value: 'button',
          sortable: false,
          class: 'xrd-table-header mr-table-header-buttons',
        },
      ];
    },
  },
  created(): void {
    this.loadKeys();
  },

  methods: {
    ...mapActions(useNotifications, ['showError', 'showSuccess']),
    loadKeys(): void {
      if (this.hasPermission(Permissions.VIEW_API_KEYS)) {
        this.loading = true;
        api
          .get<ApiKey[]>('/api-keys')
          .then((resp) => (this.apiKeys = resp.data))
          .catch((error) => this.showError(error))
          .finally(() => (this.loading = false));
      }
    },
    editKey(apiKey: ApiKey): void {
      this.selectedKey = apiKey;
      this.selectedRoles = [...this.selectedKey.roles];
      this.showEditDialog = true;
    },
    showRevokeDialog(apiKey: ApiKey): void {
      this.selectedKey = apiKey;
      this.confirmRevoke = true;
    },
    createApiKey(): void {
      this.$router.push({
        name: RouteName.CreateApiKey,
      });
    },
    translateRoles(roles: string[]): string[] {
      return !roles
        ? []
        : roles.map((role) => this.$t(`apiKey.role.${role}`) as string);
    },
    revokeApiKey() {
      if (!this.selectedKey) return;

      this.removingApiKey = true;
      return api
        .remove<ApiKey>(
          `/api-keys/${api.encodePathParameter(this.selectedKey.id)}`,
        )
        .then((response) => {
          const key = response.data;
          this.showSuccess(
            this.$t('apiKey.table.action.revoke.success', {
              id: key.id,
            }),
          );
        })
        .catch((error) => this.showError(error))
        .finally(() => {
          this.confirmRevoke = false;
          this.removingApiKey = false;
          this.loadKeys();
        });
    },
    save() {
      if (!this.selectedKey) return;
      this.savingChanges = true;
      return api
        .put<ApiKey>(
          `/api-keys/${api.encodePathParameter(this.selectedKey.id)}`,
          this.selectedRoles,
        )
        .then((response) => {
          const key = response.data as ApiKey;
          this.showSuccess(
            this.$t('apiKey.table.action.edit.success', {
              id: key.id,
            }),
          );
        })
        .catch((error) => this.showError(error))
        .finally(() => {
          this.savingChanges = false;
          this.showEditDialog = false;
          this.loadKeys();
        });
    },
  },
});
