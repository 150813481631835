



















































































/**
 * Component for displaying detailed certificate information
 */

import Vue from 'vue';
import CertificateLine from './CertificateLine.vue';

export default Vue.extend({
  components: {
    CertificateLine,
  },
  props: {
    certificate: {
      type: Object,
      required: true,
    },
  },
});
