






























































import Vue from 'vue';
import * as api from '@/util/api';
import { RouteName } from '@/global';
import { ServiceTypeEnum } from '@/domain';
import { Tab } from '@/ui-types';
import { encodePathParameter } from '@/util/api';
import { mapActions, mapState } from 'pinia';
import { useNotifications } from '@/store/modules/notifications';
import { useServicesStore } from '@/store/modules/services';
import { ServiceClient, Service } from '@/openapi-types';

export default Vue.extend({
  props: {
    serviceId: {
      type: String,
      required: true,
    },
    clientId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      currentTab: undefined as undefined | Tab,
      serviceTypeEnum: ServiceTypeEnum,
    };
  },
  computed: {
    ...mapState(useServicesStore, ['service']),
    tabs(): Tab[] {
      return [
        {
          key: 'parameters',
          name: 'tab.services.parameters',
          to: {
            name: RouteName.ServiceParameters,
            query: { descriptionType: this.$route.query.descriptionType },
            params: {
              clientId: this.clientId,
              serviceId: this.serviceId,
            },
          },
        },
        {
          key: 'endpoints',
          name: 'tab.services.endpoints',
          to: {
            name: RouteName.Endpoints,
            query: { descriptionType: this.$route.query.descriptionType },
            params: {
              clientId: this.clientId,
              serviceId: this.serviceId,
            },
          },
        },
      ];
    },
  },

  created() {
    this.fetchData(this.serviceId);
  },

  methods: {
    ...mapActions(useNotifications, ['showError', 'showSuccess']),
    ...mapActions(useServicesStore, ['setService', 'setServiceClients']),
    fetchData(serviceId: string): void {
      api
        .get<Service>(`/services/${encodePathParameter(serviceId)}`)
        .then((res) => {
          // Set ssl_auth to true if it is returned as null from backend
          this.setService(res.data);
        })
        .catch((error) => {
          this.showError(error);
        });

      api
        .get<ServiceClient[]>(
          `/services/${encodePathParameter(serviceId)}/service-clients`,
        )
        .then((res) => {
          this.setServiceClients(res.data);
        })
        .catch((error) => {
          this.showError(error);
        });
    },

    close(): void {
      this.$router.push({
        name: RouteName.SubsystemServices,
        params: { id: this.clientId },
      });
    },
  },
});
