


































































import Vue from 'vue';
import { mapActions, mapWritableState } from 'pinia';
import { useNotifications } from '@/store/modules/notifications';
import { useCsrStore } from '@/store/modules/certificateSignRequest';

export default Vue.extend({
  data() {
    return {
      disableDone: true,
    };
  },
  computed: {
    ...mapWritableState(useCsrStore, ['keyLabel']),
  },
  methods: {
    ...mapActions(useNotifications, ['showError', 'showSuccess']),
    ...mapActions(useCsrStore, ['requestGenerateCsr']),
    cancel(): void {
      this.$emit('cancel');
    },
    previous(): void {
      this.$emit('previous');
    },
    done(): void {
      this.$emit('done');
    },
    generateCsr(): void {
      this.requestGenerateCsr().then(
        () => {
          this.disableDone = false;
        },
        (error) => {
          this.showError(error);
        },
      );
    },
  },
});
