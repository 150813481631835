



































import Vue from 'vue';
import { Permissions, RouteName } from '@/global';
import { Tab } from '@/ui-types';
import SubTabs from '@/components/layout/SubTabs.vue';

import { mapState } from 'pinia';

import { useUser } from '@/store/modules/user';
import { useClientStore } from '@/store/modules/client';

export default Vue.extend({
  components: {
    SubTabs,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      currentTab: undefined as undefined | Tab,
      confirmUnregisterClient: false as boolean,
      unregisterLoading: false as boolean,
    };
  },
  computed: {
    ...mapState(useUser, ['hasPermission', 'getAllowedTabs']),
    ...mapState(useClientStore, ['client']),

    showUnregister(): boolean {
      if (!this.client) return false;
      return (
        this.client &&
        this.hasPermission(Permissions.SEND_CLIENT_DEL_REQ) &&
        (this.client.status === 'REGISTERED' ||
          this.client.status === 'REGISTRATION_IN_PROGRESS')
      );
    },

    showDelete(): boolean {
      if (
        !this.client ||
        this.client.status === 'REGISTERED' ||
        this.client.status === 'REGISTRATION_IN_PROGRESS'
      ) {
        return false;
      }

      return this.hasPermission(Permissions.DELETE_CLIENT);
    },

    tabs(): Tab[] {
      const allTabs: Tab[] = [
        {
          key: 'details',
          name: 'tab.client.details',
          to: {
            name: RouteName.SubsystemDetails,
            params: { id: this.id },
          },
        },
        {
          key: 'serviceClients',
          name: 'tab.client.serviceClients',
          to: {
            name: RouteName.SubsystemServiceClients,
            params: { id: this.id },
          },
          permissions: [Permissions.VIEW_CLIENT_ACL_SUBJECTS],
        },
        {
          key: 'services',
          name: 'tab.client.services',
          to: {
            name: RouteName.SubsystemServices,
            params: { id: this.id },
          },
          permissions: [Permissions.VIEW_CLIENT_SERVICES],
        },
        {
          key: 'internalServers',
          name: 'tab.client.internalServers',
          to: {
            name: RouteName.SubsystemServers,
            params: { id: this.id },
          },
          permissions: [Permissions.VIEW_CLIENT_INTERNAL_CERTS],
        },
        {
          key: 'localGroups',
          name: 'tab.client.localGroups',
          to: {
            name: RouteName.SubsystemLocalGroups,
            params: { id: this.id },
          },
          permissions: [Permissions.VIEW_CLIENT_LOCAL_GROUPS],
        },
      ];

      return this.getAllowedTabs(allTabs);
    },
  },
});
