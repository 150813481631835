import { render, staticRenderFns } from "./AddRestDialog.vue?vue&type=template&id=0f55869e&scoped=true&"
import script from "./AddRestDialog.vue?vue&type=script&lang=ts&"
export * from "./AddRestDialog.vue?vue&type=script&lang=ts&"
import style0 from "./AddRestDialog.vue?vue&type=style&index=0&id=0f55869e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f55869e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VRadio,VRadioGroup,VTextField})
