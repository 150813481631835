

































































































































































































































































































































































































































































import Vue from 'vue';
import * as api from '@/util/api';
import {
  BackupEncryptionStatus,
  MessageLogEncryptionStatus,
  OcspResponderDiagnostics,
  GlobalConfDiagnostics,
  AddOnStatus,
  TimestampingServiceDiagnostics,
} from '@/openapi-types';
import { mapActions, mapState } from 'pinia';
import { useNotifications } from '@/store/modules/notifications';
import { useSystemStore } from '@/store/modules/system';

export default Vue.extend({
  data: () => ({
    messageLogEnabled: false,
    timestampingServices: [] as TimestampingServiceDiagnostics[],
    globalConf: undefined as GlobalConfDiagnostics | undefined,
    ocspResponderDiagnostics: [] as OcspResponderDiagnostics[],
    backupEncryptionDiagnostics: undefined as BackupEncryptionStatus | undefined,
    messageLogEncryptionDiagnostics: undefined as MessageLogEncryptionStatus | undefined,
    globalConfLoading: false,
    timestampingLoading: false,
    ocspLoading: false,
    addonStatusLoading: false,
    backupEncryptionLoading: false,
    messageLogEncryptionLoading: false,
  }),
  computed: {
    ...mapState(useSystemStore, ['securityServerVersion']),
  },

  created() {
    this.fetchData();
  },
  methods: {
    ...mapActions(useNotifications, ['showError']),
    fetchData(): void {
      this.globalConfLoading = true;
      this.timestampingLoading = true;
      this.ocspLoading = true;
      this.addonStatusLoading = true;
      this.backupEncryptionLoading = true;
      this.messageLogEncryptionLoading = true;

      api
        .get<AddOnStatus>('/diagnostics/addon-status')
        .then((res) => {
          this.messageLogEnabled = res.data.messagelog_enabled;
        })
        .catch((error) => {
          this.showError(error);
        })
        .finally(() => {
          this.addonStatusLoading = false;
        });

      api
        .get<TimestampingServiceDiagnostics[]>(
          `/diagnostics/timestamping-services`,
        )
        .then((res) => {
          this.timestampingServices = res.data;
        })
        .catch((error) => {
          this.showError(error);
        })
        .finally(() => {
          this.timestampingLoading = false;
        });

      api
        .get<GlobalConfDiagnostics>('/diagnostics/globalconf')
        .then((res) => {
          this.globalConf = res.data;
        })
        .catch((error) => {
          this.showError(error);
        })
        .finally(() => {
          this.globalConfLoading = false;
        });

      api
        .get<OcspResponderDiagnostics[]>('/diagnostics/ocsp-responders')
        .then((res) => {
          this.ocspResponderDiagnostics = res.data;
        })
        .catch((error) => {
          this.showError(error);
        })
        .finally(() => {
          this.ocspLoading = false;
        });

      api
        .get<BackupEncryptionStatus>('/diagnostics/backup-encryption-status')
        .then((res) => {
          this.backupEncryptionDiagnostics = res.data;
        })
        .catch((error) => {
          this.showError(error);
        })
        .finally(() => {
          this.backupEncryptionLoading = false;
        });

      api
        .get<MessageLogEncryptionStatus>('/diagnostics/message-log-encryption-status')
        .then((res) => {
          this.messageLogEncryptionDiagnostics = res.data;
        })
        .catch((error) => {
          this.showError(error);
        })
        .finally(() => {
          this.messageLogEncryptionLoading = false;
        });
    },

    statusIconTypeTSP(status: string): string {
      if (!status) {
        return '';
      }
      if (this.messageLogEnabled) {
        return this.statusIconType(status);
      } else {
        return this.statusIconType(status) + '-disabled';
      }
    },

    statusIconType(status: string): string {
      if (!status) {
        return '';
      }
      switch (status) {
        case 'OK':
          return 'ok';
        case 'WAITING':
          return 'progress-register';
        case 'FAIL':
          return 'error';
        default:
          return 'error';
      }
    },

    messageLogEncryptionStatusIconType(enabled: boolean): string {
      if (this.messageLogEnabled) {
        return this.encryptionStatusIconType(enabled);
      } else {
        return this.encryptionStatusIconType(enabled) + '-disabled';
      }
    },

    encryptionStatusIconType(enabled: boolean): string {
      switch (enabled) {
        case true:
          return 'ok';
        case false:
          return 'pending';
        default:
          return 'error';
      }
    },

    messageLogEncryptionTooltipIconType(enabled: boolean): string {
      return enabled === false
        ? 'disabled'
        : 'warning-icon'
    },
  },
});
