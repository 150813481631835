





















































/**
 * Table component for an array of keys
 */
import Vue from 'vue';
import { Prop } from 'vue/types/options';
import {
  Key,
  PossibleAction,
  TokenCertificate,
  KeyUsageType,
} from '@/openapi-types';
import { Permissions } from '@/global';
import { mapState } from 'pinia';

import { useUser } from '@/store/modules/user';

export default Vue.extend({
  props: {
    tokenKey: {
      type: Object as Prop<Key>,
      required: true,
    },
    tokenLoggedIn: {
      type: Boolean,
    },
  },
  computed: {
    ...mapState(useUser, ['hasPermission']),
    showGenerateCsr(): boolean {
      // Check if the user has permission to see generate csr action
      if (this.tokenKey.usage === KeyUsageType.AUTHENTICATION) {
        return this.hasPermission(Permissions.GENERATE_AUTH_CERT_REQ);
      } else if (this.tokenKey.usage === KeyUsageType.SIGNING) {
        return this.hasPermission(Permissions.GENERATE_SIGN_CERT_REQ);
      }

      // If key doesn't have a usage type it is in the "unknown" category. Then any permission is fine.
      return (
        this.hasPermission(Permissions.GENERATE_AUTH_CERT_REQ) ||
        this.hasPermission(Permissions.GENERATE_SIGN_CERT_REQ)
      );
    },

    disableGenerateCsr(): boolean {
      // Check if the generate csr action should be disabled
      if (
        this.tokenKey.possible_actions?.includes(
          PossibleAction.GENERATE_AUTH_CSR,
        ) ||
        this.tokenKey.possible_actions?.includes(
          PossibleAction.GENERATE_SIGN_CSR,
        )
      ) {
        return false;
      }

      return true;
    },
  },
  methods: {
    keyClick(): void {
      this.$emit('key-click');
    },
    certificateClick(cert: TokenCertificate, key: Key): void {
      this.$emit('certificate-click', { cert, key });
    },
    generateCsr(): void {
      this.$emit('generate-csr');
    },
  },
});
