

































import Vue from 'vue';
import AppIcon from './AppIcon.vue';
import AppDropMenu from './AppDropMenu.vue';

export default Vue.extend({
  components: {
    AppIcon,
    AppDropMenu,
  },
});
