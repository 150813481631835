








































































































import Vue from 'vue';
import TokenLoginDialog from '@/components/token/TokenLoginDialog.vue';
import { Token } from '@/openapi-types';
import { mapActions, mapState } from 'pinia';

import { useNotifications } from '@/store/modules/notifications';
import { useTokensStore } from '@/store/modules/tokens';
import { useCsrStore } from '@/store/modules/certificateSignRequest';

export default Vue.extend({
  components: {
    TokenLoginDialog,
  },
  data() {
    return {
      search: undefined as string | undefined,
      disableDone: true,
      tokenGroup: undefined as Token | undefined,
      loginDialog: false,
    };
  },
  computed: {
    ...mapState(useTokensStore, ['tokens', 'tokensFilteredByName']),

    filteredTokens: {
      get(): Token[] {
        return this.tokensFilteredByName(this.search);
      },
    },

    disableSelection() {
      if (this.tokens.length === 1) {
        return true;
      }
      return false;
    },

    disableNext() {
      if (this.tokenGroup) {
        return false;
      }
      return true;
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    ...mapActions(useNotifications, ['showError', 'showSuccess']),
    ...mapActions(useCsrStore, ['setCsrTokenId']),
    ...mapActions(useTokensStore, ['setSelectedToken', 'fetchTokens']),
    cancel(): void {
      this.$emit('cancel');
    },
    previous(): void {
      this.$emit('previous');
    },
    done(): void {
      if (!this.tokenGroup?.id) {
        return;
      }
      this.setCsrTokenId(this.tokenGroup.id);
      this.$emit('done');
    },
    confirmLogin(token: Token): void {
      this.setSelectedToken(token);
      this.loginDialog = true;
    },
    tokenLogin(): void {
      this.fetchData();
      this.loginDialog = false;
    },
    fetchData(): void {
      // Fetch tokens from backend
      this.fetchTokens()
        .then(() => {
          // Preselect the token if there is only one
          if (
            this.filteredTokens.length === 1 &&
            this.filteredTokens[0].logged_in
          ) {
            this.tokenGroup = this.filteredTokens[0];
          }
        })
        .catch((error) => {
          this.showError(error);
        });
    },
  },
});
