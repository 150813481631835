








































import Vue from 'vue';
import axios from 'axios';
import Snackbar from '@/components/ui/Snackbar.vue';
import AppFooter from '@/components/layout/AppFooter.vue';
import AppToolbar from '@/components/layout/AppToolbar.vue';
import { RouteName } from '@/global';
import { mapActions } from 'pinia';
import { useUser } from '@/store/modules/user';

export default Vue.extend({
  name: 'App',
  components: {
    AppFooter,
    AppToolbar,
    Snackbar,
  },
  computed: {
    loginView(): boolean {
      return this.$route.name !== RouteName.Login;
    },
  },

  created() {
    // Add a response interceptor
    axios.interceptors.response.use(
      (response) => {
        // Set user authentication status in store
        this.authUser();
        return response;
      },
      (error) => {
        /*
          Check if error is a proper "unauthorized error" meaning it is not happening in sending login form data.
          Also the response from from session timeout polling is handled in AppBase -component
         */
        if (
          error?.response?.status === 401 &&
          this.$router.currentRoute.name !== 'login'
        ) {
          // if you ever get an unauthorized, logout the user
          this.setSessionAlive(false);
        }
        // If the request is made with responseType: blob, but backend responds with json error
        if (
          error.request.responseType === 'blob' &&
          error.response.data instanceof Blob &&
          error.response.data.type &&
          error.response.data.type.toLowerCase().indexOf('json') != -1
        ) {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = () => {
              error.response.data = JSON.parse(reader.result as string);
              resolve(Promise.reject(error));
            };

            reader.onerror = () => {
              reject(error);
            };

            reader.readAsText(error.response.data);
          });
        }

        // Do something with response error
        return Promise.reject(error);
      },
    );

    // Session-status api is called before accessing any view. The session-status data is only used to prevent
    // opening views that user aren't allowed to see (flickering).
    this.fetchSessionStatus();
  },
  methods: {
    ...mapActions(useUser, [
      'setSessionAlive',
      'fetchSessionStatus',
      'authUser',
    ]),
  },
});
