/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * certificate status
 */
export enum CertificateStatus {
    SAVED = 'SAVED',
    REGISTRATION_IN_PROGRESS = 'REGISTRATION_IN_PROGRESS',
    REGISTERED = 'REGISTERED',
    DELETION_IN_PROGRESS = 'DELETION_IN_PROGRESS',
    GLOBAL_ERROR = 'GLOBAL_ERROR',
}