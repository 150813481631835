















































// View for a token
import Vue from 'vue';
import { Colors } from '@/global';
import { CertificateStatus, Key, TokenCertificate } from '@/openapi-types';
import { Prop } from 'vue/types/options';

export default Vue.extend({
  props: {
    keys: {
      type: Array as Prop<Key[]>,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    arrowState: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      errors: 0,
      registered: 0,
      certificateCount: 0,
      colors: Colors,
    };
  },
  computed: {},
  created() {
    this.countStates();
  },
  methods: {
    countStates(): void {
      // Count the amounts of the different Certificate states
      this.keys
        .flatMap((key: Key) => key.certificates)
        .forEach((cert: TokenCertificate) => {
          if (cert.status === CertificateStatus.GLOBAL_ERROR) {
            this.errors++;
          } else if (cert.status === CertificateStatus.REGISTERED) {
            this.registered++;
          }
          this.certificateCount++;
        });
    },
    arrowClick(): void {
      this.$emit('click');
    },
  },
});
