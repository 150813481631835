































import Vue from 'vue';
import ClientsDataTable from './ClientsDataTable.vue';
export default Vue.extend({
  components: {
    ClientsDataTable,
  },
});
