






















































































































































































// View for a token
import Vue from 'vue';
import { Permissions, RouteName } from '@/global';
import KeysTable from './KeysTable.vue';
import KeysTableTitle from './KeysTableTitle.vue';
import UnknownKeysTable from './UnknownKeysTable.vue';
import { Key, KeyUsageType, Token, TokenCertificate } from '@/openapi-types';
import * as api from '@/util/api';
import { FileUploadResult } from '@niis/shared-ui';
import { encodePathParameter } from '@/util/api';
import TokenLoggingButton from '@/views/KeysAndCertificates/SignAndAuthKeys/TokenLoggingButton.vue';
import { Prop } from 'vue/types/options';
import { Colors } from '@/global';
import {
  getTokenUIStatus,
  TokenUIStatus,
} from '@/views/KeysAndCertificates/SignAndAuthKeys/TokenStatusHelper';
import { mapActions, mapState } from 'pinia';
import { useUser } from '@/store/modules/user';
import { useNotifications } from '@/store/modules/notifications';
import { useTokensStore } from '@/store/modules/tokens';

export default Vue.extend({
  components: {
    KeysTable,
    KeysTableTitle,
    UnknownKeysTable,
    TokenLoggingButton,
  },
  props: {
    token: {
      type: Object as Prop<Token>,
      required: true,
    },
  },
  data() {
    return {
      colors: Colors,
      authKeysOpen: true,
      signKeysOpen: true,
      unknownKeysOpen: true,
    };
  },
  computed: {
    ...mapState(useUser, ['hasPermission']),

    ...mapState(useTokensStore, ['tokenExpanded']),

    canActivateToken(): boolean {
      return this.hasPermission(Permissions.ACTIVATE_DEACTIVATE_TOKEN);
    },
    canImportCertificate(): boolean {
      return (
        this.hasPermission(Permissions.IMPORT_AUTH_CERT) ||
        this.hasPermission(Permissions.IMPORT_SIGN_CERT)
      );
    },
    canAddKey(): boolean {
      return this.hasPermission(Permissions.GENERATE_KEY);
    },

    tokenLabelKey(): string {
      const status: TokenUIStatus = getTokenUIStatus(this.token.status);

      if (status === TokenUIStatus.Inactive) {
        return 'keys.tokenStatus.inactive';
      } else if (status === TokenUIStatus.Unavailable) {
        return 'keys.tokenStatus.unavailable';
      } else if (status === TokenUIStatus.Unsaved) {
        return 'keys.tokenStatus.unsaved';
      }

      return ''; // if TokenUIStatus is Active or Available or unknown return empty string
    },

    tokenIcon(): string {
      const status: TokenUIStatus = getTokenUIStatus(this.token.status);

      if (status === TokenUIStatus.Inactive) {
        return 'icon-Cancel';
      } else if (status === TokenUIStatus.Unavailable) {
        return 'icon-Error';
      } else if (status === TokenUIStatus.Unsaved) {
        return 'icon-Error';
      }

      return '';
    },

    tokenStatusClass(): string {
      const status: TokenUIStatus = getTokenUIStatus(this.token.status);

      if (status === TokenUIStatus.Inactive) {
        return 'inactive';
      } else if (status === TokenUIStatus.Unavailable) {
        return 'unavailable';
      } else if (status === TokenUIStatus.Unsaved) {
        return 'unsaved';
      }

      return '';
    },
    tokenStatusColor(): string {
      const status: TokenUIStatus = getTokenUIStatus(this.token.status);

      if (status === TokenUIStatus.Inactive) {
        return this.colors.Black50;
      } else if (
        status === TokenUIStatus.Unavailable ||
        status === TokenUIStatus.Unsaved
      ) {
        return this.colors.Error; // Red
      } else {
        return this.colors.Black100;
      }
    },
  },
  created() {
    if (this.getAuthKeys(this.token.keys).length > 10) {
      this.authKeysOpen = false;
    }
    if (this.getSignKeys(this.token.keys).length > 10) {
      this.signKeysOpen = false;
    }
  },
  methods: {
    ...mapActions(useNotifications, ['showError', 'showSuccess']),
    ...mapActions(useTokensStore, [
      'setSelectedToken',
      'hideToken',
      'expandToken',
    ]),
    addKey(): void {
      this.setSelectedToken(this.token);
      this.$emit('add-key');
    },

    login(): void {
      this.setSelectedToken(this.token);
      this.$emit('token-login');
    },

    logout(): void {
      this.setSelectedToken(this.token);
      this.$emit('token-logout');
    },

    tokenNameClick(): void {
      this.isExpanded(this.token.id)
        ? this.descClose(this.token.id)
        : this.descOpen(this.token.id);
    },

    tokenClick(token: Token): void {
      this.$router.push({
        name: RouteName.Token,
        params: { id: token.id },
      });
    },

    keyClick(key: Key): void {
      this.$router.push({
        name: RouteName.Key,
        params: { id: key.id },
      });
    },

    certificateClick(payload: { cert: TokenCertificate; key: Key }): void {
      this.$router.push({
        name: RouteName.Certificate,
        params: {
          hash: payload.cert.certificate_details.hash,
          usage: payload.key.usage ?? 'undefined',
        },
      });
    },

    getAuthKeys(keys: Key[]): Key[] {
      const filtered = keys.filter((key: Key) => {
        return key.usage === KeyUsageType.AUTHENTICATION;
      });

      return filtered;
    },

    getSignKeys(keys: Key[]): Key[] {
      return keys.filter((key: Key) => key.usage === KeyUsageType.SIGNING);
    },

    getOtherKeys(keys: Key[]): Key[] {
      // Keys that don't have assigned usage type
      return keys.filter(
        (key: Key) =>
          key.usage !== KeyUsageType.SIGNING &&
          key.usage !== KeyUsageType.AUTHENTICATION,
      );
    },

    descClose(tokenId: string) {
      this.hideToken(tokenId);
    },
    descOpen(tokenId: string) {
      this.expandToken(tokenId);
    },
    isExpanded(tokenId: string) {
      return this.tokenExpanded(tokenId);
    },

    importCert(event: FileUploadResult) {
      api
        .post('/token-certificates', event.buffer, {
          headers: {
            'Content-Type': 'application/octet-stream',
          },
        })
        .then(
          () => {
            this.showSuccess(this.$t('keys.importCertSuccess'));
            this.fetchData();
          },
          (error) => {
            this.showError(error);
          },
        );
    },
    importCertByHash(hash: string) {
      api
        .post(`/token-certificates/${encodePathParameter(hash)}/import`, {})
        .then(
          () => {
            this.showSuccess(this.$t('keys.importCertSuccess'));
            this.fetchData();
          },
          (error) => {
            this.showError(error);
          },
        );
    },
    generateCsr(key: Key) {
      this.$router.push({
        name: RouteName.GenerateCertificateSignRequest,
        params: {
          keyId: key.id,
          tokenType: this.token.type,
        },
      });
    },
    fetchData(): void {
      // Fetch tokens from backend
      this.$emit('refresh-list');
    },
  },
});
