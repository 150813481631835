








































































































import Vue from 'vue';
import * as api from '@/util/api';
import { Permissions } from '@/global';
import {
  TokenCertificate,
  PossibleActions as PossibleActionsList,
  KeyUsageType,
  PossibleAction,
} from '@/openapi-types';
import CertificateInfo from '@/components/certificate/CertificateInfo.vue';
import CertificateHash from '@/components/certificate/CertificateHash.vue';
import UnregisterErrorDialog from './UnregisterErrorDialog.vue';
import { encodePathParameter } from '@/util/api';
import { PossibleActions } from '@/openapi-types/models/PossibleActions';
import { mapActions, mapState } from 'pinia';
import { useNotifications } from '@/store/modules/notifications';
import { useUser } from '@/store/modules/user';

export default Vue.extend({
  components: {
    CertificateInfo,
    CertificateHash,
    UnregisterErrorDialog,
  },
  props: {
    hash: {
      type: String,
      required: true,
    },
    usage: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      confirm: false,
      certificate: undefined as TokenCertificate | undefined,
      possibleActions: [] as PossibleActions,
      confirmUnregisterCertificate: false,
      confirmUnregisterError: false,
      unregisterLoading: false,
      unregisterErrorResponse: undefined as undefined | Record<string, unknown>,
    };
  },
  computed: {
    ...mapState(useUser, ['hasPermission']),
    showDelete(): boolean {
      if (this.possibleActions.includes(PossibleAction.DELETE)) {
        if (this.usage === KeyUsageType.SIGNING) {
          return this.hasPermission(Permissions.DELETE_SIGN_CERT);
        } else if (this.usage === KeyUsageType.AUTHENTICATION) {
          return this.hasPermission(Permissions.DELETE_AUTH_CERT);
        } else {
          return this.hasPermission(Permissions.DELETE_UNKNOWN_CERT);
        }
      } else {
        return false;
      }
    },

    showUnregister(): boolean {
      if (
        this.possibleActions.includes(PossibleAction.UNREGISTER) &&
        this.hasPermission(Permissions.SEND_AUTH_CERT_DEL_REQ)
      ) {
        return true;
      } else {
        return false;
      }
    },

    showActivate(): boolean {
      if (this.certificate === null) {
        return false;
      }

      if (this.possibleActions.includes(PossibleAction.ACTIVATE)) {
        if (this.usage === KeyUsageType.SIGNING) {
          return this.hasPermission(Permissions.ACTIVATE_DISABLE_SIGN_CERT);
        } else {
          return this.hasPermission(Permissions.ACTIVATE_DISABLE_AUTH_CERT);
        }
      }

      return false;
    },

    showDisable(): boolean {
      if (this.certificate === null) {
        return false;
      }

      if (this.possibleActions.includes(PossibleAction.DISABLE)) {
        if (this.usage === KeyUsageType.SIGNING) {
          return this.hasPermission(Permissions.ACTIVATE_DISABLE_SIGN_CERT);
        } else {
          return this.hasPermission(Permissions.ACTIVATE_DISABLE_AUTH_CERT);
        }
      }

      return false;
    },
  },
  created() {
    this.fetchData(this.hash);
  },

  methods: {
    ...mapActions(useNotifications, ['showError', 'showSuccess']),
    close(): void {
      this.$router.go(-1);
    },
    fetchData(hash: string): void {
      // Fetch certificate data
      api
        .get<TokenCertificate>(
          `/token-certificates/${encodePathParameter(hash)}`,
        )
        .then((res) => {
          this.certificate = res.data;
        })
        .catch((error) => {
          this.showError(error);
        });

      // Fetch possible actions
      api
        .get<PossibleActionsList>(
          `/token-certificates/${hash}/possible-actions`,
        )
        .then((res) => {
          this.possibleActions = res.data;
        })
        .catch((error) => {
          this.showError(error);
        });
    },
    showConfirmDelete(): void {
      this.confirm = true;
    },
    deleteCertificate(): void {
      this.confirm = false;

      api
        .remove(`/token-certificates/${encodePathParameter(this.hash)}`)
        .then(() => {
          this.close();
          this.showSuccess(this.$t('cert.certDeleted'));
        })
        .catch((error) => {
          this.showError(error);
        });
    },
    activateCertificate(hash: string): void {
      api
        .put(`/token-certificates/${encodePathParameter(hash)}/activate`, hash)
        .then(() => {
          this.showSuccess(this.$t('cert.activateSuccess'));
          this.fetchData(this.hash);
        })
        .catch((error) => this.showError(error));
    },
    deactivateCertificate(hash: string): void {
      api
        .put(`token-certificates/${encodePathParameter(hash)}/disable`, hash)
        .then(() => {
          this.showSuccess(this.$t('cert.disableSuccess'));
          this.fetchData(this.hash);
        })
        .catch((error) => this.showError(error));
    },

    unregisterCert(): void {
      this.unregisterLoading = true;

      if (!this.certificate) {
        return;
      }

      api
        .put(
          `/token-certificates/${this.certificate.certificate_details.hash}/unregister`,
          {},
        )
        .then(() => {
          this.showSuccess(this.$t('keys.certificateUnregistered'));
          this.fetchData(this.hash);
        })
        .catch((error) => {
          if (
            error?.response?.data?.error?.code ===
            'management_request_sending_failed'
          ) {
            this.unregisterErrorResponse = error.response;
          } else {
            this.showError(error);
          }

          this.confirmUnregisterError = true;
        })
        .finally(() => {
          this.confirmUnregisterCertificate = false;
          this.unregisterLoading = false;
        });
    },

    markForDeletion(): void {
      if (!this.certificate) {
        return;
      }

      api
        .put(
          `/token-certificates/${this.certificate.certificate_details.hash}/mark-for-deletion`,
          {},
        )
        .then(() => {
          this.showSuccess(this.$t('keys.certMarkedForDeletion'));
          this.confirmUnregisterError = false;
          this.$emit('refresh-list');
        })
        .catch((error) => {
          this.showError(error);
          this.confirmUnregisterError = false;
        });
    },
  },
});
