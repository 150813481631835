































































































































import Vue from 'vue';

import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { Permissions } from '@/global';
import { CsrFormat, KeyUsageType } from '@/openapi-types';
import { mapActions, mapState, mapWritableState } from 'pinia';
import { useUser } from '@/store/modules/user';
import { useCsrStore } from '@/store/modules/certificateSignRequest';

export default Vue.extend({
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    saveButtonText: {
      type: String,
      default: 'action.continue',
    },
    showPreviousButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      usageTypes: KeyUsageType,
      usageList: Object.values(KeyUsageType),
      csrFormatList: Object.values(CsrFormat),
      permissionForUsage: true,
    };
  },
  computed: {
    ...mapState(useCsrStore, [
      'memberIds',
      'filteredServiceList',
      'isUsageReadOnly',
    ]),
    ...mapWritableState(useCsrStore, [
      'usage',
      'csrClient',
      'csrFormat',
      'certificationService',
    ]),
    ...mapState(useUser, ['hasPermission']),
  },

  watch: {
    filteredServiceList(val) {
      // Set first certification service selected as default when the list is updated
      if (val?.length === 1) {
        this.certificationService = val[0].name;
      }
    },
    memberIds(val) {
      // Set first client selected as default when the list is updated
      if (val?.length === 1) {
        this.csrClient = val[0].id;
      }
    },
  },

  created() {
    // Fetch member id:s for the client selection dropdown
    this.fetchAllMemberIds();

    // Check if the user has permission for only one type of CSR
    const signPermission = this.hasPermission(
      Permissions.GENERATE_SIGN_CERT_REQ,
    );
    const authPermission = this.hasPermission(
      Permissions.GENERATE_AUTH_CERT_REQ,
    );

    if (signPermission && !authPermission) {
      // lock usage type to sign
      this.usage = KeyUsageType.SIGNING;
      this.permissionForUsage = false;
    }

    if (!signPermission && authPermission) {
      // lock usage type to auth
      this.usage = KeyUsageType.AUTHENTICATION;
      this.permissionForUsage = false;
    }
  },
  methods: {
    ...mapActions(useCsrStore, ['fetchAllMemberIds']),
    done(): void {
      this.$emit('done');
    },
    previous(): void {
      this.$emit('previous');
    },
    cancel(): void {
      this.$emit('cancel');
    },
  },
});
