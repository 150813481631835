




















































/**
 * View for 'backup and restore' tab
 */
import Vue from 'vue';
import { Permissions } from '@/global';
import * as api from '@/util/api';
import { Backup, BackupExt } from '@/openapi-types';
import { mapActions, mapState } from 'pinia';
import { useUser } from '@/store/modules/user';
import { useNotifications } from '@/store/modules/notifications';
import VueI18n from 'vue-i18n';
import Values = VueI18n.Values;
import { encodePathParameter } from '@/util/api';
import { saveResponseAsFile } from '@/util/helpers';

const uploadBackup = (backupFile: File, ignoreWarnings = false) => {
  const formData = new FormData();
  formData.set('file', backupFile, backupFile.name);
  return api.post(
    `/backups/upload?ignore_warnings=${ignoreWarnings}`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
};

export default Vue.extend({
  data() {
    return {
      search: '' as string,
      creatingBackup: false,
      uploadingBackup: false,
      needsConfirmation: false,
      uploadedFile: null as File | null,
      backups: [] as Backup[],
      loadingBackups: false,
    };
  },
  computed: {
    ...mapState(useUser, ['hasPermission']),
    canBackup(): boolean {
      return this.hasPermission(Permissions.BACKUP_CONFIGURATION);
    },
  },
  created(): void {
    this.fetchData();
  },
  methods: {
    ...mapActions(useNotifications, [
      'showError',
      'showSuccess',
      'showWarningMessage',
    ]),
    backupBandler() {
      return {
        showError: this.showError,
        showSuccess: this.displaySuccess,
        showWarning: this.displayWarning,
        create: this.createBackup,
        upload: uploadBackup,
        delete: this.deleteBackup,
        download: this.downloadBackup,
        restore: this.downloadBackup,
      };
    },
    fetchData() {
      this.loadingBackups = true;
      return api
        .get<Backup[]>('/backups')
        .then((res) => {
          this.backups = res.data.sort((a, b) => {
            return b.created_at.localeCompare(a.created_at);
          });
        })
        .catch((error) => this.showError(error))
        .finally(() => (this.loadingBackups = false));
    },
    createBackup() {
      this.creatingBackup = true;
      return api.post<BackupExt>('/backups/ext', null)
        .then((resp) => resp.data);
    },
    deleteBackup(filename: string) {
      return api.remove(`/backups/${encodePathParameter(filename)}`);
    },
    downloadBackup(fileName: string) {
      return api
        .get(`/backups/${encodePathParameter(fileName)}/download`, {
          responseType: 'blob',
        })
        .then((resp) => saveResponseAsFile(resp, fileName));
    },
    restoreBackup(fileName: string) {
      api.put(`/backups/${encodePathParameter(fileName)}/restore`, {});
    },
    displaySuccess(textKey: string, data: Values = {}) {
      this.showSuccess(this.$t(textKey, data));
    },
    displayWarning(textKey: string, data: Values = {}) {
      this.showWarningMessage(this.$t(textKey, data));
    },
  },
});
