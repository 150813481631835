


















































































import Vue from 'vue';
import { AccessRight } from '@/openapi-types';
import { Prop } from 'vue/types/options';
import { ServiceCandidate } from '@/ui-types';
export default Vue.extend({
  props: {
    dialog: {
      type: Boolean as Prop<boolean>,
      required: true,
    },
    serviceCandidates: {
      type: Array as Prop<ServiceCandidate[]>,
      required: true,
    },
  },
  data() {
    return {
      selections: [] as AccessRight[],
      search: '' as string,
    };
  },
  methods: {
    save(): void {
      const items = this.selections
        .filter((selection) => selection.service_code.includes(this.search))
        .map(
          (selection): AccessRight => ({
            service_code: selection.service_code,
          }),
        );

      this.$emit('save', items);
      this.clear();
    },
    cancel(): void {
      this.$emit('cancel');
      this.clear();
    },
    clear(): void {
      this.selections = [];
    },
    searchResults(): ServiceCandidate[] {
      const cleanedSearch = this.search.toLowerCase();

      return this.serviceCandidates.filter((candidate: ServiceCandidate) => {
        return (
          candidate.service_code.toLowerCase().includes(cleanedSearch) ||
          candidate.service_title?.toLowerCase().includes(cleanedSearch)
        );
      });
    },
    filterSelections(): AccessRight[] {
      return this.selections.filter((ac: AccessRight) =>
        ac.service_code.includes(this.search),
      );
    },
  },
});
