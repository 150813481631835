var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"xrd-tab-max-width dtlv-outer"},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var dirty = ref.dirty;
var invalid = ref.invalid;
return [_c('div',{staticClass:"dtlv-content"},[_c('xrd-sub-view-title',{attrs:{"title":_vm.$t('keys.tokenDetails')},on:{"close":_vm.close}}),_c('v-row',[_c('v-col',[_c('h3',[_vm._v(_vm._s(_vm.$t('keys.tokenInfo')))]),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"row-title"},[_vm._v(_vm._s(_vm.$t('keys.tokenId')))]),_c('div',{staticClass:"row-data text-break"},[_vm._v(_vm._s(_vm.token.id))])]),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"row-title"},[_vm._v(_vm._s(_vm.$t('keys.type')))]),_c('div',{staticClass:"row-data"},[_vm._v(_vm._s(_vm.token.type))])])]),_c('v-col',[_c('v-row',{attrs:{"no-gutters":""}},[_c('ValidationProvider',{staticClass:"validation-provider",attrs:{"rules":"required","name":"token.friendlyName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"code-input",attrs:{"name":"token.friendlyName","type":"text","outlined":"","label":_vm.$t('keys.friendlyName'),"maxlength":255,"error-messages":errors,"loading":_vm.loading,"disabled":!(_vm.hasEditPermission && _vm.canEditName()),"data-test":"token-friendly-name","autofocus":""},on:{"input":function($event){_vm.isFriendlyNameFieldDirty = true}},model:{value:(_vm.token.name),callback:function ($$v) {_vm.$set(_vm.token, "name", $$v)},expression:"token.name"}})]}}],null,true)})],1),(_vm.isSoftwareToken() && _vm.canUpdatePin)?_c('v-row',{attrs:{"no-gutters":""}},[_c('xrd-expandable',{staticClass:"expandable",attrs:{"is-open":_vm.isChangePinOpen,"is-disabled":!_vm.isTokenLoggedIn(),"data-test":"token-open-pin-change-button"},on:{"open":_vm.toggleChangePinOpen,"close":_vm.toggleChangePinOpen},scopedSlots:_vm._u([{key:"link",fn:function(){return [_c('div',{class:_vm.isTokenLoggedIn() && 'pointer',attrs:{"data-test":"token-open-pin-change-link"},on:{"click":_vm.toggleChangePinOpen}},[_c('span',{staticClass:"font-weight-black"},[_vm._v(" "+_vm._s(_vm.$t('token.changePin'))+" ")])])]},proxy:true},{key:"content",fn:function(){return [_c('v-row',{attrs:{"no-gutters":""}},[_c('ValidationProvider',{staticClass:"validation-provider",attrs:{"rules":"required","name":"token.oldPin"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"code-input",attrs:{"name":"token.oldPin","type":"password","outlined":"","label":_vm.$t('fields.token.oldPin'),"maxlength":255,"error-messages":errors,"loading":_vm.loading,"data-test":"token-change-pin-old"},model:{value:(_vm.tokenPinUpdate.old_pin),callback:function ($$v) {_vm.$set(_vm.tokenPinUpdate, "old_pin", $$v)},expression:"tokenPinUpdate.old_pin"}})]}}],null,true)})],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('ValidationProvider',{staticClass:"validation-provider",attrs:{"rules":"required|confirmed:confirm","name":"token.newPin"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"code-input",attrs:{"name":"token.newPin","type":"password","outlined":"","label":_vm.$t('fields.token.newPin'),"maxlength":255,"error-messages":errors,"loading":_vm.loading,"data-test":"token-change-pin-new"},model:{value:(_vm.tokenPinUpdate.new_pin),callback:function ($$v) {_vm.$set(_vm.tokenPinUpdate, "new_pin", $$v)},expression:"tokenPinUpdate.new_pin"}})]}}],null,true)})],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('ValidationProvider',{staticClass:"validation-provider",attrs:{"rules":"required","vid":"confirm","name":"token.newPinConfirm"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"code-input",attrs:{"name":"token.newPinConfirm","type":"password","outlined":"","label":_vm.$t('fields.token.newPinConfirm'),"maxlength":255,"error-messages":errors,"loading":_vm.loading,"data-test":"token-change-pin-new-confirm"},model:{value:(_vm.newPinConfirm),callback:function ($$v) {_vm.newPinConfirm=$$v},expression:"newPinConfirm"}})]}}],null,true)})],1)]},proxy:true}],null,true)})],1):_vm._e()],1)],1)],1),_c('div',{staticClass:"dtlv-actions-footer"},[_c('xrd-button',{attrs:{"outlined":"","data-test":"token-details-cancel"},on:{"click":function($event){return _vm.close()}}},[_vm._v(_vm._s(_vm.$t('action.cancel')))]),_c('xrd-button',{attrs:{"loading":_vm.saveBusy,"disabled":!dirty || invalid,"data-test":"token-details-save"},on:{"click":function($event){return _vm.save()}}},[_vm._v(_vm._s(_vm.$t('action.save')))])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }