


















































































import Vue from 'vue';
import WizardPageKeyLabel from '@/components/wizard/WizardPageKeyLabel.vue';
import WizardPageCsrDetails from '@/components/wizard/WizardPageCsrDetails.vue';
import WizardPageGenerateCsr from '@/components/wizard/WizardPageGenerateCsr.vue';
import { RouteName } from '@/global';
import { mapActions } from 'pinia';
import { useCsrStore } from '@/store/modules/certificateSignRequest';
import { useNotifications } from '@/store/modules/notifications';

export default Vue.extend({
  components: {
    WizardPageKeyLabel,
    WizardPageCsrDetails,
    WizardPageGenerateCsr,
  },
  props: {
    tokenId: {
      type: String,
      required: true,
    },
    tokenType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      currentStep: 1,
    };
  },
  created() {
    this.setCsrTokenId(this.tokenId);
    this.setCsrTokenType(this.tokenType);
    this.fetchCertificateAuthorities().catch((error) => {
      this.showError(error);
    });
  },
  methods: {
    ...mapActions(useNotifications, ['showError']),
    ...mapActions(useCsrStore, [
      'setCsrTokenId',
      'setCsrTokenType',
      'fetchCsrForm',
      'resetCsrState',
      'fetchCertificateAuthorities',
    ]),

    save(): void {
      this.fetchCsrForm().then(
        () => {
          this.currentStep = 3;
        },
        (error) => {
          this.showError(error);
        },
      );
    },
    cancel(): void {
      this.resetCsrState();
      this.$router.replace({ name: RouteName.SignAndAuthKeys });
    },
    done(): void {
      this.resetCsrState();
      this.$router.replace({ name: RouteName.SignAndAuthKeys });
    },
  },
});
