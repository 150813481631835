










































































import Vue from 'vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { mapState } from 'pinia';
import { useCsrStore } from '@/store/modules/certificateSignRequest';

export default Vue.extend({
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    saveButtonText: {
      type: String,
      default: 'action.done',
    },
    showGenerateButton: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState(useCsrStore, ['csrForm']),
  },
  methods: {
    cancel(): void {
      this.$emit('cancel');
    },
    previous(): void {
      this.$emit('previous');
    },
    done(): void {
      this.$emit('done');
    },
  },
});
