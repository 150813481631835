



































































































































import Vue from 'vue';

import { Permissions, RouteName } from '@/global';
import { FileUploadResult } from '@niis/shared-ui';
import { CertificateDetails } from '@/openapi-types';
import { saveResponseAsFile } from '@/util/helpers';
import * as api from '@/util/api';
import { encodePathParameter } from '@/util/api';
import { mapActions, mapState } from 'pinia';
import { useNotifications } from '@/store/modules/notifications';
import { useUser } from '@/store/modules/user';
import { useClientStore } from '@/store/modules/client';

export default Vue.extend({
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      connectionTypes: [
        { text: 'HTTP', value: 'HTTP' },
        { text: 'HTTPS', value: 'HTTPS' },
        { text: 'HTTPS NO AUTH', value: 'HTTPS_NO_AUTH' },
      ],
      dialog: false,
      selectedCertificate: null,
      revertHack: 0,
      tlsCertLoading: false,
      ssCertLoading: false,
    };
  },
  computed: {
    ...mapState(useUser, ['hasPermission']),
    ...mapState(useClientStore, [
      'tlsCertificates',
      'ssCertificate',
      'connectionType',
    ]),

    connectionTypeModel: {
      get(): string | undefined | null {
        return this.connectionType;
      },
      set(value: string) {
        this.saveConnectionType({
          clientId: this.id,
          connType: value,
        })
          .then(() => {
            this.showSuccess(this.$t('internalServers.connTypeUpdated'));
          })
          .catch((error) => {
            this.revertHack += 1;
            this.showError(error);
          });
      },
    },

    showConnectionType(): boolean {
      return this.hasPermission(
        Permissions.VIEW_CLIENT_INTERNAL_CONNECTION_TYPE,
      );
    },
    canEditConnectionType(): boolean {
      return this.hasPermission(
        Permissions.EDIT_CLIENT_INTERNAL_CONNECTION_TYPE,
      );
    },
    canViewTlsCertDetails(): boolean {
      return this.hasPermission(Permissions.VIEW_CLIENT_INTERNAL_CERT_DETAILS);
    },
    canAddTlsCert(): boolean {
      return this.hasPermission(Permissions.ADD_CLIENT_INTERNAL_CERT);
    },
    canViewSSCert(): boolean {
      return this.hasPermission(Permissions.VIEW_INTERNAL_TLS_CERT);
    },
    canExportSSCert(): boolean {
      return this.hasPermission(Permissions.EXPORT_INTERNAL_TLS_CERT);
    },
  },
  created() {
    this.fetchSSCert(this.id);
    this.fetchTlsCerts(this.id);
  },
  methods: {
    ...mapActions(useNotifications, ['showError', 'showSuccess']),
    ...mapActions(useClientStore, [
      'saveConnectionType',
      'fetchTlsCertificates',
      'fetchSSCertificate',
    ]),
    onFileChange(event: FileUploadResult): void {
      api
        .post(
          `/clients/${encodePathParameter(this.id)}/tls-certificates`,
          event.buffer,
          {
            headers: {
              'Content-Type': 'application/octet-stream',
            },
          },
        )
        .then(
          () => {
            // Refresh the tls cert list
            this.fetchTlsCerts(this.id);
          },
          (error) => {
            this.showError(error);
          },
        );
    },

    fetchTlsCerts(id: string): void {
      this.tlsCertLoading = true;
      this.fetchTlsCertificates(id)
        .catch((error) => {
          this.showError(error);
        })
        .finally(() => (this.tlsCertLoading = false));
    },

    exportSSCertificate(): void {
      api
        .get('/system/certificate/export', { responseType: 'arraybuffer' })
        .then((response) => {
          saveResponseAsFile(response);
        })
        .catch((error) => {
          this.showError(error);
        });
    },

    fetchSSCert(id: string): void {
      this.ssCertLoading = true;
      this.fetchSSCertificate(id)
        .catch((error) => {
          this.showError(error);
        })
        .finally(() => (this.ssCertLoading = false));
    },

    openCertificate(cert: CertificateDetails): void {
      this.$router.push({
        name: RouteName.ClientTlsCertificate,
        params: {
          id: this.id,
          hash: cert.hash,
        },
      });
    },
    closeDialog(): void {
      this.dialog = false;
    },
  },
});
