























































import Vue from 'vue';
import { Tab } from '@/ui-types';
import { mainTabs } from '@/global';
import AppIcon from './AppIcon.vue';
import AppDropMenu from './AppDropMenu.vue';
import { mapState } from 'pinia';
import { useUser } from '@/store/modules/user';

export default Vue.extend({
  components: {
    AppIcon,
    AppDropMenu,
  },
  data() {
    return {
      currentTab: undefined as undefined | Tab,
    };
  },
  computed: {
    ...mapState(useUser, ['getAllowedTabs']),
    allowedTabs(): Tab[] {
      return this.getAllowedTabs(mainTabs);
    },
  },
});
