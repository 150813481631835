





































import Vue from 'vue';
import { mapState } from 'pinia';
import { useUser } from '@/store/modules/user';

export default Vue.extend({
  computed: {
    ...mapState(useUser, ['firstAllowedTab']),
  },
  methods: {
    home(): void {
      this.$router
        .replace({
          name: this.firstAllowedTab.to.name,
        })
        .catch((err) => {
          // Ignore the error regarding navigating to the same path
          if (err.name === 'NavigationDuplicated') {
            // eslint-disable-next-line no-console
            console.info('Duplicate navigation');
          } else {
            // Throw for any other errors
            throw err;
          }
        });
    },
  },
});
