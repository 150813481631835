
























































import Vue from 'vue';
import * as api from '@/util/api';
import { encodePathParameter } from '@/util/api';
import { mapActions } from 'pinia';
import { useNotifications } from '@/store/modules/notifications';

export default Vue.extend({
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      confirmMakeOwner: false as boolean,
      makeOwnerLoading: false as boolean,
    };
  },

  methods: {
    ...mapActions(useNotifications, ['showError', 'showSuccess']),
    makeOwner(): void {
      this.makeOwnerLoading = true;

      api
        .put(`/clients/${encodePathParameter(this.id)}/make-owner`, {})
        .then(
          () => {
            this.showSuccess(this.$t('client.action.makeOwner.success'));
          },
          (error) => {
            this.showError(error);
          },
        )
        .finally(() => {
          this.$emit('done', this.id);
          this.confirmMakeOwner = false;
          this.makeOwnerLoading = false;
        });
    },
  },
});
