































































































































/***
 * Component for showing the details of a key
 */
import Vue from 'vue';
import * as api from '@/util/api';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { Permissions } from '@/global';
import {
  Key,
  KeyUsageType,
  PossibleAction,
  PossibleActions as PossibleActionsList,
  Token,
} from '@/openapi-types';
import { encodePathParameter } from '@/util/api';
import WarningDialog from '@/components/ui/WarningDialog.vue';

import { PossibleActions } from '@/openapi-types/models/PossibleActions';
import { isEmpty } from '@/util/helpers';
import { mapActions, mapState } from 'pinia';
import { useTokensStore } from '@/store/modules/tokens';
import { useUser } from '@/store/modules/user';
import { useNotifications } from '@/store/modules/notifications';

export default Vue.extend({
  components: {
    ValidationProvider,
    ValidationObserver,
    WarningDialog,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      confirmDelete: false,
      touched: false,
      saveBusy: false,
      key: {} as Key,
      possibleActions: [] as PossibleActions,
      deleting: false as boolean,
      warningInfo: [] as string[],
      warningDialog: false as boolean,
      tokenForCurrentKey: {} as Token,
    };
  },
  computed: {
    ...mapState(useTokensStore, ['tokens']),

    ...mapState(useUser, ['hasPermission']),
    canEdit(): boolean {
      if (!this.possibleActions.includes(PossibleAction.EDIT_FRIENDLY_NAME)) {
        return false;
      }

      return this.hasPermission(Permissions.EDIT_KEY_FRIENDLY_NAME);
    },
    canDelete(): boolean {
      if (!this.possibleActions.includes(PossibleAction.DELETE)) {
        return false;
      }

      if (this.key.usage === KeyUsageType.SIGNING) {
        return this.hasPermission(Permissions.DELETE_SIGN_KEY);
      }

      if (this.key.usage === KeyUsageType.AUTHENTICATION) {
        return this.hasPermission(Permissions.DELETE_AUTH_KEY);
      }

      return this.hasPermission(Permissions.DELETE_KEY);
    },
  },
  created() {
    this.fetchData(this.id);
  },
  methods: {
    ...mapActions(useNotifications, ['showError', 'showSuccess']),
    ...mapActions(useTokensStore, ['fetchTokens']),
    close(): void {
      this.$router.go(-1);
    },

    save(): void {
      this.saveBusy = true;

      api
        .patch(`/keys/${encodePathParameter(this.id)}`, this.key)
        .then(() => {
          this.saveBusy = false;
          this.showSuccess(this.$t('keys.keySaved'));
          this.close();
        })
        .catch((error) => {
          this.saveBusy = false;
          this.showError(error);
        });
    },

    async fetchData(id: string) {
      const keyResponse = await api.get<Key>(
        `/keys/${encodePathParameter(id)}`,
      );

      this.key = keyResponse.data;
      this.fetchPossibleActions(id);
      // If the key has no name, use key id instead
      this.setKeyName();

      if (this.tokens?.length === 0) {
        await this.fetchTokens();
      }

      // Find the token that contains current key after token and key are fetched
      this.tokenForCurrentKey = this.tokens.find((token: Token) =>
        token.keys.find((key: Key) => key.id === this.id),
      ) as Token;
    },
    fetchPossibleActions(id: string): void {
      api
        .get<PossibleActionsList>(
          `/keys/${encodePathParameter(id)}/possible-actions`,
        )
        .then((res) => {
          this.possibleActions = res.data;
        })
        .catch((error) => {
          this.showError(error);
        });
    },
    cancelSubmit(): void {
      this.warningDialog = false;
    },
    acceptWarnings(): void {
      this.warningDialog = false;
      this.deleteKey(true);
    },
    deleteKey(ignoreWarnings: boolean): void {
      this.deleting = true;
      this.confirmDelete = false;

      api
        .remove(
          `/keys/${encodePathParameter(
            this.id,
          )}?ignore_warnings=${ignoreWarnings}`,
        )
        .then(() => {
          this.showSuccess(this.$t('keys.keyDeleted'));
          this.close();
        })
        .catch((error) => {
          if (error?.response?.data?.warnings) {
            this.warningInfo = error.response.data.warnings;
            this.warningDialog = true;
          } else {
            this.showError(error);
          }
        })
        .finally(() => (this.deleting = false));
    },
    setKeyName(): void {
      if (isEmpty(this.key.name)) {
        this.key.name = this.key.id;
      }
    },
  },
});
