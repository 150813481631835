








































































import Vue from 'vue';
import { Anchor } from '@/openapi-types';
import * as api from '@/util/api';
import UploadConfigurationAnchorDialog from '@/views/Settings/SystemParameters/UploadConfigurationAnchorDialog.vue';
import { mapActions } from 'pinia';
import { useNotifications } from '@/store/modules/notifications';
import { useGeneral } from '@/store/modules/general';

export default Vue.extend({
  components: {
    UploadConfigurationAnchorDialog,
  },
  props: {
    saveButtonText: {
      type: String,
      default: 'action.continue',
    },
    showPreviousButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showAnchorDialog: false as boolean,
      configuratonAnchor: undefined as Anchor | undefined,
    };
  },

  methods: {
    ...mapActions(useGeneral, ['fetchMemberClassesForCurrentInstance']),
    ...mapActions(useNotifications, ['showError']),
    // Fetch anchor data so it can be shown in the UI
    fetchConfigurationAnchor() {
      api
        .get<Anchor>('/system/anchor')
        .then((resp) => (this.configuratonAnchor = resp.data))
        .catch((error) => this.showError(error));

      // Fetch member classes for owner member step after anchor is ready
      this.fetchMemberClassesForCurrentInstance();
    },
    done(): void {
      this.$emit('done');
    },
  },
});
