




























































import Vue from 'vue';

import { Permissions } from '@/global';
import DeleteClientButton from '@/components/client/DeleteClientButton.vue';
import UnregisterClientButton from '@/components/client/UnregisterClientButton.vue';
import MakeOwnerButton from '@/components/client/MakeOwnerButton.vue';
import { mapActions, mapState } from 'pinia';
import { useNotifications } from '@/store/modules/notifications';
import { useUser } from '@/store/modules/user';
import { useClientStore } from '@/store/modules/client';

export default Vue.extend({
  components: {
    UnregisterClientButton,
    DeleteClientButton,
    MakeOwnerButton,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState(useClientStore, ['client', 'clientLoading']),
    ...mapState(useUser, ['hasPermission']),
    showMakeOwner(): boolean {
      if (!this.client) return false;

      return (
        this.client &&
        this.hasPermission(Permissions.SEND_OWNER_CHANGE_REQ) &&
        this.client.status === 'REGISTERED' &&
        !this.client.owner
      );
    },
    showUnregister(): boolean {
      if (!this.client) return false;

      return (
        this.client &&
        this.hasPermission(Permissions.SEND_CLIENT_DEL_REQ) &&
        (this.client.status === 'REGISTERED' ||
          this.client.status === 'REGISTRATION_IN_PROGRESS')
      );
    },
    showDelete(): boolean {
      if (
        !this.client ||
        this.client.status === 'REGISTERED' ||
        this.client.status === 'REGISTRATION_IN_PROGRESS'
      ) {
        return false;
      }

      return this.hasPermission(Permissions.DELETE_CLIENT);
    },
  },
  created() {
    this.fetchData(this.id);
  },
  methods: {
    ...mapActions(useNotifications, ['showError']),
    ...mapActions(useClientStore, ['fetchClient']),
    fetchData(id: string): void {
      this.fetchClient(id).catch((error) => {
        this.showError(error);
      });
    },
  },
});
