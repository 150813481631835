var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [_c('xrd-simple-dialog',{attrs:{"dialog":_vm.dialog,"width":620,"title":"services.addRest","disable-save":!valid},on:{"save":_vm.save,"cancel":_vm.cancel}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('div',{staticClass:"dlg-edit-row"},[_c('div',{staticClass:"dlg-row-title"},[_vm._v(_vm._s(_vm.$t('services.serviceType')))]),_c('ValidationProvider',{staticClass:"validation-provider dlg-row-input",attrs:{"rules":"required","name":"serviceType"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-radio-group',{attrs:{"name":"serviceType","error-messages":errors,"row":""},model:{value:(_vm.serviceType),callback:function ($$v) {_vm.serviceType=$$v},expression:"serviceType"}},[_c('v-radio',{attrs:{"name":"REST","label":_vm.$t('services.restApiBasePath'),"value":"REST"}}),_c('v-radio',{attrs:{"name":"OPENAPI3","label":_vm.$t('services.OpenApi3Description'),"value":"OPENAPI3"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"pt-3 dlg-input-width"},[_c('ValidationProvider',{staticClass:"validation-provider",attrs:{"rules":"required|restUrl","name":"serviceUrl"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"placeholder":_vm.$t('services.urlPlaceholder'),"label":_vm.$t('services.url'),"name":"serviceUrl","outlined":"","autofocus":"","error-messages":errors},model:{value:(_vm.url),callback:function ($$v) {_vm.url=$$v},expression:"url"}})]}}],null,true)})],1),_c('div',{staticClass:"pt-3 dlg-input-width"},[_c('ValidationProvider',{staticClass:"validation-provider",attrs:{"rules":"required|xrdIdentifier","name":"serviceCode"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","name":"serviceCode","label":_vm.$t('services.serviceCode'),"type":"text","placeholder":_vm.$t('services.serviceCodePlaceholder'),"maxlength":255,"error-messages":errors},model:{value:(_vm.serviceCode),callback:function ($$v) {_vm.serviceCode=$$v},expression:"serviceCode"}})]}}],null,true)})],1)])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }