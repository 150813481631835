
































import Vue from 'vue';
import { CertificateStatus, TokenCertificate } from '@/openapi-types';
import { Prop } from 'vue/types/options';

export default Vue.extend({
  props: {
    certificate: {
      type: Object as Prop<TokenCertificate>,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    status() {
      switch (this.certificate.status) {
        case CertificateStatus.SAVED:
          return 'keys.certStatus.saved';

        case CertificateStatus.REGISTRATION_IN_PROGRESS:
          return 'keys.certStatus.registration';

        case CertificateStatus.REGISTERED:
          return 'keys.certStatus.registered';

        case CertificateStatus.DELETION_IN_PROGRESS:
          return 'keys.certStatus.deletion';

        case CertificateStatus.GLOBAL_ERROR:
          return 'keys.certStatus.globalError';

        default:
          if (!this.certificate.saved_to_configuration) {
            return 'keys.certStatus.onlyInHWToken';
          } else {
            return '-';
          }
      }
    },
    statusIconType() {
      switch (this.certificate.status) {
        case CertificateStatus.SAVED:
          return 'saved';

        case CertificateStatus.REGISTRATION_IN_PROGRESS:
          return 'progress-register';

        case CertificateStatus.REGISTERED:
          return 'ok';

        case CertificateStatus.DELETION_IN_PROGRESS:
          return 'progress-delete';

        case CertificateStatus.GLOBAL_ERROR:
          return 'error';
        default:
          return 'error';
      }
    },
  },
  methods: {},
});
