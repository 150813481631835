





























































import Vue from 'vue';
import { ValidationProvider } from 'vee-validate';
import { mapActions, mapState } from 'pinia';
import { Token } from '@/openapi-types';
import * as api from '@/util/api';
import { encodePathParameter } from '@/util/api';
import { useAlerts } from '@/store/modules/alerts';
import { useNotifications } from '@/store/modules/notifications';
import { useTokensStore } from '@/store/modules/tokens';

export default Vue.extend({
  components: { ValidationProvider },
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      pin: '',
      loading: false,
    };
  },

  computed: {
    ...mapState(useTokensStore, ['selectedToken']),

    isValid(): boolean {
      // Check that input is not empty
      if (this.pin && this.pin.length > 0) {
        return true;
      }
      return false;
    },
  },

  methods: {
    ...mapActions(useAlerts, ['checkAlertStatus']),
    ...mapActions(useNotifications, ['showError', 'showSuccess']),
    cancel(): void {
      this.$emit('cancel');
      this.clear();
    },
    save(): void {
      if (!this.selectedToken) {
        // eslint-disable-next-line no-console
        console.error('Selected token missing');
        return;
      }
      const token: Token = this.selectedToken;

      this.loading = true;
      api
        .put(`/tokens/${encodePathParameter(token.id)}/login`, {
          password: this.pin,
        })
        .then(() => {
          this.loading = false;
          this.showSuccess(this.$t('keys.loggedIn'));
          this.$emit('save');
        })
        .catch((error) => {
          this.loading = false;
          if (
            error.response.status === 400 &&
            error.response.data.error.code === 'pin_incorrect'
          ) {
            (
              this.$refs.tokenPin as InstanceType<typeof ValidationProvider>
            ).setErrors([this.$t('keys.incorrectPin') as string]);
          }

          this.showError(error);
        })
        .finally(() => this.checkAlertStatus());

      this.clear();
    },
    clear(): void {
      this.pin = '';
      (this.$refs.tokenPin as InstanceType<typeof ValidationProvider>).reset();
    },
  },
});
