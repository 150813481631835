





































































import Vue from 'vue';
import WizardPageCsrDetails from '@/components/wizard/WizardPageCsrDetails.vue';
import WizardPageGenerateCsr from '@/components/wizard/WizardPageGenerateCsr.vue';
import { RouteName } from '@/global';
import { mapActions } from 'pinia';
import { useNotifications } from '@/store/modules/notifications';
import { useCsrStore } from '@/store/modules/certificateSignRequest';

export default Vue.extend({
  components: {
    WizardPageCsrDetails,
    WizardPageGenerateCsr,
  },
  props: {
    keyId: {
      type: String,
      required: true,
      default: undefined,
    },
    tokenType: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      currentStep: 1,
    };
  },
  created() {
    this.storeKeyId(this.keyId);
    this.setCsrTokenType(this.tokenType);
    this.fetchKeyData().catch((error) => {
      this.showError(error);
    });
    this.fetchCertificateAuthorities().catch((error) => {
      this.showError(error);
    });
  },
  beforeDestroy() {
    // Clear the store state
    this.resetCsrState();
  },
  methods: {
    ...mapActions(useNotifications, ['showError', 'showSuccess']),
    ...mapActions(useCsrStore, [
      'resetCsrState',
      'setCsrTokenType',
      'storeKeyId',
      'fetchCsrForm',
      'fetchKeyData',
      'fetchCertificateAuthorities',
    ]),
    save(): void {
      this.fetchCsrForm().then(
        () => {
          this.currentStep = 2;
        },
        (error) => {
          this.showError(error);
        },
      );
    },
    cancel(): void {
      this.$router.replace({ name: RouteName.SignAndAuthKeys });
    },
  },
});
