

































































































































import Vue, { VueConstructor } from 'vue';

import { ValidationProvider, ValidationObserver } from 'vee-validate';

import { mapActions, mapState } from 'pinia';
import { useNotifications } from '@/store/modules/notifications';
import { useGeneral } from '@/store/modules/general';
import { useUser } from '@/store/modules/user';
import { useInitializeServer } from '@/store/modules/initializeServer';

export default (
  Vue as VueConstructor<
    Vue & {
      $refs: {
        memberCodeVP: InstanceType<typeof ValidationProvider>;
      };
    }
  >
).extend({
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    saveButtonText: {
      type: String,
      default: 'action.continue',
    },
    showPreviousButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isMemberCodeValid: true,
    };
  },
  computed: {
    ...mapState(useGeneral, ['memberClassesCurrentInstance', 'memberName']),

    ...mapState(useUser, [
      'currentSecurityServer',
      'isServerCodeInitialized',
      'isServerOwnerInitialized',
    ]),
    ...mapState(useInitializeServer, [
      'initServerMemberClass',
      'initServerMemberCode',
      'initServerSSCode',
    ]),

    memberClass: {
      get(): string | undefined {
        if (this.currentSecurityServer?.member_class) {
          return this.currentSecurityServer.member_class;
        }
        return this.initServerMemberClass;
      },
      set(value: string) {
        this.storeInitServerMemberClass(value);
      },
    },
    memberCode: {
      get(): string | undefined {
        if (this.currentSecurityServer?.member_code) {
          return this.currentSecurityServer.member_code;
        }
        return this.initServerMemberCode;
      },
      set(value: string) {
        this.storeInitServerMemberCode(value);
      },
    },
    securityServerCode: {
      get(): string | undefined {
        if (this.currentSecurityServer?.server_code) {
          return this.currentSecurityServer.server_code;
        }
        return this.initServerSSCode;
      },
      set(value: string) {
        this.storeInitServerSSCode(value);
      },
    },
  },

  watch: {
    memberClassesCurrentInstance(val: string[]) {
      // Set first member class selected if there is only one
      if (val?.length === 1) {
        this.storeInitServerMemberClass(val[0]);
      }
    },
    memberClass(val) {
      if (val) {
        // Update member name when info changes
        this.checkMember();
      }
    },
    async memberCode(val) {
      // Needs to be done here, because the watcher runs before the setter
      this.isMemberCodeValid = (await this.$refs.memberCodeVP.validate()).valid;
      if (val) {
        // Update member name when info changes
        this.checkMember();
      }
    },
  },
  beforeMount() {
    this.fetchMemberClassesForCurrentInstance().catch((error) => {
      if (error.response.status === 500) {
        // this can happen if anchor is not ready
        return;
      }
      this.showError(error);
    });

    this.checkMember();
  },
  mounted() {
    this.$refs.memberCodeVP;
  },
  methods: {
    ...mapActions(useNotifications, ['showError', 'showSuccess']),
    ...mapActions(useInitializeServer, [
      'storeInitServerSSCode',
      'storeInitServerMemberClass',
      'storeInitServerMemberCode',
    ]),

    ...mapActions(useGeneral, [
      'fetchMemberClassesForCurrentInstance',
      'fetchMemberName',
    ]),

    done(): void {
      this.$emit('done');
    },
    previous(): void {
      this.$emit('previous');
    },

    checkMember(): void {
      if (
        this.memberClass &&
        this.memberCode &&
        this.memberClass.length > 0 &&
        this.memberCode.length > 0 &&
        this.isMemberCodeValid
      ) {
        this.fetchMemberName(this.memberClass, this.memberCode).catch(
          (error) => {
            if (error.response.status === 404) {
              // no match found
              return;
            }
            this.showError(error);
          },
        );
      }
    },
  },
});
