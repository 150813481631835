


































import Vue from 'vue';
import ContextualAlerts from './ContextualAlerts.vue';
import GlobalAlerts from './GlobalAlerts.vue';

export default Vue.extend({
  components: {
    ContextualAlerts,
    GlobalAlerts,
  },
});
