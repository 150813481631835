





























































































import Vue from 'vue';
import { ServiceClient } from '@/openapi-types';
import * as api from '@/util/api';
import { Prop } from 'vue/types/options';
import { encodePathParameter } from '@/util/api';
import { mapActions } from 'pinia';
import { useNotifications } from '@/store/modules/notifications';

export default Vue.extend({
  props: {
    id: {
      type: String as Prop<string>,
      required: true,
    },
    serviceClients: {
      type: Array as Prop<ServiceClient[]>,
      required: true,
    },
  },
  data() {
    return {
      search: '' as string,
      serviceClientCandidates: [] as ServiceClient[],
      selection: undefined as undefined | ServiceClient,
    };
  },
  created(): void {
    this.fetchData();
  },
  methods: {
    ...mapActions(useNotifications, ['showError', 'showSuccess']),
    fetchData(): void {
      api
        .get<ServiceClient[]>(
          `/clients/${encodePathParameter(this.id)}/service-client-candidates`,
        )
        .then((response) => (this.serviceClientCandidates = response.data))
        .catch((error) => this.showError(error));
    },
    cancel(): void {
      this.$router.go(-1);
    },
    filteredCandidates(): ServiceClient[] {
      return this.serviceClientCandidates.filter(
        (candidate: ServiceClient): boolean => {
          const searchWordLowerCase = this.search.toLowerCase();

          // local group id is number. Convert it to string so it's easier to search it
          const id =
            candidate.service_client_type === 'LOCALGROUP'
              ? candidate.id.toString()
              : candidate.id.toLowerCase();
          return (
            candidate.name?.toLowerCase().includes(searchWordLowerCase) ||
            id.includes(searchWordLowerCase)
          );
        },
      );
    },
    isDisabled(scCandidate: ServiceClient): boolean {
      return this.serviceClients.some(
        (sc: ServiceClient): boolean => sc.id === scCandidate.id,
      );
    },
  },
});
