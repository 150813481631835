








































































































































































































import Vue, { PropType } from 'vue';
import * as api from '@/util/api';
import { mapActions, mapState } from 'pinia';
import { useNotifications } from '@/store/modules/notifications';
import { useGeneral } from '@/store/modules/general';
import { ServiceClient } from '@/openapi-types';

enum ServiceClientTypes {
  GLOBALGROUP = 'GLOBALGROUP',
  LOCALGROUP = 'LOCALGROUP',
  SUBSYSTEM = 'SUBSYSTEM',
}

const initialState = () => {
  return {
    name: '',
    serviceClientType: '',
    instance: '',
    memberClass: '',
    memberCode: '',
    subsystemCode: '',
    serviceClientTypes: ServiceClientTypes,
    expandPanel: [0],
    serviceClientCandidates: [] as ServiceClient[],
    selectedIds: [] as ServiceClient[],
    noResults: false,
    checkbox1: true,
    loading: false,
  };
};

export default Vue.extend({
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    clientId: {
      type: String,
      required: true,
    },
    existingServiceClients: {
      type: Array as PropType<ServiceClient[]>,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },

  data() {
    return { ...initialState() };
  },
  computed: {
    ...mapState(useGeneral, ['xroadInstances', 'memberClasses']),
    canSave(): boolean {
      return this.selectedIds.length > 0;
    },
    ServiceClientTypeItems(): Record<string, unknown>[] {
      // Returns items for subject type select with translated texts
      return [
        {
          text: this.$t('serviceClientType.globalGroup'),
          value: this.serviceClientTypes.GLOBALGROUP,
        },
        {
          text: this.$t('serviceClientType.localGroup'),
          value: this.serviceClientTypes.LOCALGROUP,
        },
        {
          text: this.$t('serviceClientType.subsystem'),
          value: this.serviceClientTypes.SUBSYSTEM,
        },
      ];
    },
  },
  created() {
    this.fetchXroadInstances();
    this.fetchMemberClasses();
  },
  methods: {
    ...mapActions(useNotifications, ['showError']),
    ...mapActions(useGeneral, ['fetchMemberClasses', 'fetchXroadInstances']),
    checkboxChange(subject: ServiceClient, event: boolean): void {
      if (event) {
        this.selectedIds.push(subject);
      } else {
        const index = this.selectedIds.indexOf(subject);
        if (index > -1) {
          this.selectedIds.splice(index, 1);
        }
      }
    },
    search(): void {
      this.noResults = false;
      let query = `/clients/${this.clientId}/service-client-candidates?member_name_group_description=${this.name}&member_group_code=${this.memberCode}&subsystem_code=${this.subsystemCode}`;

      // These checks are needed because instance, subject type and member class (dropdowns) return undefined if they are first selected and then cleared
      if (this.instance) {
        query = query + `&instance=${this.instance}`;
      }

      if (this.memberClass) {
        query = query + `&member_class=${this.memberClass}`;
      }

      if (this.serviceClientType) {
        query = query + `&service_client_type=${this.serviceClientType}`;
      }

      const isExistingServiceClient = (sc: ServiceClient): boolean => {
        return !this.existingServiceClients.some(
          (existing) =>
            sc.id === existing.id &&
            sc.service_client_type === existing.service_client_type,
        );
      };

      this.loading = true;
      this.serviceClientCandidates = [];
      this.selectedIds = [];

      api
        .get<ServiceClient[]>(query)
        .then((res) => {
          if (this.existingServiceClients?.length > 0) {
            // Filter out subjects that are already added
            this.serviceClientCandidates = res.data.filter(
              isExistingServiceClient,
            );
          } else {
            // Show results straight if there is nothing to filter
            this.serviceClientCandidates = res.data;
          }

          if (this.serviceClientCandidates.length < 1) {
            this.noResults = true;
          }
        })
        .catch((error) => {
          this.showError(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    cancel(): void {
      this.clearForm();
      this.$emit('cancel');
    },
    save(): void {
      this.$emit('service-clients-added', this.selectedIds);
      this.clearForm();
    },

    clearForm(): void {
      // Reset initial state
      Object.assign(this.$data, initialState());
    },
  },
});
