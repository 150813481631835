
















































import Vue from 'vue';
import * as api from '@/util/api';
import { mapActions } from 'pinia';
import { useNotifications } from '@/store/modules/notifications';

export default Vue.extend({
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    ...mapActions(useNotifications, ['showError', 'showSuccess']),
    save() {
      this.loading = true;
      api
        .post('/system/certificate', {})
        .then(() => {
          this.showSuccess(
            this.$t('ssTlsCertificate.generateTlsAndCertificateDialog.success'),
          );
          this.$emit('saved');
        })
        .catch((error) => {
          this.showError(error);
          this.$emit('cancel'); // still close the dialog
        })
        .finally(() => (this.loading = false));
    },
  },
});
