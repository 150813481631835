





















































































































































import Vue from 'vue';
import { mapActions, mapState } from 'pinia';
import { useNotifications } from '@/store/modules/notifications';
import { toClipboard } from '@/util/helpers';
import { Notification } from '@/ui-types';
import { Colors } from '@/global';

export default Vue.extend({
  // Component for contextual notifications
  computed: {
    ...mapState(useNotifications, ['errorNotifications']),
  },
  methods: {
    notificationColor(notification: Notification) {
      // TODO - how to import these values from colors.css?
      return notification.isWarning ? Colors.Warning : Colors.Error;
    },
    ...mapActions(useNotifications, ['deleteNotification']),

    closeError(id: number): void {
      this.deleteNotification(id);
    },
    copyId(notification: Notification): void {
      const id = notification.errorId;
      if (id) {
        toClipboard(id);
      }
    },
  },
});
