

























































































import Vue from 'vue';

import { Endpoint } from '@/openapi-types';
import * as api from '@/util/api';
import addEndpointDialog from './AddEndpointDialog.vue';
import { RouteName, Permissions } from '@/global';
import { encodePathParameter } from '@/util/api';
import { mapActions, mapState } from 'pinia';
import { useUser } from '@/store/modules/user';
import { useNotifications } from '@/store/modules/notifications';
import { useServicesStore } from '@/store/modules/services';

export default Vue.extend({
  components: {
    addEndpointDialog,
  },
  props: {
    serviceId: {
      type: String,
      required: true,
    },
    clientId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isAddEndpointDialogVisible: false,
    };
  },
  computed: {
    ...mapState(useUser, ['hasPermission']),
    ...mapState(useServicesStore, ['service']),

    endpoints(): Endpoint[] {
      // Check if the service has endpoints
      if (!this.service.endpoints) {
        return [];
      }

      return this.service.endpoints.filter((endpoint: Endpoint) => {
        return !this.isBaseEndpoint(endpoint);
      });
    },

    canAddEndpoint(): boolean {
      return this.hasPermission(Permissions.ADD_OPENAPI3_ENDPOINT);
    },

    canEditEndpoint(): boolean {
      return this.hasPermission(Permissions.EDIT_OPENAPI3_ENDPOINT);
    },

    canViewAccessRights(): boolean {
      return this.hasPermission(Permissions.VIEW_ENDPOINT_ACL);
    },
  },

  methods: {
    ...mapActions(useNotifications, ['showError', 'showSuccess']),
    addEndpoint(method: string, path: string): void {
      api
        .post(`/services/${encodePathParameter(this.service.id)}/endpoints`, {
          method,
          path,
          service_code: this.service.service_code,
        })
        .then(() => {
          this.showSuccess(this.$t('endpoints.saveNewEndpointSuccess'));
        })
        .catch((error) => {
          this.showError(error);
        })
        .finally(() => {
          this.isAddEndpointDialogVisible = false;
          this.$emit('update-service', this.service.id);
        });
    },
    isBaseEndpoint(endpoint: Endpoint): boolean {
      return endpoint.method === '*' && endpoint.path === '**';
    },
    editEndpoint(endpoint: Endpoint): void {
      if (!endpoint.id) {
        return;
      }
      this.$router.push({
        name: RouteName.EndpointDetails,
        params: {
          id: endpoint.id,
          clientId: this.clientId,
          serviceId: this.serviceId,
        },
      });
    },
    editAccessRights(endpoint: Endpoint): void {
      if (!endpoint.id) {
        return;
      }
      this.$router.push({
        name: RouteName.EndpointAccessRights,
        params: {
          id: endpoint.id,
          clientId: this.clientId,
          serviceId: this.serviceId,
        },
      });
    },
    cancelAddEndpoint(): void {
      this.isAddEndpointDialogVisible = false;
    },
  },
});
