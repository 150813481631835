var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"xrd-view-common"},[_c('div',{staticClass:"table-toolbar pb-3 pt-5"},[_c('div',{staticClass:"xrd-title-search"},[_c('div',{staticClass:"xrd-view-title"},[_vm._v(_vm._s(_vm.$t('tab.main.clients')))]),_c('xrd-search',{model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('div',[(_vm.showAddMember)?_c('xrd-button',{staticClass:"add-member",attrs:{"data-test":"add-member-button","outlined":""},on:{"click":_vm.addMember}},[_c('v-icon',{staticClass:"xrd-large-button-icon"},[_vm._v("icon-Add")]),_vm._v(" "+_vm._s(_vm.$t('action.addMember')))],1):_vm._e(),(_vm.showAddClient)?_c('xrd-button',{attrs:{"data-test":"add-client-button"},on:{"click":_vm.addClient}},[_c('v-icon',{staticClass:"xrd-large-button-icon"},[_vm._v("icon-Add")]),_vm._v(" "+_vm._s(_vm.$t('action.addClient')))],1):_vm._e()],1)]),_c('v-data-table',{staticClass:"elevation-0 data-table",attrs:{"loading":_vm.clientsLoading,"headers":_vm.headers,"items":_vm.getClients,"search":_vm.search,"must-sort":true,"items-per-page":-1,"sort-by":['visibleName'],"custom-sort":_vm.customSort,"custom-filter":_vm.customFilter,"hide-default-footer":"","item-key":"id","loader-height":2},scopedSlots:_vm._u([{key:"item.visibleName",fn:function(ref){
var item = ref.item;
return [(item.type === _vm.clientTypes.OWNER_MEMBER)?[_c('i',{on:{"click":function($event){return _vm.openClient(item)}}},[_c('v-icon',{staticClass:"icon-member icon-size"},[_vm._v("icon-Folder")])],1),(_vm.canOpenClient)?_c('span',{staticClass:"member-name identifier-wrap clickable",on:{"click":function($event){return _vm.openClient(item)}}},[_vm._v(_vm._s(item.visibleName)+" "),_c('span',{staticClass:"owner-box"},[_vm._v(_vm._s(_vm.$t('client.owner')))])]):_c('span',{staticClass:"member-name identifier-wrap owner-box"},[_vm._v(_vm._s(item.visibleName)+" "+_vm._s(_vm.$t('client.owner')))])]:(item.type === _vm.clientTypes.MEMBER)?[_c('i',{on:{"click":function($event){return _vm.openClient(item)}}},[_c('v-icon',{staticClass:"icon-member icon-size"},[_vm._v("icon-Folder-outline")])],1),(_vm.canOpenClient)?_c('span',{staticClass:"member-name identifier-wrap clickable",on:{"click":function($event){return _vm.openClient(item)}}},[_vm._v(_vm._s(item.visibleName))]):_c('span',{staticClass:"name identifier-wrap"},[_vm._v(_vm._s(item.visibleName))])]:(
          item.type === _vm.clientTypes.VIRTUAL_MEMBER ||
          item.type === _vm.clientTypes.MEMBER
        )?[_c('v-icon',{staticClass:"icon-virtual-member icon-size"},[_vm._v("icon-Folder-outline")]),_c('span',{staticClass:"identifier-wrap member-name"},[_vm._v(_vm._s(item.visibleName))])]:[(_vm.canOpenClient)?_c('span',{staticClass:"name identifier-wrap clickable",on:{"click":function($event){return _vm.openSubsystem(item)}}},[_vm._v(_vm._s(item.visibleName))]):_c('span',{staticClass:"name"},[_vm._v(_vm._s(item.visibleName))])]]}},{key:"item.id",fn:function(ref){
        var item = ref.item;
return [_c('span',{staticClass:"identifier-wrap"},[_vm._v(_vm._s(item.id))])]}},{key:"item.status",fn:function(ref){
        var item = ref.item;
return [_c('client-status',{attrs:{"status":item.status}})]}},{key:"item.button",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"button-wrap"},[(
            (item.type === _vm.clientTypes.OWNER_MEMBER ||
              item.type === _vm.clientTypes.MEMBER ||
              item.type === _vm.clientTypes.VIRTUAL_MEMBER) &&
            item.member_name &&
            _vm.showAddClient
          )?_c('xrd-button',{attrs:{"text":"","outlined":false},on:{"click":function($event){return _vm.addSubsystem(item)}}},[_c('v-icon',{staticClass:"xrd-large-button-icon"},[_vm._v("icon-Add")]),_vm._v(_vm._s(_vm.$t('action.addSubsystem')))],1):_vm._e(),(
            item.type !== _vm.clientTypes.OWNER_MEMBER &&
            item.type !== _vm.clientTypes.VIRTUAL_MEMBER &&
            item.status === 'SAVED' &&
            _vm.showRegister
          )?_c('xrd-button',{attrs:{"text":"","outlined":false},on:{"click":function($event){return _vm.registerClient(item)}}},[_vm._v(_vm._s(_vm.$t('action.register')))]):_vm._e()],1)]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"custom-footer"})]},proxy:true}],null,true)},[_c('template',{slot:"no-data"},[_vm._v(_vm._s(_vm.$t('action.noData')))]),_c('v-alert',{attrs:{"slot":"no-results","value":true,"color":"error"},slot:"no-results"},[_vm._v(_vm._s(_vm.$t('action.emptySearch', { msg: _vm.search })))])],2),_c('xrd-confirm-dialog',{attrs:{"dialog":_vm.confirmRegisterClient,"title":"clients.action.register.confirm.title","text":"clients.action.register.confirm.text","loading":_vm.registerClientLoading},on:{"cancel":function($event){_vm.confirmRegisterClient = false},"accept":function($event){return _vm.registerAccepted(_vm.selectedClient)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }