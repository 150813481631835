/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * subject type
 */
export enum ServiceClientType {
    GLOBALGROUP = 'GLOBALGROUP',
    LOCALGROUP = 'LOCALGROUP',
    SUBSYSTEM = 'SUBSYSTEM',
}