



















































import Vue from 'vue';
import { Token } from '@/openapi-types';
import { Prop } from 'vue/types/options';

export default Vue.extend({
  props: {
    token: {
      type: Object as Prop<Token>,
      required: true,
    },
  },
  methods: {
    confirmLogout(): void {
      this.$emit('token-logout');
    },
    confirmLogin(): void {
      this.$emit('token-login');
    },
  },
});
