

























































import Vue from 'vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';

export default Vue.extend({
  components: { ValidationProvider, ValidationObserver },
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      url: '',
    };
  },
  computed: {
    isValid(): boolean {
      if (this.url && this.url.length > 0) {
        return true;
      }

      return false;
    },
  },
  methods: {
    cancel(): void {
      this.$emit('cancel');

      this.clear();
    },
    save(): void {
      this.$emit('save', this.url);
      this.clear();
    },
    clear(): void {
      this.url = '';
      (this.$refs.form as InstanceType<typeof ValidationObserver>).reset();
    },
  },
});
