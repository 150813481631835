
































import Vue from 'vue';

export default Vue.extend({
  props: {
    status: {
      type: String,
      default: undefined,
    },
  },

  computed: {
    statusIconType(): string {
      if (!this.status) {
        return '';
      }
      switch (this.status.toLowerCase()) {
        case 'registered':
          return 'ok';
        case 'registration_in_progress':
          return 'progress-register';
        case 'saved':
          return 'saved';
        case 'deletion_in_progress':
          return 'progress-delete';
        case 'global_error':
          return 'error';
        default:
          return 'error';
      }
    },
  },

  methods: {
    getStatusText(status: string): string {
      if (!status) {
        return '';
      }
      switch (status.toLowerCase()) {
        case 'registered':
          return this.$t('client.statusText.registered') as string;
        case 'registration_in_progress':
          return this.$t('client.statusText.registrationInProgress') as string;
        case 'saved':
          return this.$t('client.statusText.saved') as string;
        case 'deletion_in_progress':
          return this.$t('client.statusText.deletionInProgress') as string;
        case 'global_error':
          return this.$t('client.statusText.globalError') as string;
        default:
          return '';
      }
    },
  },
});
