























































































import Vue from 'vue';

import WarningDialog from '@/components/ui/WarningDialog.vue';
import { AddMemberWizardModes } from '@/global';
import { createClientId } from '@/util/helpers';
import * as api from '@/util/api';
import { encodePathParameter } from '@/util/api';
import { mapActions, mapState } from 'pinia';
import { useAddClient } from '@/store/modules/addClient';
import { useNotifications } from '@/store/modules/notifications';
import { useCsrStore } from '@/store/modules/certificateSignRequest';

export default Vue.extend({
  components: {
    WarningDialog,
  },
  data() {
    return {
      disableCancel: false as boolean,
      registerChecked: true as boolean,
      submitLoading: false as boolean,
      warningInfo: [] as string[],
      warningDialog: false as boolean,
    };
  },
  computed: {
    ...mapState(useAddClient, [
      'addMemberWizardMode',
      'memberClass',
      'memberCode',
      'reservedMember',
    ]),

    ...mapState(useCsrStore, ['csrTokenId']),

    showRegisterOption() {
      if (
        this.addMemberWizardMode === AddMemberWizardModes.CERTIFICATE_EXISTS
      ) {
        return true;
      }
      return false;
    },
  },

  methods: {
    ...mapActions(useNotifications, ['showError', 'showSuccess']),
    ...mapActions(useAddClient, ['createMember']),
    ...mapActions(useCsrStore, ['requestGenerateCsr', 'generateKeyAndCsr']),
    cancel(): void {
      this.$emit('cancel');
    },
    previous(): void {
      this.$emit('previous');
    },
    done(): void {
      this.cmpCreateMember(false);
    },
    cmpCreateMember(ignoreWarnings: boolean): void {
      this.disableCancel = true;
      this.submitLoading = true;

      this.createMember(ignoreWarnings).then(
        () => {
          if (
            this.addMemberWizardMode ===
              AddMemberWizardModes.CERTIFICATE_EXISTS &&
            this.registerChecked
          ) {
            this.registerClient();
          } else if (
            this.addMemberWizardMode === AddMemberWizardModes.CERTIFICATE_EXISTS
          ) {
            this.disableCancel = false;
            this.submitLoading = false;
            this.$emit('done');
          } else if (
            this.addMemberWizardMode === AddMemberWizardModes.CSR_EXISTS
          ) {
            this.generateCsr();
          } else {
            this.cmpGenerateKeyAndCsr();
          }
        },
        (error) => {
          if (error?.response?.data?.warnings) {
            this.warningInfo = error.response.data.warnings;
            this.warningDialog = true;
          } else {
            this.showError(error);
            this.disableCancel = false;
            this.submitLoading = false;
          }
        },
      );
    },

    cancelSubmit(): void {
      this.disableCancel = false;
      this.submitLoading = false;
      this.warningDialog = false;
    },
    acceptWarnings(): void {
      this.cmpCreateMember(true);
    },

    cmpGenerateKeyAndCsr(): void {
      if (!this.csrTokenId) {
        // Should not happen
        throw new Error('Token id does not exist');
      }

      this.generateKeyAndCsr(this.csrTokenId)
        .then(
          () => {
            this.$emit('done');
          },
          (error) => {
            this.showError(error);
          },
        )
        .finally(() => {
          this.disableCancel = false;
          this.submitLoading = false;
        });
    },

    generateCsr(): void {
      this.requestGenerateCsr()
        .then(
          () => {
            this.$emit('done');
          },
          (error) => {
            this.showError(error);
          },
        )
        .finally(() => {
          this.disableCancel = false;
          this.submitLoading = false;
        });
    },

    registerClient(): void {
      if (!this.reservedMember) {
        // Should not happen
        throw new Error('Reserved member does not exist');
      }

      const clientId = createClientId(
        this.reservedMember.instanceId,
        this.memberClass,
        this.memberCode,
      );

      api
        .put(`/clients/${encodePathParameter(clientId)}/register`, {})
        .then(
          () => {
            this.$emit('done');
          },
          (error) => {
            this.showError(error);
            this.$emit('done');
          },
        )
        .finally(() => {
          this.disableCancel = false;
          this.submitLoading = false;
        });
    },
  },
});
