

































































































































import Vue from 'vue';

import SelectClientDialog from '@/components/client/SelectClientDialog.vue';
import { Client } from '@/openapi-types';
import { debounce, isEmpty } from '@/util/helpers';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { AddMemberWizardModes } from '@/global';
import { validate } from 'vee-validate';
import { mapActions, mapState, mapWritableState } from 'pinia';
import { useNotifications } from '@/store/modules/notifications';
import { useAddClient } from '@/store/modules/addClient';
import { useGeneral } from '@/store/modules/general';
import { useUser } from '@/store/modules/user';

// To provide the Vue instance to debounce
// eslint-disable-next-line @typescript-eslint/no-explicit-any
let that: any;

export default Vue.extend({
  components: {
    ValidationObserver,
    ValidationProvider,
    SelectClientDialog,
  },
  data() {
    return {
      showSelectClient: false as boolean,
      checkRunning: false as boolean,
      isMemberCodeValid: true,
    };
  },
  computed: {
    ...mapState(useAddClient, [
      'reservedMember',
      'selectedMemberName',
      'selectableMembers',
    ]),
    ...mapState(useGeneral, ['memberClassesCurrentInstance']),
    ...mapState(useUser, ['currentSecurityServer']),
    ...mapWritableState(useAddClient, ['memberClass', 'memberCode']),

    filteredSelectableMembers(): Client[] {
      // Filter out the owner member
      const filtered = this.selectableMembers.filter((client: Client) => {
        return !(
          client.member_class === this.reservedMember?.memberClass &&
          client.member_code === this.reservedMember.memberCode
        );
      });
      return filtered;
    },

    duplicateClient(): boolean {
      if (!this.memberClass || !this.memberCode) {
        return false;
      }

      // Check that the info doesn't match the reserved member (owner member)
      return !(
        this.reservedMember?.memberClass.toLowerCase() !==
          this.memberClass.toLowerCase() ||
        this.reservedMember.memberCode.toLowerCase() !==
          this.memberCode.toLowerCase()
      );
    },
  },

  watch: {
    async memberCode(val) {
      // Set wizard mode to default (full)
      this.setAddMemberWizardMode(AddMemberWizardModes.FULL);

      // Needs to be done here, because the watcher runs before the setter
      validate(this.memberCode, 'required|xrdIdentifier', {
        // name is not used, but if it's undefined there is a warning in browser console
        name: 'addClient.memberCode',
      }).then((result) => {
        if (result.valid) {
          this.isMemberCodeValid = true;
          if (isEmpty(val) || isEmpty(this.memberClass)) {
            return;
          }
          this.checkClient();
        } else {
          this.isMemberCodeValid = false;
        }
      });
    },
    memberClass(val): void {
      // Set wizard mode to default (full)
      this.setAddMemberWizardMode(AddMemberWizardModes.FULL);
      if (isEmpty(val) || isEmpty(this.memberCode)) {
        return;
      }
      this.checkClient();
    },

    memberClassesCurrentInstance(val): void {
      // Set first member class selected as default when the list is updated
      if (val?.length === 1) {
        this.memberClass = val[0];
      }
    },
  },

  mounted() {
    this.$refs.memberCodeVP;
  },
  created() {
    that = this;
    this.setAddMemberWizardMode(AddMemberWizardModes.FULL);
    this.fetchSelectableMembers(that.currentSecurityServer.instance_id);
  },
  methods: {
    ...mapActions(useNotifications, ['showError']),

    ...mapActions(useAddClient, [
      'setSelectedMember',
      'fetchSelectableMembers',
      'setAddMemberWizardMode',
      'searchTokens',
      'setSelectedMemberName',
    ]),

    cancel(): void {
      this.$emit('cancel');
    },
    done(): void {
      this.$emit('done');
    },
    saveSelectedClient(selectedMember: Client): void {
      this.setSelectedMember(selectedMember);
      this.showSelectClient = false;
    },
    checkClient(): void {
      // check if the identifier is valid
      if (!this.isMemberCodeValid) {
        return;
      }
      this.checkRunning = true;

      // Find if the selectable clients array has a match
      const tempClient = this.filteredSelectableMembers.find(
        (client: Client) => {
          return (
            client.member_code === this.memberCode &&
            client.member_class === this.memberClass
          );
        },
      );

      // Fill the name "field" if it's available or set it undefined
      this.setSelectedMemberName(tempClient?.member_name);

      // Pass the arguments so that we use the validated information instead of the state at that time
      this.checkClientDebounce(this.memberClass, this.memberCode);
    },
    checkClientDebounce: debounce((memberClass: string, memberCode: string) => {
      // Debounce is used to reduce unnecessary api calls
      // Search tokens for suitable CSR:s and certificates
      that
        .searchTokens({
          instanceId: that.reservedMember.instanceId,
          memberClass: memberClass,
          memberCode: memberCode,
        })
        .then(
          () => {
            that.checkRunning = false;
          },
          (error: Error) => {
            that.showError(error);
            that.checkRunning = true;
          },
        );
    }, 600),
  },
});
