










































































import Vue from 'vue';
import MemberOrGroupSelectionStep from '@/views/Clients/ServiceClients/MemberOrGroupSelectionStep.vue';
import ServiceSelectionStep from '@/views/Clients/ServiceClients/ServiceSelectionStep.vue';
import {
  AccessRight,
  Service,
  ServiceClient,
  ServiceDescription,
} from '@/openapi-types';
import * as api from '@/util/api';
import { ServiceCandidate } from '@/ui-types';
import { compareByServiceCode } from '@/util/sorting';
import { encodePathParameter } from '@/util/api';
import { mapActions } from 'pinia';
import { useNotifications } from '@/store/modules/notifications';

export default Vue.extend({
  components: {
    MemberOrGroupSelectionStep,
    ServiceSelectionStep,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      step: 1 as number,
      serviceClientAccessRights: [] as AccessRight[],
      serviceCandidates: [] as ServiceCandidate[],
      serviceClients: [] as ServiceClient[],
      serviceClientCandidateSelection: undefined as undefined | ServiceClient,
    };
  },
  created(): void {
    this.fetchData();
  },
  methods: {
    ...mapActions(useNotifications, ['showError', 'showSuccess']),
    candidateSelection(candidate: ServiceClient): void {
      this.serviceClientCandidateSelection = candidate;
    },
    fetchData: function (): void {
      api
        .get<ServiceClient[]>(
          `/clients/${encodePathParameter(this.id)}/service-clients`,
          {},
        )
        .then((response) => (this.serviceClients = response.data))
        .catch((error) => this.showError(error));

      api
        .get<ServiceDescription[]>(
          `/clients/${encodePathParameter(this.id)}/service-descriptions`,
        )
        .then((response) => {
          const serviceDescriptions = response.data;

          // Parse all services for the current client and map them to ServiceCandidates (manually added type for
          // objects that are used to add and list services that can be granted access rights to).
          this.serviceCandidates = serviceDescriptions
            .flatMap((serviceDescription) => serviceDescription.services)
            .sort(compareByServiceCode)
            .map((service: Service) => ({
              service_code: service.service_code,
              service_title: service.title,
              id: service.id,
            }));
        })
        .catch((error) => this.showError(error));
    },
    previousStep(): void {
      this.step -= 1;
    },
    nextStep(): void {
      this.step += 1;
    },
  },
});
