

































































































































































































































































import Vue from 'vue';
import * as api from '@/util/api';
import AccessRightsDialog from '../AccessRightsDialog.vue';
import WarningDialog from '@/components/ui/WarningDialog.vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { RouteName, Permissions } from '@/global';
import { ServiceClient, ServiceClients, ServiceUpdate } from '@/openapi-types';
import { ServiceTypeEnum } from '@/domain';
import { encodePathParameter } from '@/util/api';
import { mapActions, mapState } from 'pinia';
import { useUser } from '@/store/modules/user';
import { useNotifications } from '@/store/modules/notifications';
import { useServicesStore } from '@/store/modules/services';

type NullableServiceClient = undefined | ServiceClient;

export default Vue.extend({
  components: {
    AccessRightsDialog,
    WarningDialog,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    serviceId: {
      type: String,
      required: true,
    },
    clientId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      touched: false as boolean,
      confirmGroup: false as boolean,
      confirmMember: false as boolean,
      confirmAllServiceClients: false as boolean,
      selectedMember: undefined as NullableServiceClient,
      url: '' as string,
      addServiceClientDialogVisible: false as boolean,
      timeout: 23 as number,
      url_all: false as boolean,
      timeout_all: false as boolean,
      ssl_auth_all: false as boolean,
      warningInfo: [] as string[],
      warningDialog: false as boolean,
      saving: false as boolean,
    };
  },
  computed: {
    ...mapState(useServicesStore, ['service', 'serviceClients']),
    ...mapState(useUser, ['hasPermission']),
    hasServiceClients(): boolean {
      return this.serviceClients?.length > 0;
    },
    disableSave(): boolean {
      // service is undefined --> can't save OR inputs are not touched
      return !this.service || !this.touched;
    },
    showApplyToAll(): boolean {
      return (
        this.$route.query.descriptionType === ServiceTypeEnum.WSDL &&
        this.hasPermission(Permissions.EDIT_SERVICE_PARAMS)
      );
    },
    canEdit(): boolean {
      return this.hasPermission(Permissions.EDIT_SERVICE_PARAMS);
    },
  },

  methods: {
    ...mapActions(useNotifications, ['showError', 'showSuccess']),
    ...mapActions(useServicesStore, ['setServiceClients']),
    cancelSubmit(): void {
      this.warningDialog = false;
    },
    acceptWarnings(): void {
      this.warningDialog = false;
      this.save(true);
    },
    save(ignoreWarnings: boolean): void {
      /**
       * For the current service backend returns ssl_auth as undefined if current service is using http.
       * If service is https then it can be either false or true. When saving service parameters however the ssl_auth
       * must be a boolean even if the service is using http. Backend will handle saving correct data.
       */
      const serviceUpdate: ServiceUpdate = {
        url: this.service.url,
        timeout: this.service.timeout,
        ssl_auth: this.service.ssl_auth ?? false, // set false as backup as backend takes boolean
        timeout_all: this.timeout_all,
        url_all: this.url_all,
        ssl_auth_all: this.ssl_auth_all,
        ignore_warnings: ignoreWarnings,
      };

      this.saving = true;
      api
        .patch(
          `/services/${encodePathParameter(this.serviceId)}`,
          serviceUpdate,
        )
        .then(() => {
          this.showSuccess(this.$t('services.serviceSaved'));
        })
        .catch((error) => {
          if (error?.response?.data?.warnings) {
            this.warningInfo = error.response.data.warnings;
            this.warningDialog = true;
          } else {
            this.showError(error);
          }
        })
        .finally(() => (this.saving = false));
    },

    setTouched(): void {
      this.touched = true;
    },

    fetchData(serviceId: string): void {
      api
        .get<ServiceClient[]>(
          `/services/${encodePathParameter(serviceId)}/service-clients`,
        )
        .then((res) => {
          this.setServiceClients(res.data);
        })
        .catch((error) => {
          this.showError(error);
        });
    },

    showAddServiceClientDialog(): void {
      this.addServiceClientDialogVisible = true;
    },

    doAddServiceClient(selected: ServiceClient[]): void {
      this.addServiceClientDialogVisible = false;

      api
        .post(
          `/services/${encodePathParameter(this.serviceId)}/service-clients`,
          {
            items: selected,
          } as ServiceClients,
        )
        .then(() => {
          this.showSuccess(this.$t('accessRights.addServiceClientsSuccess'));
          this.fetchData(this.serviceId);
        })
        .catch((error) => {
          this.showError(error);
        });
    },

    closeAccessRightsDialog(): void {
      this.addServiceClientDialogVisible = false;
    },

    removeAllServiceClients(): void {
      this.confirmAllServiceClients = true;
    },

    doRemoveAllServiveClient(): void {
      const items = this.serviceClients.map((sc: ServiceClient) => ({
        id: sc.id,
        service_client_type: sc.service_client_type,
      }));

      this.removeServiceClients(items);
      this.confirmAllServiceClients = false;
    },
    removeServiceClient(member: NullableServiceClient): void {
      this.confirmMember = true;
      this.selectedMember = member;
    },
    doRemoveServiceClient() {
      const serviceClient: ServiceClient = this.selectedMember as ServiceClient;

      if (serviceClient.id) {
        this.removeServiceClients([serviceClient]);
      }

      this.confirmMember = false;
      this.selectedMember = undefined;
    },

    removeServiceClients(serviceClients: ServiceClient[]) {
      api
        .post(
          `/services/${encodePathParameter(
            this.serviceId,
          )}/service-clients/delete`,
          {
            items: serviceClients,
          },
        )
        .then(() => {
          this.showSuccess(this.$t('accessRights.removeSuccess'));
        })
        .catch((error) => {
          this.showError(error);
        })
        .finally(() => {
          this.$emit('update-service', this.service.id);
        });
    },
    close() {
      this.$router.push({
        name: RouteName.SubsystemServices,
        params: { id: this.clientId },
      });
    },
    isHttpsMethod(): boolean {
      return this.service?.url?.startsWith('https');
    },
    changeUrl(): void {
      this.setTouched();
      if (!this.isHttpsMethod() && this.service.ssl_auth === true) {
        this.service.ssl_auth = false;
      }
    },
  },
});
