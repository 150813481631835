




































/**
 * Component for sorting icon (up and down)
 */
import Vue from 'vue';
import { Colors } from '@/global';

export default Vue.extend({
  props: {
    arrowState: {
      type: Boolean,
      required: true,
    },
    active: {
      type: Boolean,
      required: true,
    },
    selected: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    iconColor(): string {
      // Determine the icon color
      if (this.selected) {
        return Colors.Black100;
      }
      if (this.active) {
        return Colors.Black30;
      }
      return 'transparent'; // Hidden
    },
  },
});
