














































// Dialog to confirm service description disabling
import Vue from 'vue';

export default Vue.extend({
  props: {
    subject: {
      type: Object,
      required: true,
    },
    subjectIndex: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      notice: '',
    };
  },

  methods: {
    cancel(): void {
      this.$emit('cancel', this.subject, this.subjectIndex);
      this.clear();
    },
    save(): void {
      this.$emit('save', this.subject, this.subjectIndex, this.notice);
      this.clear();
    },
    clear(): void {
      this.notice = '';
    },
  },
});
