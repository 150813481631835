


























































































































































































/***
 * Component for showing the details of REST or WSDL service description.
 * Both use the same api.
 */
import Vue from 'vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { Permissions } from '@/global';
import * as api from '@/util/api';
import ServiceWarningDialog from '@/components/service/ServiceWarningDialog.vue';
import {
  ServiceDescription,
  ServiceDescriptionUpdate,
  ServiceType,
} from '@/openapi-types';
import { encodePathParameter } from '@/util/api';
import { mapActions, mapState } from 'pinia';
import { useNotifications } from '@/store/modules/notifications';
import { useUser } from '@/store/modules/user';

export default Vue.extend({
  components: {
    ServiceWarningDialog,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      confirmWSDLDelete: false,
      confirmRESTDelete: false,
      confirmEditWarning: false,
      warningInfo: [],
      touched: false,
      serviceDesc: {} as ServiceDescription,
      currentServiceCode: undefined as string | undefined,
      initialServiceCode: '',
      saveBusy: false,
      serviceType: ServiceType,
      editLoading: false as boolean,
      serviceDescriptionUpdate: null as ServiceDescriptionUpdate | null,
    };
  },
  computed: {
    ...mapState(useUser, ['hasPermission']),
    showDelete(): boolean {
      return this.hasPermission(Permissions.DELETE_WSDL);
    },
  },
  watch: {
    serviceDesc(desc: ServiceDescription) {
      if (desc.services?.[0]?.service_code) {
        this.currentServiceCode = desc.services[0].service_code;
      }
    },
  },
  created() {
    this.fetchData(this.id);
  },
  methods: {
    ...mapActions(useNotifications, ['showError', 'showSuccess']),
    close(): void {
      this.$router.go(-1);
    },

    save(): void {
      this.saveBusy = true;

      this.serviceDescriptionUpdate = {
        url: this.serviceDesc.url,
        type: this.serviceDesc.type,
        ignore_warnings: false,
      };

      if (
        this.serviceDescriptionUpdate.type === this.serviceType.REST ||
        this.serviceDescriptionUpdate.type === this.serviceType.OPENAPI3
      ) {
        this.serviceDescriptionUpdate.rest_service_code =
          this.initialServiceCode;
        this.serviceDescriptionUpdate.new_rest_service_code =
          this.serviceDescriptionUpdate.rest_service_code !==
          this.currentServiceCode
            ? this.currentServiceCode
            : this.serviceDescriptionUpdate.rest_service_code;
      }

      api
        .patch(
          `/service-descriptions/${this.id}`,
          this.serviceDescriptionUpdate,
        )
        .then(() => {
          this.showSuccess(this.$t('localGroup.descSaved'));
          this.saveBusy = false;
          this.serviceDescriptionUpdate = null;
          this.$router.go(-1);
        })
        .catch((error) => {
          if (error.response.data.warnings) {
            this.warningInfo = error.response.data.warnings;
            this.confirmEditWarning = true;
          } else {
            this.showError(error);
            this.saveBusy = false;
            this.serviceDescriptionUpdate = null;
          }
        });
    },

    fetchData(id: string): void {
      api
        .get<ServiceDescription>(
          `/service-descriptions/${encodePathParameter(id)}`,
        )
        .then((res) => {
          this.serviceDesc = res.data;
          this.initialServiceCode =
            this.serviceDesc.services &&
            this.serviceDesc.services[0] &&
            this.serviceDesc.services[0].service_code;
        })
        .catch((error) => {
          this.showError(error);
        });
    },

    showDeletePopup(serviceType: string): void {
      if (serviceType === this.serviceType.WSDL) {
        this.confirmWSDLDelete = true;
      } else {
        this.confirmRESTDelete = true;
      }
    },
    doDeleteServiceDesc(): void {
      api
        .remove(`/service-descriptions/${encodePathParameter(this.id)}`)
        .then(() => {
          this.showSuccess(this.$t('services.deleted'));
          this.confirmWSDLDelete = false;
          this.confirmRESTDelete = false;
          this.$router.go(-1);
        })
        .catch((error) => {
          this.showError(error);
        });
    },

    acceptEditWarning(): void {
      this.editLoading = true;

      if (this.serviceDescriptionUpdate) {
        this.serviceDescriptionUpdate.ignore_warnings = true;
      }

      api
        .patch(
          `/service-descriptions/${this.id}`,
          this.serviceDescriptionUpdate,
        )
        .then(() => {
          this.showSuccess(this.$t('localGroup.descSaved'));
          this.$router.go(-1);
        })
        .catch((error) => {
          this.showError(error);
        })
        .finally(() => {
          this.saveBusy = false;
          this.editLoading = false;
          this.confirmEditWarning = false;
          this.serviceDescriptionUpdate = null;
        });
    },

    cancelEditWarning(): void {
      this.confirmEditWarning = false;
      this.saveBusy = false;
      this.editLoading = false;
    },
  },
});
