































































































































































































/**
 * This component renders the Clients data table.
 * Default sort and filter functions are replaced to achieve the end result where
 */
import Vue from 'vue';
import ClientStatus from './ClientStatus.vue';

import { Permissions, RouteName, ClientTypes } from '@/global';
import { createClientId } from '@/util/helpers';
import { ExtendedClient } from '@/ui-types';
import { DataTableHeader } from 'vuetify';
import * as api from '@/util/api';
import { encodePathParameter } from '@/util/api';
import { mapActions, mapState } from 'pinia';
import { useNotifications } from '@/store/modules/notifications';
import { useUser } from '@/store/modules/user';
import { useClientsStore } from '@/store/modules/clients';

export default Vue.extend({
  components: {
    ClientStatus,
  },

  data: () => ({
    search: '' as string,
    clientTypes: ClientTypes,
    pagination: {
      sortBy: 'visibleName' as string,
    },
    confirmRegisterClient: false as boolean,
    registerClientLoading: false as boolean,
    selectedClient: undefined as undefined | ExtendedClient,
  }),

  computed: {
    ...mapState(useClientsStore, [
      'getClients',
      'clientsLoading',
      'ownerMember',
      'realMembers',
    ]),
    ...mapState(useUser, ['hasPermission']),
    headers(): DataTableHeader[] {
      return [
        {
          text: this.$t('client.name') as string,
          align: 'start',
          value: 'visibleName',
          class: 'xrd-table-header xrd-table-header-name',
        },
        {
          text: this.$t('client.id') as string,
          align: 'start',
          value: 'id',
          class: 'xrd-table-header xrd-table-header-id',
        },
        {
          text: this.$t('client.status') as string,
          align: 'start',
          value: 'status',
          class: 'xrd-table-header xrd-table-header-status',
        },
        {
          text: '',
          value: 'button',
          sortable: false,
          class: 'xrd-table-header xrd-table-header-button',
        },
      ];
    },
    showAddClient(): boolean {
      return this.hasPermission(Permissions.ADD_CLIENT);
    },
    showAddMember(): boolean {
      return (
        this.hasPermission(Permissions.ADD_CLIENT) &&
        this.realMembers?.length < 2
      );
    },
    showRegister(): boolean {
      return this.hasPermission(Permissions.SEND_CLIENT_REG_REQ);
    },
    canOpenClient(): boolean {
      return this.hasPermission(Permissions.VIEW_CLIENT_DETAILS);
    },
  },
  created() {
    this.fetchData();
  },

  methods: {
    ...mapActions(useNotifications, ['showError', 'showSuccess']),
    ...mapActions(useClientsStore, ['fetchClients']),

    openClient(item: ExtendedClient): void {
      if (!item.id) {
        // Should not happen
        throw new Error('Invalid client');
      }
      this.$router.push({
        name: RouteName.Client,
        params: { id: item.id },
      });
    },

    openSubsystem(item: ExtendedClient): void {
      if (!item.id) {
        // Should not happen
        throw new Error('Invalid client');
      }
      this.$router.push({
        name: RouteName.Subsystem,
        params: { id: item.id },
      });
    },

    addClient(): void {
      this.$router.push({
        name: RouteName.AddClient,
      });
    },

    addMember(): void {
      if (!this.ownerMember?.instance_id) {
        // Should not happen
        throw new Error('Invalid owner member');
      }

      this.$router.push({
        name: RouteName.AddMember,
        params: {
          instanceId: this.ownerMember.instance_id,
          memberClass: this.ownerMember.member_class,
          memberCode: this.ownerMember.member_code,
        },
      });
    },

    addSubsystem(item: ExtendedClient): void {
      if (!item.instance_id || !item.member_name) {
        // Should not happen
        throw new Error('Invalid client');
      }

      this.$router.push({
        name: RouteName.AddSubsystem,
        params: {
          instanceId: item.instance_id,
          memberClass: item.member_class,
          memberCode: item.member_code,
          memberName: item.member_name,
        },
      });
    },

    registerClient(item: ExtendedClient): void {
      this.selectedClient = item;
      this.confirmRegisterClient = true;
    },

    registerAccepted(item: ExtendedClient) {
      this.registerClientLoading = true;

      // This should not happen, but better to throw error than create an invalid client id
      if (!item.instance_id) {
        throw new Error('Missing instance id');
      }

      const clientId = createClientId(
        item.instance_id,
        item.member_class,
        item.member_code,
        item.subsystem_code,
      );

      api
        .put(`/clients/${encodePathParameter(clientId)}/register`, {})
        .then(
          () => {
            this.showSuccess(this.$t('clients.action.register.success'));
          },
          (error) => {
            this.showError(error);
          },
        )
        .finally(() => {
          this.fetchData();
          this.confirmRegisterClient = false;
          this.registerClientLoading = false;
        });
    },

    customFilter: (
      value: unknown,
      search: string | null,
      item: ExtendedClient,
    ): boolean => {
      // Override for the default filter function.
      if (search === null || search.length === 0 || search?.trim() === '') {
        return true;
      }

      search = search.toString().toLowerCase();

      const isFiltered =
        item.visibleName.toLowerCase().includes(search) ||
        item.id.toLowerCase().includes(search) ||
        false;

      if (item.type !== ClientTypes.SUBSYSTEM) {
        item.isFiltered = !isFiltered;
        return true; //We will filter these in sorting due to structure requirements
      }

      return isFiltered;
    },

    customSort(
      items: ExtendedClient[],
      sortBy: string[],
      sortDesc: boolean[],
    ): ExtendedClient[] {
      const index = sortBy[0] as keyof ExtendedClient;
      const sortDirection = !sortDesc[0] ? 1 : -1;

      // Filter out all subsystems for later use
      const subsystems = items.filter(
        (client) => client.type === ClientTypes.SUBSYSTEM,
      );

      // First we order and filter the groups (filtering is based on the isFiltered attribute as well as if subsystems are visible)
      const groups = items
        .filter((client) => client.type !== ClientTypes.SUBSYSTEM)
        .filter(
          (client) =>
            !this.search ||
            !client.isFiltered ||
            subsystems.some((item) => item.id.startsWith(`${client.id}:`)),
        )
        .sort((clientA, clientB) => {
          if (clientA.owner || clientB.owner) {
            return clientA.owner ? -1 : 1;
          }

          const groupSortDirection =
            index !== 'visibleName' ? 1 : sortDirection;

          return (
            clientA.visibleName.localeCompare(clientB.visibleName) *
            groupSortDirection
          );
        });

      // Do local sorting inside the groups
      return groups
        .map<ExtendedClient[]>((group) => {
          return [
            group,
            ...subsystems
              .filter((client) => client.id.startsWith(`${group.id}:`))
              .sort((clientA, clientB) => {
                switch (index) {
                  case 'visibleName':
                    return (
                      clientA.visibleName.localeCompare(clientB.visibleName) *
                      sortDirection
                    );
                  case 'id':
                    return clientA.id.localeCompare(clientB.id) * sortDirection;
                  case 'status':
                    return (
                      (clientA.status || '').localeCompare(
                        clientB.status || '',
                      ) * sortDirection
                    );
                  default:
                    // Just don't sort if the sorting field is unknown
                    return 0;
                }
              }),
          ];
        })
        .reduce(
          (previousValue, currentValue) => [...previousValue, ...currentValue],
          [],
        );
    },

    fetchData() {
      this.fetchClients().catch((error) => {
        this.showError(error);
      });
    },
  },
});
