







































































































import Vue from 'vue';
import * as api from '@/util/api';
import { Permissions } from '@/global';
import { Prop } from 'vue/types/options';
import { TimestampingService } from '@/openapi-types';
import { mapActions } from 'pinia';
import { useNotifications } from '@/store/modules/notifications';

export default Vue.extend({
  name: 'AddTimestampingServiceDialog',
  props: {
    configuredTimestampingServices: {
      type: Array as Prop<TimestampingService[]>,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      show: false,
      approvedTimestampingServices: [] as TimestampingService[],
      selectedTimestampingServiceName: '',
      permissions: Permissions,
    };
  },
  computed: {
    selectableTimestampingServices(): TimestampingService[] {
      return [...this.approvedTimestampingServices].filter(
        (approvedService) =>
          !this.configuredTimestampingServices.some(
            (configuredService) =>
              approvedService.name === configuredService.name,
          ),
      );
    },
    selectedTimestampingService(): TimestampingService | undefined {
      return this.approvedTimestampingServices.find(
        (approvedService) =>
          approvedService.name === this.selectedTimestampingServiceName,
      );
    },
  },
  created(): void {
    this.fetchApprovedTimestampingServices();
  },
  methods: {
    ...mapActions(useNotifications, ['showError', 'showSuccess']),
    fetchApprovedTimestampingServices(): void {
      api
        .get<TimestampingService[]>('/timestamping-services')
        .then((resp) => (this.approvedTimestampingServices = resp.data))
        .catch((error) => this.showError(error));
    },
    add(): void {
      this.loading = true;
      api
        .post('/system/timestamping-services', this.selectedTimestampingService)
        .then(() => {
          this.$emit('added');
          this.loading = false;
          this.close();
          this.showSuccess(
            this.$t(
              'systemParameters.timestampingServices.action.add.dialog.success',
            ),
          );
        })
        .catch((error) => this.showError(error));
    },
    close(): void {
      this.show = false;
      this.selectedTimestampingServiceName = '';
    },
  },
});
