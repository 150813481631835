






















































import Vue from 'vue';

import { Permissions } from '@/global';
import CertificateInfo from '@/components/certificate/CertificateInfo.vue';
import CertificateHash from '@/components/certificate/CertificateHash.vue';
import * as api from '@/util/api';
import { CertificateDetails } from '@/openapi-types';
import { encodePathParameter } from '@/util/api';
import { mapActions, mapState } from 'pinia';
import { useUser } from '@/store/modules/user';
import { useNotifications } from '@/store/modules/notifications';
import { useClientStore } from '@/store/modules/client';

export default Vue.extend({
  components: {
    CertificateInfo,
    CertificateHash,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    hash: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      confirm: false,
      certificate: null as CertificateDetails | null,
    };
  },
  computed: {
    ...mapState(useUser, ['hasPermission']),
    ...mapState(useClientStore, ['tlsCertificates']),
    showDeleteButton(): boolean {
      return this.hasPermission(Permissions.DELETE_CLIENT_INTERNAL_CERT);
    },
  },
  created() {
    this.fetchData(this.id, this.hash);
  },
  methods: {
    ...mapActions(useNotifications, ['showError', 'showSuccess']),
    close(): void {
      this.$router.go(-1);
    },
    fetchData(clientId: string, hash: string): void {
      api
        .get<CertificateDetails>(
          `/clients/${clientId}/tls-certificates/${hash}`,
        )
        .then(
          (response) => {
            this.certificate = response.data;
          },
          (error) => {
            this.showError(error);
          },
        );
    },
    deleteCertificate(): void {
      this.confirm = true;
    },
    doDeleteCertificate(): void {
      this.confirm = false;

      api
        .remove(
          `/clients/${encodePathParameter(
            this.id,
          )}/tls-certificates/${encodePathParameter(this.hash)}`,
        )
        .then(
          () => {
            this.showSuccess(this.$t('cert.certDeleted'));
          },
          (error) => {
            this.showError(error);
          },
        )
        .finally(() => {
          this.close();
        });
    },
  },
});
