












































import Vue from 'vue';

export default Vue.extend({
  props: {
    helpImage: {
      type: String,
      required: true,
    },
    helpTitle: {
      type: String,
      required: true,
    },
    helpText: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    showHelp: false,
  }),

  methods: {
    helpClick(): void {
      this.showHelp = true;
    },
    closeHelp(): void {
      this.showHelp = false;
    },
  },
});
