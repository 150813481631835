











































































































import Vue from 'vue';

import TokenPinStep from './TokenPinStep.vue';
import ConfigurationAnchorStep from './ConfigurationAnchorStep.vue';
import WarningDialog from '@/components/ui/WarningDialog.vue';
import OwnerMemberStep from './OwnerMemberStep.vue';
import * as api from '@/util/api';
import { InitialServerConf } from '@/openapi-types';
import { mapActions, mapState } from 'pinia';
import { useAlerts } from '@/store/modules/alerts';
import { useUser } from '@/store/modules/user';
import { useNotifications } from '@/store/modules/notifications';
import { useInitializeServer } from '@/store/modules/initializeServer';

export default Vue.extend({
  components: {
    TokenPinStep,
    ConfigurationAnchorStep,
    OwnerMemberStep,
    WarningDialog,
  },
  data() {
    return {
      currentStep: 1 as number,
      pinSaveBusy: false as boolean,
      warningInfo: [] as string[],
      confirmInitWarning: false as boolean,
      requestPayload: {} as InitialServerConf,
    };
  },
  computed: {
    ...mapState(useUser, [
      'isAnchorImported',
      'isServerOwnerInitialized',
      'isServerCodeInitialized',
      'firstAllowedTab',
    ]),
    ...mapState(useInitializeServer, [
      'initServerSSCode',
      'initServerMemberClass',
      'initServerMemberCode',
    ]),
  },

  created() {
    this.fetchInitializationStatus().catch((error) => {
      this.showError(error);
    });
  },
  methods: {
    ...mapActions(useAlerts, ['checkAlertStatus']),
    ...mapActions(useNotifications, ['showError', 'showSuccess']),
    ...mapActions(useUser, [
      'setInitializationStatus',
      'fetchInitializationStatus',
      'fetchCurrentSecurityServer',
    ]),
    nextStep(): void {
      this.currentStep++;
    },

    tokenPinReady(pin: string): void {
      this.pinSaveBusy = true;

      this.requestPayload = {
        software_token_pin: pin,
        ignore_warnings: false,
      };

      // If owner member is not already set up add it
      if (!this.isServerOwnerInitialized) {
        this.requestPayload.owner_member_class = this.initServerMemberClass;
        this.requestPayload.owner_member_code = this.initServerMemberCode;
      }

      // Add security code if it's not already set up
      if (!this.isServerCodeInitialized) {
        this.requestPayload.security_server_code = this.initServerSSCode;
      }

      this.initServer(this.requestPayload);
    },

    acceptInitWarning(): void {
      this.requestPayload.ignore_warnings = true;
      this.confirmInitWarning = false;
      this.initServer(this.requestPayload);
    },

    initServer(payload: InitialServerConf): void {
      api
        .post('/initialization', payload)
        .then(() => {
          this.showSuccess(this.$t('initialConfiguration.success'));
          // Set init state to done so that the routing goes into "normal" mode
          this.setInitializationStatus();
          this.pinSaveBusy = false;
          this.fetchCurrentSecurityServer();
          this.checkAlertStatus(); // Check if we have any alerts after initialisation
          this.$router.replace({
            name: this.firstAllowedTab.to.name,
          });
        })
        .catch((error) => {
          if (error?.response?.data?.warnings) {
            this.warningInfo = error.response.data.warnings;
            this.confirmInitWarning = true;
          } else {
            this.showError(error);
            this.pinSaveBusy = false;
          }
        });
    },
  },
});
