























































































import Vue from 'vue';
import { DataTableHeader } from 'vuetify';

import * as api from '@/util/api';
import { ServiceClient } from '@/openapi-types';
import { encodePathParameter } from '@/util/api';
import { Permissions } from '@/global';
import { mapActions, mapState } from 'pinia';
import { useNotifications } from '@/store/modules/notifications';
import { useUser } from '@/store/modules/user';

import { useClientStore } from '@/store/modules/client';

export default Vue.extend({
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      serviceClients: [] as ServiceClient[],
      search: '',
      loading: false,
    };
  },
  computed: {
    ...mapState(useClientStore, ['client']),
    ...mapState(useUser, ['hasPermission']),
    showAddSubjects(): boolean {
      return this.hasPermission(Permissions.EDIT_ACL_SUBJECT_OPEN_SERVICES);
    },
    headers(): DataTableHeader[] {
      return [
        {
          text: this.$t('serviceClients.name') as string,
          align: 'start',
          value: 'name',
          class: 'xrd-table-header sc-table-name',
        },
        {
          text: this.$t('serviceClients.id') as string,
          align: 'start',
          value: 'id',
          class: 'xrd-table-header sc-table-id',
        },
      ];
    },
  },
  created() {
    this.fetchServiceClients();
  },
  methods: {
    ...mapActions(useNotifications, ['showError', 'showSuccess']),
    fetchServiceClients() {
      this.loading = true;
      api
        .get<ServiceClient[]>(
          `/clients/${encodePathParameter(this.id)}/service-clients`,
          {},
        )
        .then((response) => (this.serviceClients = response.data))
        .catch((error) => this.showError(error))
        .finally(() => (this.loading = false));
    },
    addServiceClient(): void {
      this.$router.push(`/subsystem/serviceclients/${this.id}/add`);
    },
    filteredServiceClients(): ServiceClient[] {
      return this.serviceClients.filter((sc: ServiceClient) => {
        const searchWordLowerCase = this.search.toLowerCase();
        return (
          sc.name?.toLowerCase().includes(searchWordLowerCase) ||
          sc.id.toLowerCase().includes(searchWordLowerCase)
        );
      });
    },
    showAccessRights(serviceClientId: string) {
      this.$router.push(
        `/subsystem/${this.id}/serviceclients/${serviceClientId}`,
      );
    },
  },
});
