




















































































































































import Vue from 'vue';
import MemberDetailsPage from './MemberDetailsPage.vue';
import TokenPage from '@/components/wizard/TokenPage.vue';
import SignKeyPage from '@/components/wizard/SignKeyPage.vue';
import FinishPage from './FinishPage.vue';
import CsrDetailsPageLocked from '@/components/wizard/CsrDetailsPageLocked.vue';
import GenerateCsrPage from '@/components/wizard/GenerateCsrPage.vue';
import { RouteName, AddMemberWizardModes } from '@/global';
import { mapActions, mapState } from 'pinia';
import { useAddClient } from '@/store/modules/addClient';
import { useNotifications } from '@/store/modules/notifications';
import { useCsrStore } from '@/store/modules/certificateSignRequest';
import { useGeneral } from '@/store/modules/general';

const NO_SELECTION = 999;

export default Vue.extend({
  components: {
    MemberDetailsPage,
    TokenPage,
    SignKeyPage,
    FinishPage,
    CsrDetailsPageLocked,
    GenerateCsrPage,
  },
  props: {
    instanceId: {
      type: String,
      required: true,
    },
    memberClass: {
      type: String,
      required: true,
    },
    memberCode: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      currentStep: 1 as number,
      wizardModes: AddMemberWizardModes,
    };
  },

  computed: {
    ...mapState(useAddClient, ['addMemberWizardMode', 'selectedMemberId']),

    tokenPageNumber(): number {
      if (
        this.addMemberWizardMode === AddMemberWizardModes.CERTIFICATE_EXISTS ||
        this.addMemberWizardMode === AddMemberWizardModes.CSR_EXISTS
      ) {
        return NO_SELECTION;
      }

      return 2;
    },

    keyPageNumber(): number {
      if (
        this.addMemberWizardMode === AddMemberWizardModes.CERTIFICATE_EXISTS ||
        this.addMemberWizardMode === AddMemberWizardModes.CSR_EXISTS
      ) {
        return NO_SELECTION;
      }

      return 3;
    },
    csrDetailsPageNumber(): number {
      if (
        this.addMemberWizardMode === AddMemberWizardModes.CERTIFICATE_EXISTS
      ) {
        return NO_SELECTION;
      }
      if (this.addMemberWizardMode === AddMemberWizardModes.CSR_EXISTS) {
        return 2;
      }

      return 4;
    },

    csrGeneratePageNumber(): number {
      if (
        this.addMemberWizardMode === AddMemberWizardModes.CERTIFICATE_EXISTS
      ) {
        return NO_SELECTION;
      }
      if (this.addMemberWizardMode === AddMemberWizardModes.CSR_EXISTS) {
        return 3;
      }

      return 5;
    },

    finishPageNumber(): number {
      if (
        this.addMemberWizardMode === AddMemberWizardModes.CERTIFICATE_EXISTS
      ) {
        return 2;
      }
      if (this.addMemberWizardMode === AddMemberWizardModes.CSR_EXISTS) {
        return 4;
      }
      return 6;
    },
  },
  created() {
    // Set up the CSR part with Sign mode
    this.setupSignKey();

    this.fetchCertificateAuthorities().catch((error) => {
      this.showError(error);
    });

    this.fetchMemberClassesForCurrentInstance();

    // Store the reserved member info to store
    this.storeReservedMember({
      instanceId: this.instanceId,
      memberClass: this.memberClass,
      memberCode: this.memberCode,
    });
  },
  beforeDestroy() {
    this.resetAddClientState();
    this.resetCsrState();
  },

  methods: {
    ...mapActions(useNotifications, ['showError', 'showSuccess']),
    ...mapActions(useCsrStore, [
      'storeCsrClient',
      'storeCsrIsNewMember',
      'resetCsrState',
      'fetchCsrForm',
      'fetchCertificateAuthorities',
      'setupSignKey',
    ]),
    ...mapActions(useAddClient, ['storeReservedMember', 'resetAddClientState']),
    ...mapActions(useGeneral, ['fetchMemberClassesForCurrentInstance']),

    cancel(): void {
      this.$router.replace({ name: RouteName.Clients });
    },

    csrDetailsReady(): void {
      // Add the selected client id in csr store
      const idString = this.selectedMemberId;
      this.storeCsrClient(idString);
      this.storeCsrIsNewMember(true);

      this.fetchCsrForm().then(
        () => {
          this.currentStep++;
        },
        (error) => {
          this.showError(error);
        },
      );
    },

    done(): void {
      this.$router.replace({ name: RouteName.Clients });
    },
  },
});
