





























































































import Vue from 'vue';
import * as api from '@/util/api';
import { saveResponseAsFile } from '@/util/helpers';
import { mapActions } from 'pinia';
import { useNotifications } from '@/store/modules/notifications';

export default Vue.extend({
  data() {
    return {
      distinguishedName: '',
      csrGenerated: false,
      generatingCsr: false,
    };
  },
  methods: {
    ...mapActions(useNotifications, ['showError']),
    back(): void {
      this.$router.go(-1);
    },
    generateCsr(): void {
      this.generatingCsr = true;
      api
        .post(
          '/system/certificate/csr',
          { name: this.distinguishedName },
          { responseType: 'blob' },
        )
        .then((res) => {
          saveResponseAsFile(res, 'request.csr');
          this.csrGenerated = true;
        })
        .catch((error) => {
          this.showError(error);
        })
        .finally(() => (this.generatingCsr = false));
    },
  },
});
