/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * diagnostics status class
 */
export enum DiagnosticStatusClass {
    OK = 'OK',
    WAITING = 'WAITING',
    FAIL = 'FAIL',
}