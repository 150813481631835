

































































































import Vue from 'vue';

import { RouteName } from '@/global';
import { KeyUsageType, TokenCertificate } from '@/openapi-types';
import { mapActions, mapState } from 'pinia';
import { useNotifications } from '@/store/modules/notifications';
import { useClientStore } from '@/store/modules/client';

export default Vue.extend({
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      certificatesLoading: false,
    };
  },
  computed: {
    ...mapState(useClientStore, [
      'client',
      'signCertificates',
      'clientLoading',
    ]),
  },
  created() {
    this.certificatesLoading = true;
    this.fetchSignCertificates(this.id)
      .catch((error) => {
        this.showError(error);
      })
      .finally(() => (this.certificatesLoading = false));
  },
  methods: {
    ...mapActions(useNotifications, ['showError', 'showSuccess']),
    ...mapActions(useClientStore, ['fetchSignCertificates']),
    viewCertificate(cert: TokenCertificate) {
      this.$router.push({
        name: RouteName.Certificate,
        params: {
          hash: cert.certificate_details.hash,
          usage: KeyUsageType.SIGNING,
        },
      });
    },
  },
});
