/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * service type
 */
export enum ServiceType {
    WSDL = 'WSDL',
    REST = 'REST',
    OPENAPI3 = 'OPENAPI3',
}