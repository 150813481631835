
































































































































import Vue from 'vue';
import { Permissions, RouteName } from '@/global';
import { CertificateDetails } from '@/openapi-types';
import * as api from '@/util/api';
import GenerateTlsAndCertificateDialog from '@/views/KeysAndCertificates/SecurityServerTlsCertificate/GenerateTlsAndCertificateDialog.vue';
import { saveResponseAsFile } from '@/util/helpers';
import { FileUploadResult } from '@niis/shared-ui';
import HelpButton from '../HelpButton.vue';
import { mapActions, mapState } from 'pinia';
import { useUser } from '@/store/modules/user';
import { useNotifications } from '@/store/modules/notifications';

export default Vue.extend({
  components: {
    GenerateTlsAndCertificateDialog,
    HelpButton,
  },
  data() {
    return {
      certificate: undefined as CertificateDetails | undefined,
      generateDialog: false,
      exportPending: false,
      loading: false,
    };
  },
  computed: {
    ...mapState(useUser, ['hasPermission']),
    generateKeyVisible(): boolean {
      return this.hasPermission(Permissions.GENERATE_INTERNAL_TLS_KEY_CERT);
    },
    importCertificateVisible(): boolean {
      return this.hasPermission(Permissions.IMPORT_INTERNAL_TLS_CERT);
    },
    exportCertificateVisible(): boolean {
      return this.hasPermission(Permissions.EXPORT_INTERNAL_TLS_CERT);
    },
    generateCsrVisible(): boolean {
      return this.hasPermission(Permissions.GENERATE_INTERNAL_TLS_CSR);
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    ...mapActions(useNotifications, ['showError', 'showSuccess']),
    certificateClick(): void {
      this.$router.push({
        name: RouteName.InternalTlsCertificate,
      });
    },
    generateCsr(): void {
      this.$router.push({
        name: RouteName.GenerateInternalCSR,
      });
    },
    fetchData(): void {
      this.loading = true;
      api
        .get<CertificateDetails>('/system/certificate')
        .then((res) => {
          this.certificate = res.data;
        })
        .catch((error) => {
          this.showError(error);
        })
        .finally(() => (this.loading = false));
    },
    newCertificateGenerated(): void {
      this.fetchData();
      this.generateDialog = false;
    },
    exportCertificate(): void {
      this.exportPending = true;
      api
        .get('/system/certificate/export', { responseType: 'blob' })
        .then((res) => saveResponseAsFile(res, 'certs.tar.gz'))
        .catch((error) => this.showError(error))
        .finally(() => (this.exportPending = false));
    },
    onImportFileChanged(result: FileUploadResult): void {
      api
        .post('/system/certificate/import', result.buffer, {
          headers: {
            'Content-Type': 'application/octet-stream',
          },
        })
        .then(() => {
          this.showSuccess(this.$t('ssTlsCertificate.certificateImported'));
          this.fetchData();
        })
        .catch((error) => this.showError(error));
    },
  },
});
