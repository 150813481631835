

















































































































/**
 * Table component for an array of keys
 */
import Vue from 'vue';
import SortButton from './SortButton.vue';
import { Colors } from '@/global';
import { KeysSortColumn } from './keyColumnSorting';

export default Vue.extend({
  components: {
    SortButton,
  },
  props: {
    sortDirection: {
      type: Boolean,
      required: true,
    },
    selectedSort: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      colors: Colors,
      titleSort: false,
      sortColumn: KeysSortColumn,
      hoverName: false,
      hoverId: false,
      hoverOcsp: false,
      hoverExp: false,
      hoverStatus: false,
    };
  },
  methods: {
    setSort(sort: KeysSortColumn): void {
      this.$emit('set-sort', sort);
    },
  },
});
