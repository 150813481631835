/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * intended usage for the key (signing or authentication)
 */
export enum KeyUsageType {
    AUTHENTICATION = 'AUTHENTICATION',
    SIGNING = 'SIGNING',
}