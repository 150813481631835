






















































































import Vue from 'vue';
import { DataTableHeader } from 'vuetify';
import * as api from '@/util/api';
import NewGroupDialog from './NewGroupDialog.vue';

import { Permissions, RouteName } from '@/global';
import { selectedFilter } from '@/util/helpers';
import { LocalGroup } from '@/openapi-types';
import { encodePathParameter } from '@/util/api';
import { mapActions, mapState } from 'pinia';
import { useNotifications } from '@/store/modules/notifications';
import { useUser } from '@/store/modules/user';
import { useClientStore } from '@/store/modules/client';

export default Vue.extend({
  components: {
    NewGroupDialog,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      search: '',
      dialog: false,
      groups: [] as LocalGroup[],
      addGroupDialog: false,
      loading: false,
    };
  },
  computed: {
    ...mapState(useUser, ['hasPermission']),
    ...mapState(useClientStore, ['client']),
    showAddGroup(): boolean {
      return this.hasPermission(Permissions.ADD_LOCAL_GROUP);
    },
    headers(): DataTableHeader[] {
      return [
        {
          text: this.$t('localGroups.code') as string,
          align: 'start',
          value: 'code',
          class: 'xrd-table-header lg-table-header-code',
        },
        {
          text: this.$t('localGroups.description') as string,
          align: 'start',
          value: 'description',
          class: 'xrd-table-header lg-table-header-description',
        },
        {
          text: this.$t('localGroups.memberCount') as string,
          align: 'start',
          value: 'member_count',
          class: 'xrd-table-header lg-table-header-member-count',
        },
        {
          text: this.$t('localGroups.updated') as string,
          align: 'start',
          value: 'updated_at',
          class: 'xrd-table-header lg-table-header-updated',
        },
      ];
    },
  },
  created() {
    this.fetchGroups(this.id);
  },
  methods: {
    ...mapActions(useNotifications, ['showError']),
    addGroup(): void {
      this.addGroupDialog = true;
    },

    closeDialog(): void {
      this.addGroupDialog = false;
    },

    groupAdded(): void {
      this.fetchGroups(this.id);
      this.addGroupDialog = false;
    },

    filtered(): LocalGroup[] {
      return selectedFilter(this.groups, this.search, 'id');
    },

    viewGroup(group: LocalGroup): void {
      if (!group.id) {
        return;
      }
      this.$router.push({
        name: RouteName.LocalGroup,
        params: { clientId: this.id, groupId: group.id.toString() },
      });
    },

    fetchGroups(id: string): void {
      this.loading = true;
      api
        .get<LocalGroup[]>(`/clients/${encodePathParameter(id)}/local-groups`)
        .then((res) => {
          this.groups = res.data.sort((a: LocalGroup, b: LocalGroup) => {
            if (a.code.toLowerCase() < b.code.toLowerCase()) {
              return -1;
            }
            if (a.code.toLowerCase() > b.code.toLowerCase()) {
              return 1;
            }

            return 0;
          });
        })
        .catch((error) => {
          this.showError(error);
        })
        .finally(() => (this.loading = false));
    },
  },
});
