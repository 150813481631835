


































































import Vue from 'vue';
import { mapWritableState } from 'pinia';
import { useCsrStore } from '@/store/modules/certificateSignRequest';

export default Vue.extend({
  props: {
    tokenType: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      disableDone: true,
    };
  },
  computed: {
    ...mapWritableState(useCsrStore, ['keyLabel']),
    keyLabelText(): string {
      if (this.$props.tokenType === 'HARDWARE') {
        return 'wizard.signKey.keyLabel';
      } else {
        return 'keys.keyLabelInput';
      }
    },
  },
  methods: {
    cancel(): void {
      this.$emit('cancel');
    },
    done(): void {
      this.$emit('done');
    },
  },
});
