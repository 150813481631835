














































import Vue from 'vue';
import * as api from '@/util/api';
import { encodePathParameter } from '@/util/api';
import { mapActions } from 'pinia';
import { useNotifications } from '@/store/modules/notifications';

export default Vue.extend({
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      confirmUnregisterClient: false as boolean,
      unregisterLoading: false as boolean,
    };
  },

  methods: {
    ...mapActions(useNotifications, ['showError', 'showSuccess']),
    unregisterClient(): void {
      this.unregisterLoading = true;
      api
        .put(`/clients/${encodePathParameter(this.id)}/unregister`, {})
        .then(
          () => {
            this.showSuccess(this.$t('client.action.unregister.success'));
          },
          (error) => {
            this.showError(error);
          },
        )
        .finally(() => {
          this.$emit('done', this.id);
          this.confirmUnregisterClient = false;
          this.unregisterLoading = false;
        });
    },
  },
});
