






























































































import Vue from 'vue';
import { mapState } from 'pinia';
import { useAlerts } from '@/store/modules/alerts';
import { useSystemStore } from '@/store/modules/system';
import { useUser } from '@/store/modules/user';
import { formatDateTime } from '@/filters';
import { Permissions, RouteName } from '@/global';

export default Vue.extend({
  computed: {
    ...mapState(useAlerts, [
      'showGlobalConfAlert',
      'showSoftTokenPinEnteredAlert',
      'showRestoreInProgress',
      'restoreStartTime',
    ]),
    ...mapState(useSystemStore, ['isSecondaryNode']),
    ...mapState(useUser, [
      'authenticated',
      'needsInitialization',
      'hasPermission',
    ]),
    hasAlerts(): boolean {
      return (
        this.showGlobalConfAlert ||
        this.showSoftTokenPinEnteredAlert ||
        this.showRestoreInProgress ||
        this.isSecondaryNode
      );
    },

    showLoginLink(): boolean {
      return this.$route.name !== RouteName.SignAndAuthKeys;
    },
    isAllowedToLoginToken(): boolean {
      return this.hasPermission(Permissions.ACTIVATE_DEACTIVATE_TOKEN);
    },
  },
  methods: {
    formatDateTime,
    tokenLogin(): void {
      this.$router.replace({ name: RouteName.SignAndAuthKeys });
    },
  },
});
