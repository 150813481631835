




























































































































































import Vue from 'vue';
import { Permissions } from '@/global';
import { GroupMember, LocalGroup } from '@/openapi-types';
import AddMembersDialog from './AddMembersDialog.vue';
import * as api from '@/util/api';
import { encodePathParameter } from '@/util/api';
import { mapActions, mapState } from 'pinia';
import { useUser } from '@/store/modules/user';
import { useNotifications } from '@/store/modules/notifications';

export default Vue.extend({
  components: {
    AddMembersDialog,
  },
  props: {
    clientId: {
      type: String,
      required: true,
    },
    groupId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      confirmGroup: false,
      confirmMember: false,
      confirmAllMembers: false,
      selectedMember: undefined as GroupMember | undefined,
      description: undefined as string | undefined,
      group: undefined as LocalGroup | undefined,
      groupCode: '',
      addMembersDialogVisible: false,
    };
  },
  computed: {
    ...mapState(useUser, ['hasPermission']),
    showDelete(): boolean {
      return this.hasPermission(Permissions.DELETE_LOCAL_GROUP);
    },
    canEditDescription(): boolean {
      return this.hasPermission(Permissions.EDIT_LOCAL_GROUP_DESC);
    },

    canEditMembers(): boolean {
      return this.hasPermission(Permissions.EDIT_LOCAL_GROUP_MEMBERS);
    },

    hasMembers(): boolean {
      if (this.group && this.group.members && this.group.members.length > 0) {
        return true;
      }
      return false;
    },
  },
  created() {
    this.fetchData(this.clientId, this.groupId);
  },
  methods: {
    ...mapActions(useNotifications, ['showError', 'showSuccess']),
    close(): void {
      this.$router.go(-1);
    },

    saveDescription(): void {
      api
        .patch<LocalGroup>(
          `/local-groups/${encodePathParameter(this.groupId)}`,
          {
            description: this.description,
          },
        )
        .then((res) => {
          this.showSuccess(this.$t('localGroup.descSaved'));
          this.group = res.data;
          this.groupCode = res.data.code;
          this.description = res.data.description;
        })
        .catch((error) => {
          this.showError(error);
        });
    },

    fetchData(clientId: string, groupId: number | string): void {
      api
        .get<LocalGroup>(`/local-groups/${encodePathParameter(groupId)}`)
        .then((res) => {
          this.group = res.data;
          this.groupCode = res.data.code;
          this.description = res.data.description;
        })
        .catch((error) => {
          this.showError(error);
        });
    },

    addMembers(): void {
      this.addMembersDialogVisible = true;
    },

    doAddMembers(selectedIds: string[]): void {
      this.addMembersDialogVisible = false;

      api
        .post(`/local-groups/${encodePathParameter(this.groupId)}/members`, {
          items: selectedIds,
        })
        .then(() => {
          this.fetchData(this.clientId, this.groupId);
        })
        .catch((error) => {
          this.showError(error);
        });
    },

    closeMembersDialog(): void {
      this.addMembersDialogVisible = false;
    },

    removeAllMembers(): void {
      this.confirmAllMembers = true;
    },

    doRemoveAllMembers(): void {
      if (!this.group?.members) {
        return;
      }
      const ids: string[] = [];

      this.group.members.forEach((member: GroupMember) => {
        ids.push(member.id);
      });
      this.removeArrayOfMembers(ids);

      this.confirmAllMembers = false;
    },

    removeMember(member: GroupMember): void {
      this.confirmMember = true;
      this.selectedMember = member as GroupMember;
    },
    doRemoveMember() {
      if (!this.selectedMember) {
        return;
      }
      const member: GroupMember = this.selectedMember;

      if (member && member.id) {
        this.removeArrayOfMembers([member.id]);
      }

      this.confirmMember = false;
      this.selectedMember = undefined;
    },

    removeArrayOfMembers(members: string[]) {
      api
        .post(
          `/local-groups/${encodePathParameter(this.groupId)}/members/delete`,
          {
            items: members,
          },
        )
        .catch((error) => {
          this.showError(error);
        })
        .finally(() => {
          this.fetchData(this.clientId, this.groupId);
        });
    },

    deleteGroup(): void {
      this.confirmGroup = true;
    },
    doDeleteGroup(): void {
      this.confirmGroup = false;

      api
        .remove(`/local-groups/${encodePathParameter(this.groupId)}`)
        .then(() => {
          this.showSuccess(this.$t('localGroup.groupDeleted'));
          this.$router.go(-1);
        })
        .catch((error) => {
          this.showError(error);
        });
    },
  },
});
