





















































































































































/**
 * Table component for an array of keys
 */
import Vue from 'vue';
import RegisterCertificateDialog from './RegisterCertificateDialog.vue';
import KeyRow from './KeyRow.vue';
import CertificateRow from './CertificateRow.vue';
import KeysTableThead from './KeysTableThead.vue';
import {
  Key,
  TokenCertificate,
  TokenCertificateSigningRequest,
  TokenType,
} from '@/openapi-types';
import { Permissions } from '@/global';
import { KeysSortColumn } from './keyColumnSorting';
import * as api from '@/util/api';
import { encodePathParameter } from '@/util/api';
import * as Sorting from './keyColumnSorting';
import { Prop } from 'vue/types/options';
import { mapActions, mapState } from 'pinia';
import { useUser } from '@/store/modules/user';
import { useNotifications } from '@/store/modules/notifications';

export default Vue.extend({
  components: {
    RegisterCertificateDialog,
    KeyRow,
    CertificateRow,
    KeysTableThead,
  },
  props: {
    keys: {
      type: Array as Prop<Key[]>,
      required: true,
    },
    tokenLoggedIn: {
      type: Boolean,
    },
    tokenType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      registerDialog: false,
      confirmDeleteCsr: false,
      selectedCert: undefined as TokenCertificate | undefined,
      selectedCsr: undefined as TokenCertificateSigningRequest | undefined,
      selectedKey: undefined as Key | undefined,
      sortDirection: false,
      selectedSort: KeysSortColumn.NAME,
      tokenTypes: TokenType,
    };
  },
  computed: {
    ...mapState(useUser, ['hasPermission']),
    sortedKeys(): Key[] {
      return Sorting.keyArraySort(
        this.keys,
        this.selectedSort,
        this.sortDirection,
      );
    },
    canImportFromToken(): boolean {
      // Can the user import certificate from hardware token
      return this.hasPermission(Permissions.IMPORT_SIGN_CERT);
    },

    showRegisterCertButton(): boolean {
      // Decide if the user can register a certificate
      return this.hasPermission(Permissions.SEND_AUTH_CERT_REG_REQ);
    },
  },

  methods: {
    ...mapActions(useNotifications, ['showError', 'showSuccess']),
    setSort(sort: KeysSortColumn): void {
      // Set sort column and direction
      if (sort === this.selectedSort) {
        this.sortDirection = !this.sortDirection;
      }

      this.selectedSort = sort;
    },
    canDeleteCsr(key: Key): boolean {
      // Decide if the user can delete CSR based on the key usage type and permissions
      if (key.usage === 'AUTHENTICATION') {
        return this.hasPermission(Permissions.DELETE_AUTH_CERT);
      }
      return this.hasPermission(Permissions.DELETE_SIGN_CERT);
    },
    keyClick(key: Key): void {
      this.$emit('key-click', key);
    },
    certificateClick(cert: TokenCertificate, key: Key): void {
      this.$emit('certificate-click', { cert, key });
    },
    generateCsr(key: Key): void {
      this.$emit('generate-csr', key);
    },
    importCert(hash: string): void {
      this.$emit('import-cert-by-hash', hash);
    },
    showRegisterCertDialog(cert: TokenCertificate): void {
      this.registerDialog = true;
      this.selectedCert = cert;
    },
    registerCert(address: string): void {
      this.registerDialog = false;
      if (!this.selectedCert) {
        return;
      }

      api
        .put(
          `/token-certificates/${this.selectedCert.certificate_details.hash}/register`,
          { address },
        )
        .then(() => {
          this.showSuccess(this.$t('keys.certificateRegistered'));
          this.$emit('refresh-list');
        })
        .catch((error) => {
          this.showError(error);
        });
    },
    showDeleteCsrDialog(req: TokenCertificateSigningRequest, key: Key): void {
      this.confirmDeleteCsr = true;
      this.selectedCsr = req;
      this.selectedKey = key;
    },
    deleteCsr(): void {
      this.confirmDeleteCsr = false;

      if (!this.selectedKey || !this.selectedCsr) {
        return;
      }

      api
        .remove(
          `/keys/${encodePathParameter(
            this.selectedKey.id,
          )}/csrs/${encodePathParameter(this.selectedCsr.id)}`,
        )
        .then(() => {
          this.showSuccess(this.$t('keys.csrDeleted'));
          this.$emit('refresh-list');
        })
        .catch((error) => {
          this.showError(error);
        });
    },
  },
});
