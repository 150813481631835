



















































/**
 * Table component for an array of keys
 */
import Vue from 'vue';
import { Prop } from 'vue/types/options';
import CertificateStatusIcon from './CertificateStatusIcon.vue';
import { CertificateStatus } from '@/openapi-types';
import { TokenCertificate } from '@/openapi-types';
import { Colors } from '@/global';

export default Vue.extend({
  components: {
    CertificateStatusIcon,
  },
  props: {
    cert: {
      type: Object as Prop<TokenCertificate>,
      required: true,
    },
  },
  computed: {
    certStatusColor(): string {
      return this.cert.status === CertificateStatus.GLOBAL_ERROR
        ? Colors.Error
        : '';
    },
  },

  methods: {
    certificateClick(): void {
      this.$emit('certificate-click');
    },
  },
});
