




























































































import Vue from 'vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { mapActions, mapState } from 'pinia';
import { useNotifications } from '@/store/modules/notifications';
import { useCsrStore } from '@/store/modules/certificateSignRequest';
import { AxiosError } from 'axios';

export default Vue.extend({
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    saveButtonText: {
      type: String,
      default: 'action.done',
    },
    // Creating Key + CSR or just CSR
    keyAndCsr: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      disableDone: true,
    };
  },
  computed: {
    ...mapState(useCsrStore, ['csrForm', 'csrTokenId']),
  },
  methods: {
    ...mapActions(useNotifications, ['showError', 'showSuccess']),
    ...mapActions(useCsrStore, ['requestGenerateCsr', 'generateKeyAndCsr']),
    cancel(): void {
      this.$emit('cancel');
    },
    previous(): void {
      this.$emit('previous');
    },
    done(): void {
      this.$emit('done');
    },
    async generateCsr(): Promise<void> {
      if (this.keyAndCsr) {
        // Create Key AND CSR

        if (!this.csrTokenId) {
          // Should not happen
          throw new Error('Token id does not exist');
        }
        const tokenId = this.csrTokenId;

        // Create key and CSR
        try {
          await this.generateKeyAndCsr(tokenId);
          this.disableDone = false;
        } catch (error) {
          this.disableDone = true;
          // Error comes from axios, so it most probably is AxiosError
          this.showError(error as AxiosError);
        }
      } else {
        // Create only CSR
        try {
          await this.requestGenerateCsr();
          this.disableDone = false;
        } catch (error) {
          this.disableDone = true;
          // Error comes from axios, so it most probably is AxiosError
          this.showError(error as AxiosError);
        }
      }
    },
  },
});
