/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * token type
 */
export enum TokenType {
    SOFTWARE = 'SOFTWARE',
    HARDWARE = 'HARDWARE',
}