














































































































import Vue from 'vue';
import { Prop } from 'vue/types/options';
import { ServiceCandidate } from '@/ui-types';
import { AccessRight, AccessRights, ServiceClient } from '@/openapi-types';
import * as api from '@/util/api';
import { encodePathParameter } from '@/util/api';
import { mapActions } from 'pinia';
import { useNotifications } from '@/store/modules/notifications';

export default Vue.extend({
  props: {
    id: {
      type: String as Prop<string>,
      required: true,
    },
    serviceCandidates: {
      type: Array as Prop<ServiceCandidate[]>,
      required: true,
    },
    serviceClientCandidateSelection: {
      type: Object as Prop<ServiceClient>,
      required: true,
    },
  },
  data() {
    return {
      selections: [] as ServiceCandidate[],
      search: '' as string,
    };
  },
  computed: {
    searchResults(): ServiceCandidate[] {
      const cleanedSearch = this.search.toLowerCase();
      return this.serviceCandidates.filter((candidate: ServiceCandidate) => {
        return (
          candidate.service_code.toLowerCase().includes(cleanedSearch) ||
          candidate.service_title?.toLowerCase().includes(cleanedSearch)
        );
      });
    },
  },
  methods: {
    ...mapActions(useNotifications, ['showError', 'showSuccess']),
    saveServices(): void {
      const items = this.selections
        .filter((selection) => selection.service_code.includes(this.search))
        .map(
          (selection): AccessRight => ({
            service_code: selection.service_code,
          }),
        ) as AccessRight[];

      const accessRightsObject: AccessRights = { items };

      api
        .post(
          `/clients/${encodePathParameter(
            this.id,
          )}/service-clients/${encodePathParameter(
            this.serviceClientCandidateSelection.id,
          )}/access-rights`,
          accessRightsObject,
        )
        .then(() => {
          this.showSuccess(
            this.$t('serviceClients.addServiceClientAccessRightSuccess'),
          );
          this.$router.push(`/subsystem/serviceclients/${this.id}`);
        })
        .catch((error) => this.showError(error));

      this.clear();
    },
    clear(): void {
      this.selections = [];
    },
    cancel(): void {
      this.$router.go(-1);
    },
  },
});
