/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * <p>The enum can have three different values</p> <ul> <li>PRIMARY, which is the primary node in a high availability setup</li> <li>SECONDARY, which is a secondary node in a high availability setup – a read-only server</li> <li>STANDALONE,when there are no load balancer or high availability configured</li> </ul>
 */
export enum NodeType {
    PRIMARY = 'PRIMARY',
    SECONDARY = 'SECONDARY',
    STANDALONE = 'STANDALONE',
}