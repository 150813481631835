


















































































































































import Vue from 'vue';
import ClientDetailsPage from './ClientDetailsPage.vue';
import TokenPage from '@/components/wizard/TokenPage.vue';
import SignKeyPage from '@/components/wizard/SignKeyPage.vue';
import FinishPage from './FinishPage.vue';
import CsrDetailsPageLocked from '@/components/wizard/CsrDetailsPageLocked.vue';
import GenerateCsrPage from '@/components/wizard/GenerateCsrPage.vue';
import { RouteName, AddMemberWizardModes } from '@/global';
import { mapActions, mapState } from 'pinia';
import { useAddClient } from '@/store/modules/addClient';
import { useNotifications } from '@/store/modules/notifications';
import { useCsrStore } from '@/store/modules/certificateSignRequest';

const NO_SELECTION = 999;

export default Vue.extend({
  components: {
    ClientDetailsPage,
    TokenPage,
    SignKeyPage,
    FinishPage,
    CsrDetailsPageLocked,
    GenerateCsrPage,
  },
  data() {
    return {
      currentStep: 1,
      wizardModes: AddMemberWizardModes,
    };
  },
  computed: {
    ...mapState(useAddClient, ['addMemberWizardMode', 'selectedMemberId']),

    tokenPageNumber(): number {
      if (
        this.addMemberWizardMode === AddMemberWizardModes.CERTIFICATE_EXISTS ||
        this.addMemberWizardMode === AddMemberWizardModes.CSR_EXISTS
      ) {
        return NO_SELECTION;
      }

      return 2;
    },

    keyPageNumber(): number {
      if (
        this.addMemberWizardMode === AddMemberWizardModes.CERTIFICATE_EXISTS ||
        this.addMemberWizardMode === AddMemberWizardModes.CSR_EXISTS
      ) {
        return NO_SELECTION;
      }

      return 3;
    },
    csrDetailsPageNumber(): number {
      if (
        this.addMemberWizardMode === AddMemberWizardModes.CERTIFICATE_EXISTS
      ) {
        return NO_SELECTION;
      }
      if (this.addMemberWizardMode === AddMemberWizardModes.CSR_EXISTS) {
        return 2;
      }

      return 4;
    },

    csrGeneratePageNumber(): number {
      if (
        this.addMemberWizardMode === AddMemberWizardModes.CERTIFICATE_EXISTS
      ) {
        return NO_SELECTION;
      }
      if (this.addMemberWizardMode === AddMemberWizardModes.CSR_EXISTS) {
        return 3;
      }

      return 5;
    },

    finishPageNumber(): number {
      if (
        this.addMemberWizardMode === AddMemberWizardModes.CERTIFICATE_EXISTS
      ) {
        return 2;
      }
      if (this.addMemberWizardMode === AddMemberWizardModes.CSR_EXISTS) {
        return 4;
      }
      return 6;
    },
  },
  created() {
    // Set up the CSR part with Sign mode
    this.setupSignKey();
    // Fetch certificate authorities. Used in "sign key" step.
    this.fetchCertificateAuthorities().catch((error) => {
      this.showError(error);
    });
  },
  beforeDestroy() {
    // Clear the stores used in the wizard
    this.resetAddClientState();
    this.resetCsrState();
  },
  methods: {
    ...mapActions(useNotifications, ['showError', 'showSuccess']),
    ...mapActions(useCsrStore, [
      'setupSignKey',
      'resetCsrState',
      'fetchCertificateAuthorities',
      'storeCsrClient',
      'storeCsrIsNewMember',
      'fetchCsrForm',
    ]),
    ...mapActions(useAddClient, ['resetAddClientState']),

    cancel(): void {
      this.$router.replace({ name: RouteName.Clients });
    },
    csrDetailsReady(): void {
      // Add the selected client id in the CSR store
      const idString = this.selectedMemberId;
      this.storeCsrClient(idString);
      this.storeCsrIsNewMember(true);

      this.fetchCsrForm().then(
        () => {
          this.currentStep++;
        },
        (error) => {
          this.showError(error);
        },
      );
    },

    done(): void {
      this.$router.replace({ name: RouteName.Clients });
    },
  },
});
