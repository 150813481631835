


































































































import Vue, { PropType } from 'vue';
import { Client } from '@/openapi-types';

export default Vue.extend({
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    searchLabel: {
      type: String,
      required: true,
    },
    selectableClients: {
      type: Array as PropType<Client[]>,
      default() {
        return [];
      },
    },
  },

  data() {
    return {
      search: '',
      selectedMember: undefined,
    };
  },
  methods: {
    filteredMembers() {
      if (!this.search) {
        return this.selectableClients;
      }

      const tempSearch = this.search.toString().toLowerCase().trim();
      if (tempSearch === '') {
        return this.selectableClients;
      }

      return this.selectableClients.filter((member) => {
        if (member.member_name?.toLowerCase().includes(tempSearch)) {
          return true;
        } else if (member.id?.toLowerCase().includes(tempSearch)) {
          return true;
        }

        return false;
      });
    },
    cancel(): void {
      this.clearForm();
      this.$emit('cancel');
    },
    save(): void {
      this.$emit('save', this.selectedMember);
      this.clearForm();
    },

    clearForm(): void {
      // Reset initial state
      this.selectedMember = undefined;
      this.search = '';
    },
  },
});
