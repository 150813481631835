



































import Vue from 'vue';
import { Permissions, RouteName } from '@/global';
import { Tab } from '@/ui-types';
import SubTabs from '@/components/layout/SubTabs.vue';
import { mapState } from 'pinia';
import { useUser } from '@/store/modules/user';
export default Vue.extend({
  components: {
    SubTabs,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      currentTab: undefined as undefined | Tab,
    };
  },
  computed: {
    ...mapState(useUser, ['getAllowedTabs']),
    tabs(): Tab[] {
      const allTabs: Tab[] = [
        {
          key: 'details',
          name: 'tab.client.details',
          to: {
            name: RouteName.MemberDetails,
            params: { id: this.id },
          },
        },
        {
          key: 'internalServers',
          name: 'tab.client.internalServers',
          to: {
            name: RouteName.MemberServers,
            params: { id: this.id },
          },
          permissions: [Permissions.VIEW_CLIENT_INTERNAL_CERTS],
        },
      ];

      return this.getAllowedTabs(allTabs);
    },
  },
});
